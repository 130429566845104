export default [
	{
		key: 'name',
		sortField: 'name',
		label: 'Name',
		thStyle: 'min-width: 150px',
		filter: {
			type: "input",
			default: "",
			placeholder: "All",
		},
	},
	{
		key: 'group',
		sortField: 'group',
		label: 'Group',
		thStyle: 'min-width: 150px',
		filter: {
			type: "input",
			default: "",
			placeholder: "All",
		},
	},
	{
		key: 'agent',
		sortField: 'agent',
		label: 'Agent',
		tdClass: 'text-right',
  
		filter: {
			type: "select",
      options: [], 
			default: "",
      filterFunc: (items, val) => {
        if (!val) return items;
        return items.filter(item => item.agent === val)
      }
		},
		tdStyle: 'width: 100px'
	},
	{
		key: 'CurrentEquity',
		sortField: 'CurrentEquity',
		label: 'Current Equity',
		tdClass: 'text-right',
		canFilterOutZeroValues: true
	},
	{
		key: 'CurrentCredit',
		sortField: 'CurrentCredit',
		label: 'Current Credit',
		tdClass: 'text-right',
		canFilterOutZeroValues: true
	},
	{
		key: 'Withdrawable',
		sortField: 'Withdrawable',
		label: 'Withdrawable',
		tdClass: 'text-right',
		canFilterOutZeroValues: true
	},
	{
		key: 'NetDeposits',
		sortField: 'NetDeposits',
		label: 'Daily Net Deposit',
		tdClass: 'text-right',
		canFilterOutZeroValues: true
	},
	{
		key: 'MonthlyNetDeposit',
		sortField: 'MonthlyNetDeposit',
		label: 'Monthly Net Deposit',
		tdClass: 'text-right',
		canFilterOutZeroValues: true
	},
	{
		key: 'ClosedVolumeToday',
		sortField: 'ClosedVolumeToday',
		label: 'Daily Volume',
		tdClass: 'text-right',
		canFilterOutZeroValues: true
	},
	{
		key: 'MonthlyVolume',
		sortField: 'MonthlyVolume',
		label: 'Monthly Volume',
		tdClass: 'text-right',
		canFilterOutZeroValues: true
	},
	{
		key: 'ActualPL',
		sortField: 'ActualPL',
		label: 'Daily P/L',
		tdClass: 'text-right',
		colorRedGreen: true,
		canFilterOutZeroValues: true
	},
	{
		key: 'PLThisMonth',
		sortField: 'PLThisMonth',
		label: 'Monthly P/L',
		tdClass: 'text-right',
		colorRedGreen: true,
		canFilterOutZeroValues: true
	},
	{
		key: 'PLLastMonth',
		sortField: 'PLLastMonth',
		label: 'Last Month P/L',
		tdClass: 'text-right',
		colorRedGreen: true,
		canFilterOutZeroValues: true
	},
]