<template>
    <div class="d-flex justify-content-between">
        <div class="statics_card_width">
            <statistic-card-with-area-chart
                :icon="['far', 'piggy-bank']"
                :statistic="`${symbolOfCurrency(companyCurrency)} ${formatMoney(dataTotal.PLThisMonth, 2)}`"
                statistic-title="P/L This month"
                :chart-data="[
                    {
                        data: pLInMonthAgo,
                        name: 'Amount',
                    },
                ]"
                color="purple"
            />
        </div>

        <div class="statics_card_width">
            <statistic-card-with-area-chart
                :icon="['far', 'inbox-in']"
                :statistic="`${symbolOfCurrency(companyCurrency)} ${formatMoney(dataTotal.MonthlyNetDeposit, 2)}`"
                statistic-title="Net deposit this month"
                :chart-data="[
                    {
                        data: netDepositInMonthAgo,
                        name: 'Amount',
                    },
                ]"
                color="success"
            />
        </div>

        <div class="statics_card_width">
            <statistic-card-with-area-chart
                :icon="['far', 'piggy-bank']"
                :statistic="`${symbolOfCurrency(companyCurrency)} ${formatMoney(dataTotal.ActualPL, 2)}`"
                statistic-title="P/L Today"
                :chart-data="[
                    {
                        data: pLOnDayAgo,
                        name: 'Amount',
                    },
                ]"
                color="danger"
            />
        </div>

        <div class="statics_card_width">
            <statistic-card-with-area-chart
                :icon="['far', 'inbox-in']"
                :statistic="`${symbolOfCurrency(companyCurrency)} ${formatMoney(dataTotal.NetDeposits, 2)}`"
                statistic-title="Net Deposit Today"
                :chart-data="[
                    {
                        data: netDepositOnDayAgo,
                        name: 'Amount',
                    },
                ]"
                color="warning"
            />
        </div>

        <div class="statics_card_width">
            <statistic-card-with-area-chart
                :icon="['far', 'piggy-bank']"
                :statistic="`${symbolOfCurrency(companyCurrency)} ${formatMoney(dataTotal.Withdrawable, 2)}`"
                statistic-title="Withdrawable"
                :chart-data="[
                    {
                        data: withdrawableAgo,
                        name: 'Amount',
                    },
                ]"
                color="info"
            />
        </div>
    </div>
</template>
<script>
import { formatMoney, symbolOfCurrency } from "@/helper";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import axios from "@axios";
import chartMixins from "../companyPLCharts/chartMixins";
import moment from "moment";
import store from "@/store";

export default {
    components: {
        StatisticCardWithAreaChart,
    },
    mixins: [chartMixins],
    data() {
        return {
            companyPL: [],
            pLInMonthAgo: [],
            netDepositInMonthAgo: [],
            pLOnDayAgo: [],
            netDepositOnDayAgo: [],
            withdrawableAgo: [],
            fetchingState: "FETCHING",
            payloads: [],
            interval: null,
            dataTotal: {
                PLThisMonth: 0,
                MonthlyNetDeposit: 0,
                Withdrawable: 0,
                NetDeposits: 0,
                ActualPL: 0,
            },
        };
    },
    computed: {
        companyCurrency() {
            const riskMangementSettings = store.getters["companySettings/settingsByPath"](["risk_management"]);
            if (riskMangementSettings.settings) return riskMangementSettings.settings.currency;
            return "";
        },
    },
    methods: {
        formatMoney,
        symbolOfCurrency,
        async fetchSeries() {
            try {
                this.fetchingState = "FETCHING";
                this.setDayStartEnd("ThisMonth");
                await Promise.all([
                    this.fetchYesterdayHistoryUsingMetricData(0, "PLThisMonth"),
                    this.fetchYesterdayHistoryUsingMetricData(1, "MonthlyNetDeposit"),

                    this.fetchTodayHistory(2, "CompanyTotalValues", "company", "ActualPL"),
                    this.fetchTodayHistory(3, "CompanyTotalValues", "company", "NetDeposits"),
                    this.fetchTodayHistory(4, "CompanyTotalValues", "company", "Withdrawable"),

                    //? mockData
                    this.fetchCurrentValues("CompanyTotalValues", "company", [
                        "PLThisMonth",
                        "MonthlyNetDeposit",
                        "ActualPL",
                        "NetDeposits",
                        "Withdrawable",
                    ]),
                ]);

                this.pLInMonthAgo = this.chartSeries[0].data;
                this.netDepositInMonthAgo = this.chartSeries[1].data;

                // this.pLOnDayAgo = this.chartSeriesToday[2].data;
                // this.netDepositOnDayAgo = this.chartSeriesToday[3].data;
                // this.withdrawableAgo = this.chartSeriesToday[4].data;

                //?mockData
                this.pLOnDayAgo = this.chartSeries[0].data;
                this.netDepositOnDayAgo = this.chartSeries[0].data;
                this.withdrawableAgo = this.chartSeries[0].data;

                this.fetchingState = "FETCHED";
            } catch (error) {
                this.fetchingState = "FAILED";
                console.error(error);
            }
        },
        update_metric_data(datum) {
            // console.log(datum)
            if (datum.metric_definition_name && ["CurrentUserStatistics"].includes(datum.metric_definition_name)) {
                if (this.companyPL[datum.id]) this.companyPL[datum.id].value = datum.value;
            }
        },
        calculateDataTotal() {
            Object.keys(this.dataTotal).forEach((field) => (this.dataTotal[field] = 0));
            Object.values(this.companyPL).forEach((item) => {
                Object.keys(this.dataTotal).map((field) => {
                    if (item.value[field]) this.dataTotal[field] += item.value[field];
                });
            });
            // console.log(this.dataTotal)
        },
        updateCharts() {
            let currentMoment = moment().utcOffset(this.timezoneOffset);
            this.pLInMonthAgo.pop();
            this.pLInMonthAgo.push({
                y: this.dataTotal.PLThisMonth,
                x: currentMoment.format("DD/MM"),
            });

            this.netDepositInMonthAgo.pop();
            this.netDepositInMonthAgo.push({
                y: this.dataTotal.MonthlyNetDeposit,
                x: currentMoment.format("DD/MM"),
            });

            this.pLOnDayAgo.pop();
            this.pLOnDayAgo.push({
                x: currentMoment.format("HH:mm"),
                y: this.dataTotal.ActualPL,
            });

            this.netDepositOnDayAgo.pop();
            this.netDepositOnDayAgo.push({
                y: this.dataTotal.NetDeposits,
                x: currentMoment.format("HH:mm"),
            });

            this.withdrawableAgo.pop();
            this.withdrawableAgo.push({
                x: currentMoment.format("HH:mm"),
                y: this.dataTotal.Withdrawable,
            });
        },
    },
    channels: {
        MetricDataUpdateChannel: {
            connected() {
                console.log("Connected to the chat channel");
            },
            rejected() {
                console.log("Rejected to the chat channel");
            },
            received(data) {
                if (data?.event_name == "trade.MetricDatum.before_destroy") {
                    this.companyPL = this.companyPL.filter((datum) => {
                        return datum.id != data.payload.id;
                    });
                    return;
                }

                if (data?.event_name == "trade.MetricDatum.after_create") {
                    this.update_metric_data(datum);
                    return;
                }

                if (Array.isArray(data)) {
                    data.forEach((datum) => {
                        this.update_metric_data(datum);
                    });
                }
                this.$store.commit("app/SET_DATA_LIVE_TIME", moment().format(" HH:mm:ss - DD/MM/YYYY"));
            },
            disconnected() {
                console.log("Disconnected to the chat channel");
            },
        },
    },
    mounted() {
        axios
            // .get(`${process.env.VUE_APP_API_URL}metric/CurrentUserStatistics/data`)
            //? mockData
            .get("/CurrentUserStatistics/data")
            .then(({ data }) => {
                data.forEach((datum) => {
                    this.companyPL[datum.id] = datum;
                });
                this.calculateDataTotal();
            });

        this.series = [
            { name: "P/L This Month" },
            { name: "Net Deposits This Month" },
            { name: "PL Today" },
            { name: "Net Deposit Today" },
            { name: "Withdrawable" },
        ];
        this.fetchSeries().then(() => {
            this.calculateDataTotal();
            this.updateCharts();
        });

        this.interval = setInterval(async () => {
            this.calculateDataTotal();
            this.updateCharts();
        }, 15 * 1000);

        //? mockData
        // this.$cable.subscribe({
        //     channel: "MetricDataUpdateChannel",
        // });
    },
    destroyed() {
        if (this.interval) clearInterval(this.interval);
    },
};
</script>
<style scoped>
.statics_card_width {
    width: 19%;
    /* min-width: 327px; */
}
</style>
