<template>
	<div class="row-count-pill rounded-pill my-shadow">
		<b-dropdown no-caret size="sm" variant="outline-dark" right >
			<template #button-content>
				<span class="count">
					{{ value }} rows
				</span>
				<font-awesome-icon :icon="['fas', 'caret-down']" />
			</template>
			<b-dropdown-item v-for="count, index in rowCounts" :key="index" @click="update(count)">
				<span class="count">
					{{ count }} rows
				</span>
			</b-dropdown-item>
		</b-dropdown>
	</div>
</template>

<script>
// import cssVariables from '@/assets/scss/variables/_variables.scss'
export default {
	props: ['value'],
	data() {
		return {
			rowCounts: [ 15, 19, 25, 35, 45 ]
			//  cssVariables.rowCounts.split(',').map(x => parseInt(x))
		}
	},
	methods: {
		update(count) {
			this.$emit('input', count)
		}
	},
	mounted() {
		// console.log(this.rowCounts)
	}
}
</script>

<style scoped>
.row-count-pill >>> .btn {
	border-radius: 9999px !important;
}
.row-count-pill >>> .btn:focus {
	color: #e4e6eb !important;
}
.row-count-pill >>> .count {
	font-size: 12px;
}
</style>