<template>
    <div class="w-100">
        <div class="d-flex justify-content-between">
            <h4 class="mb-1 table-title">Platform Open Positions</h4>
            <div class="d-flex">
                <span class="text-danger mr-1 cursor-pointer" v-if="isFilterShown" @click="clearFilter"
                    >Clear Search</span
                >
                <font-awesome-icon
                    :icon="['far', 'filter']"
                    class="cursor-pointer mr-1"
                    @click="toggleFilter"
                    v-b-tooltip.hover.top="'Filter'"
                />
                <font-awesome-icon
                    :icon="['far', 'compress-arrows-alt']"
                    class="btn-icon mr-1"
                    v-b-tooltip.hover.top="`Filter out 0 values: ${isFilterZeroShown ? 'on' : 'off'}`"
                    @click="toggleFilterZero"
                />
                <font-awesome-icon
                    :icon="['far', 'cog']"
                    class="cursor-pointer mr-1"
                    v-b-tooltip.hover.top="'Table Settings'"
                    v-b-modal.pop
                />
                <font-awesome-icon
                    :icon="['far', 'file-export']"
                    class="btn-icon"
                    v-b-tooltip.hover.top="'Export CSV'"
                    @click="$bvModal.show('platform-open-position-download-modal')"
                />
                <b-modal
                    id="platform-open-position-download-modal"
                    title="Choose format type"
                    centered
                    @ok="exportFile(exportData, exportFormat, 'PlatformOpenPosition')"
                >
                    <v-select v-model="exportFormat" :clearable="false" label="format" :options="['csv', 'xls']" />
                </b-modal>
            </div>
        </div>

        <b-card no-body class="px-50 rounded my-shadow table-16-rows w-100">
            <CommonTable
                :isLoading="isLoading"
                :fields="fields"
                :items="dataAfterCollapsed"
                :max-display-rows="19"
                :has-summary-row="false"
                :is-header-small="true"
                :isFilterShown="isFilterShown"
                :isFilterZeroShown="isFilterZeroShown"
                @filterChange="filterChange"
                @filterClear="filterClear"
                @filterOutZeroValues="handleFilterOutZeroValues"
                :field_sort="field_sort"
                :isDesc="isDesc"
                @onHeaderClick="(field) => sortBy(field)"
                ref="table"
            >
                <template #cell(name)="{ item }">
                    <!-- {{ item }} -->
                    <div :style="{ 'padding-left': `${calcNamePadding(item.level)}px` }">
                        <dot v-if="isItemUncollapsible(item)" />
                        <span v-else class="cursor-pointer">
                            <font-awesome-icon
                                :icon="['fas', 'caret-down']"
                                size="lg"
                                class="mr-50"
                                @click="collapse(item)"
                                v-if="!collapsedSet.includes(item[whichpath])"
                            />
                            <font-awesome-icon
                                :icon="['fas', 'caret-right']"
                                size="lg"
                                class="mr-50"
                                @click="uncollapse(item)"
                                v-else
                            />
                        </span>
                        <NameField :name="item.name" />
                    </div>
                </template>
            </CommonTable>

            <div class="py-1 d-flex justify-content-between card-footer-color">
                <FilterPills
                    :isLoading="isLoading"
                    v-model="activeLevels"
                    :levels="levels"
                    @toggleLevel="toggleLevel"
                />
                <div class="mr-2 my-auto text-heading-color">
                    Open Positions: {{ mt_open_trades.filter((item) => item.login).length }}
                </div>
            </div>
        </b-card>

        <TableSettingsModal
            :fields="platformOpenPositionFields.filter((i, index) => index > 0)"
            table="Platform Open Position"
            @reactivity="key += 1"
            id="pop"
        />
    </div>
</template>

<script>
import CommonTable from "@/components/tables/CommonTable";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import {
    formatMoney,
    exportFile,
    formatNumber,
    setFilterInLocalStorage,
    getFilterInLocalStorage,
    removeFilterInLocalStorage,
    isFilteredByRegion,
    getClientEmail,
    mergeTwoClients,
    getPathWithoutGroup,
} from "@/helper";
import moment from "moment-timezone";
import Dot from "@/components/Dot";
import platformOpenPositionFields from "./fields/platformOpenPositionFields.js";
import TableSettingsModal from "@/components/TableSettingModal";
import FilterPills from "@/components/tables/LevelPills.vue";
import NameField from "@/components/tables/NameField.vue";
import vSelect from "vue-select";
import { mapGetters } from "vuex";
import { destroyed } from "vue-echarts";

const TABLE_NAME = "PlatformOpenPositions";

export default {
    components: {
        CommonTable,
        Dot,
        TableSettingsModal,
        FilterPills,
        NameField,
        vSelect,
    },

    directives: {
        Ripple,
    },

    props: {},
    data() {
        return {
            exportFormat: "csv",
            key: 56,
            mt_open_trades: [],
            platformOpenPositionFields,
            action: ["Buy", "Sell", "BuyLimit", "SellLimit", "BuyStop", "SellStop", " Balance", "Credit"],

            numberLabels: ["volume", "profit_usd"],
            otherLabels: [],

            groupLevels: [],
            activeLevels: [],
            collapsedSet: [],
            isFilterShown: false,
            filterValues: {},
            isFilterZeroShown: true,
            filterOutZeroValues: {},

            isLoading: false,

            field_sort: "open_time",
            isDesc: true,
            liveUpdateInterval: 0,

            currencies: [],

            conversion_rate: { USD: 1 },
            group_organization: {},
        };
    },

    computed: {
        ...mapGetters({
            timezoneOffset: "companySettings/timezoneOffset",
            timezoneName: "companySettings/timezoneName",
        }),
        companyCurrency() {
            const riskMangementSettings = this.$store.getters["companySettings/settingsByPath"](["risk_management"]);
            if (riskMangementSettings.settings) return riskMangementSettings.settings.currency;
            return "";
        },
        exportData() {
            let data = [
                [...this.fields.map((field) => field.label)],
                ...this.items.map((item) => {
                    return this.fields.map((field) => {
                        if (field.key == "name") return item.id;
                        if (field.key === "storage")
                            return (item.server && item.login) || item.storage ? formatNumber(item.storage, 2) : "";
                        if (field.key === "volume") return item[field.key] ? formatNumber(item.volume, 2) : "";
                        if (["profit", "profit_usd"].includes(field.key))
                            return item[field.key] ? formatNumber(item[field.key], 2) : "";
                        return this.formatValue(item, field.key);
                    });
                }),
            ];
            return data;
        },
        fields() {
            let results = platformOpenPositionFields;

            let restrict = JSON.parse(localStorage.getItem("Platform Open Position Settings"));
            if (this.key && restrict) results = results.filter((i) => !restrict.includes(i.label));

            let currencyField = results.find((item) => item.key == "currency");
            if (currencyField) {
                currencyField.filter.options = [
                    { value: "", text: "All" },
                    ...(this.currencies
                        ? this.currencies.map((currency) => ({ value: currency, text: currency }))
                        : []),
                ];
            }
            results = results.map((field) => {
                if (field.label.includes("company_currency")) {
                    return {
                        ...field,
                        label: field.label.replace("company_currency", this.companyCurrency),
                    };
                }
                return field;
            });
            return results;
        },

        levels() {
            let result = this.groupLevels.map((levelName, index) => ({
                name: levelName,
                value: (item) => item.path[index],
            }));
            return [
                ...result,
                { name: "Client", value: (item) => `${item.mt_account.name} ${item.mt_account.email}` },
                { name: "Account", value: (item) => `${item.mt_account.server}-${item.mt_account.login}` },
                { name: "Open Position", value: (item) => `${item.external_id}` },
            ];
        },

        dataTotal() {
            let result = this.calculateSum(
                this.numberLabels,
                this.dataAfterGroup.filter((item) => item.level == 1)
            );
            return result;
        },

        whichpath() {
            let res = "path";
            if (!isFilteredByRegion(this.activeLevels, this.groupLevels.length)) {
                res = "subpath";
            }
            return res;
        },

        dataAfterCollapsed() {
            let results = JSON.parse(JSON.stringify(this.dataAfterFormat));
            this.collapsedSet.forEach((path) => {
                results = results.filter((item) => !this.isChildOfParent(item[this.whichpath], path));
            });
            return results;
        },

        dataAfterFormat() {
            let results = JSON.parse(JSON.stringify(this.dataAfterFilteredByZero));
            results = results.map((item) => {
                this.fields.forEach(({ key }) => {
                    item[key] = this.formatValue(item, key);
                });
                return item;
            });
            return results;
        },

        dataAfterFilteredByZero() {
            let results = JSON.parse(JSON.stringify(this.dataAfterFiltered));

            Object.keys(this.filterOutZeroValues).forEach((key) => {
                if (this.filterOutZeroValues[key]) {
                    results = results.filter((item) => {
                        if (key != "Risk") return !isNaN(parseFloat(item[key])) && parseFloat(item[key]) !== 0;
                        else return item[key] !== 1;
                    });
                }
            });
            let validPaths = results.map((item) => item[this.whichpath]);
            results = JSON.parse(JSON.stringify(this.dataAfterSort));
            results = results.filter((item) => {
                let bool = false;
                validPaths.forEach((path) => {
                    if (this.isChildOfParent(path, item[this.whichpath]) || path == item[this.whichpath]) {
                        bool = true;
                        return;
                    }
                });
                return bool;
            });
            return results;
        },
        dataAfterFiltered() {
            let results = JSON.parse(JSON.stringify(this.dataAfterSort));
            Object.keys(this.filterValues).forEach((key) => {
                let field = this.fields.find((field) => field.key == key);
                if (field?.filter?.filterFunc) {
                    results = field.filter.filterFunc(results, this.filterValues[key]);
                } else {
                    if (this.filterValues[key]) {
                        results = results.filter((item) => {
                            return (this.formatValue(item, key) ?? "")
                                .toString()
                                .toLowerCase()
                                .includes(this.filterValues[key].toLowerCase());
                        });
                    }
                }
            });
            let validPaths = results.map((item) => item[this.whichpath]);
            results = JSON.parse(JSON.stringify(this.dataAfterSort));
            results = results.filter((item) => {
                let bool = false;
                validPaths.forEach((path) => {
                    if (this.isChildOfParent(path, item[this.whichpath]) || path == item[this.whichpath]) {
                        bool = true;
                        return;
                    }
                });
                return bool;
            });
            return results;
        },

        dataAfterSort() {
            let desc_or_asc = this.isDesc ? -1 : 1;
            this.activeLevels.sort();

            let result = [];
            let j = 0;
            this.activeLevels.forEach((level, index) => {
                let pos_or_neg = index == 0 ? desc_or_asc : -desc_or_asc;
                let data = this.dataAfterGroup.filter((datum) => datum.level == level);
                data.sort(
                    (a, b) =>
                        pos_or_neg *
                        (a[this.field_sort] < b[this.field_sort] ? -1 : a[this.field_sort] > b[this.field_sort] ? 1 : 0)
                );

                // Check if table is filtered by region, if not we have to merge client with same email (diffrent region)
                if (
                    !isFilteredByRegion(this.activeLevels, this.groupLevels.length) &&
                    level === this.groupLevels.length + 1
                ) {
                    let noDuplicatedData = {};
                    data.forEach((datum) => {
                        if (noDuplicatedData[datum.name]) {
                            noDuplicatedData[datum.name] = mergeTwoClients(
                                noDuplicatedData[datum.name],
                                datum,
                                this.fields
                            );
                        } else noDuplicatedData[datum.name] = datum;
                    });
                    data = Object.values(noDuplicatedData);
                }

                if (index == 0) {
                    result = result.concat(data);
                    j = level;
                } else {
                    // Find correct index and insert the value
                    // console.log(level, index, j, data)
                    data.forEach((current) => {
                        result.forEach((datum, index) => {
                            if (
                                datum.level == j &&
                                this.isChildOfParent(current[this.whichpath], datum[this.whichpath])
                            )
                                result.splice(index + 1, 0, current);
                        });
                    });
                    j = level;
                }
            });
            // console.log("dataAfterSort", results)
            return result;
        },

        dataAfterGroup() {
            let results = [];
            // console.log(this.items)
            this.levels.forEach((level, index) => {
                if (this.activeLevels.includes(index + 1)) {
                    let distinctValues = [
                        ...new Set(
                            this.items.map((item) => {
                                let path = item.path.split("/").slice(0, index + 1);
                                if (this.whichpath == "subpath") {
                                    path = path.slice(Math.min(...this.activeLevels) - 1);
                                }
                                return path.join("/");
                            })
                        ),
                    ];

                    results.push(
                        ...distinctValues.map((path) => {
                            let name = path.split("/");
                            name = this.group_organization[path]?.name ?? name[name.length - 1];

                            let data = this.items.filter(
                                (item) =>
                                    this.isChildOfParent(item[this.whichpath], path) || item[this.whichpath] == path
                            );
                            let tmp = this.calculateSum(this.numberLabels, data);

                            if (index == this.levels.length - 1) {
                                tmp = {
                                    ...data[0],
                                    ...tmp,
                                };
                            }

                            return {
                                ...tmp,
                                level: index + 1,
                                isUncollapsible: index + 1 === Math.max(...this.activeLevels),
                                name: name,
                                [this.whichpath]: path,
                            };
                        })
                    );

                    // console.log(distinctValues, results)
                }
            });
            // console.log('dataAfterGroup', results)
            return results;
        },

        items() {
            let results = this.mt_open_trades.map((item) => {
                if (typeof item.path == "string") item.path = item.path.split("/");
                let path = this.levels.map((level) => level.value(item));
                let subpath = path.slice(Math.min(...this.activeLevels) - 1);
                return {
                    ...item,
                    path: path.join("/"),
                    subpath: subpath.join("/"),
                    level: this.levels.length,
                    profit_usd: item.profit_usd
                        ? item.profit_usd
                        : item.profit
                        ? item.profit * this.conversion_rate[item.currency]
                        : 0,
                };
            });
            return results;
        },
    },

    methods: {
        formatMoney,
        moment,
        exportFile,

        isChildOfParent(child, parent) {
            let child_path = child.split("/");
            let parent_path = parent.split("/");
            return (
                child_path.length > parent_path.length && child_path.slice(0, parent_path.length).join("/") == parent
            );
        },

        initFilter() {
            if (getFilterInLocalStorage(TABLE_NAME)) {
                let filterValues = getFilterInLocalStorage(TABLE_NAME);

                this.isFilterShown = true;
                this.$refs.table.initFilter(filterValues);
            }
            if (getFilterInLocalStorage(TABLE_NAME + "Zero")) {
                let filterValues = getFilterInLocalStorage(TABLE_NAME + "Zero");
                this.isFilterZeroShown = filterValues.show ? true : false;
                this.$refs.table.initFilterZero(filterValues);
            }
        },

        toggleFilter() {
            this.isFilterShown = !this.isFilterShown;
            if (this.isFilterShown) {
                setFilterInLocalStorage(TABLE_NAME, {});
            } else {
                removeFilterInLocalStorage(TABLE_NAME);
            }
        },

        toggleFilterZero() {
            this.isFilterZeroShown = !this.isFilterZeroShown;
            if (this.isFilterZeroShown) {
                let filterOutZeroValues = getFilterInLocalStorage(TABLE_NAME + "Zero") || {};

                setFilterInLocalStorage(TABLE_NAME + "Zero", {
                    ...filterOutZeroValues,
                    show: true,
                });
            } else {
                let filterOutZeroValues = getFilterInLocalStorage(TABLE_NAME + "Zero") || {};

                setFilterInLocalStorage(TABLE_NAME + "Zero", {
                    ...filterOutZeroValues,
                    show: false,
                });
            }
        },

        clearFilter() {
            this.$refs.table.clearFilter();
        },

        filterChange(filterValues) {
            Object.keys(filterValues).forEach((key) => {
                this.$set(this.filterValues, key, filterValues[key]);
            });
            if (this.isFilterShown) setFilterInLocalStorage(TABLE_NAME, filterValues);
        },

        filterClear(filterValues) {
            Object.keys(filterValues).forEach((key) => {
                this.$set(this.filterValues, key, filterValues[key]);
            });
            if (this.isFilterShown) setFilterInLocalStorage(TABLE_NAME, filterValues);
        },
        handleFilterOutZeroValues(filterOutZeroValues) {
            Object.keys(filterOutZeroValues).forEach((key) => {
                if (key != "show") this.$set(this.filterOutZeroValues, key, filterOutZeroValues[key]);
            });
            if (this.isFilterZeroShown)
                setFilterInLocalStorage(TABLE_NAME + "Zero", {
                    ...filterOutZeroValues,
                    show: this.isFilterZeroShown,
                });
        },
        sortBy(field) {
            if (field) {
                this.field_sort = field;
                this.isDesc = !this.isDesc;
            }
        },

        getOptions(field) {
            let options = this.dataAfterGroupBy.map((datum) => {
                return this.formatValue(datum, field);
            });
            return ["All", ...new Set(options)].filter((option) => option !== undefined);
        },

        formatValue(item, field) {
            let reasons = ["CLIENT", "EXPERT", "DEALER"];
            switch (field) {
                case "name":
                    return item.name;
                case "account":
                    return item.server && item.login ? `${item.server}-${item.login}` : "";
                case "time":
                    return item.open_time
                        ? moment(item.open_time)
                              .tz(this.timezoneName)
                              .format("YYYY.MM.DD HH:mm:ss Z")
                        : "";
                case "type":
                    return this.action[item.action];
                case "symbol":
                    return item.symbol;
                case "volume":
                    return item.volume ? formatMoney(item.volume) : "";
                case "reason":
                    return reasons[item.reason];
                case "storage":
                    return (item.server && item.login) || item.storage ? formatMoney(item.storage) : "";
                case "profit":
                    return item.profit != null ? formatMoney(item.profit) : "";
                case "currency":
                    return item.currency;
                case "profit_usd":
                    return formatMoney(item.profit_usd, 2) ?? "";
                default:
                    console.log("Invalid field", field, item);
                    return item[field];
            }
        },

        calcNamePadding(level) {
            let a = this.activeLevels;
            return 25 * a.indexOf(level);
        },

        sortByHasValue(data) {
            return data.sort((a, b) => Object.keys(b.value).length - Object.keys(a.value).length);
        },

        async filterPlatformDealRegion(level) {
            if (this.PDRegionFilterSet.includes(level))
                this.PDRegionFilterSet = this.PDRegionFilterSet.filter((item) => item != level);
            else this.PDRegionFilterSet.push(level);
            await this.fetch_mt_open_trades();
        },

        calculateSum(numberLabels, data) {
            return numberLabels.reduce((obj, key) => {
                if (data.length == 1) {
                    obj[key] = data[0][key];
                } else {
                    obj[key] = data.reduce((sum, item) => sum + (isNaN(item[key]) ? 0 : item[key]), 0);
                }
                return obj;
            }, {});
        },

        isItemUncollapsible(item) {
            return item.level == Math.max(...this.activeLevels);
        },

        collapse(item) {
            this.collapsedSet.push(item[this.whichpath]);
        },

        uncollapse(item) {
            this.collapsedSet = this.collapsedSet.filter((path) => path != item[this.whichpath]);
        },

        calcNamePadding(level) {
            return 25 * this.activeLevels.indexOf(level);
        },

        toggleLevel(level) {
            // console.log(level, this.activeLevels)
            this.collapsedSet = this.collapsedSet.filter((path) => path.split("/").length >= level);
        },

        async fetch_mt_open_trades(loading = true) {
            this.isLoading = loading;
            // this.PDCollapseSet = []
            try {
                // let url = `${process.env.VUE_APP_API_URL}mt_open_trades?group=true`;
                // let res = await axios.get(url);

                //? mockData
                const res = await axios.get("/MtOpenTrade/data");

                await Promise.all(
                    res.data.map((item) => {
                        if (item.mt_account) {
                            if (this.conversion_rate[item.mt_account.currency] == null) {
                                // this.conversion_rate[item.mt_account.currency] = NaN;
                                // return axios
                                //     .get(
                                //         `${process.env.VUE_APP_API_URL}site_settings/get_conversion_rate?from_currency=${item.mt_account.currency}&to_currency=${this.companyCurrency}`
                                //     )
                                //     .then((res) => {
                                //         this.conversion_rate[item.mt_account.currency] = res.data;
                                //     });

                                //? mockData
                                this.conversion_rate[item.mt_account.currency] = 1;
                            }
                        }
                    })
                );
                this.mt_open_trades = res.data;
            } catch (err) {
                console.error(err);
            } finally {
                this.isLoading = false;
            }
        },

        getAllChildren(node, path) {
            let result = { [path]: node.settings };
            if (node.children) {
                Object.keys(node.children).forEach((key) => {
                    result = { ...result, ...this.getAllChildren(node.children[key], path + "/" + key) };
                });
            }
            return result;
        },
    },

    channels: {
        TradeRecordChannel: {
            connected() {
                console.log("Connected to the TradeRecordChannel channel");
            },
            rejected() {
                console.log("Rejected to the TradeRecordChannel channel");
            },
            received(payload) {
                var data = payload.context;
                // console.log(payload)
                if (payload.context_type == "mt_open_trade") {
                    // add new open trades to exist data
                    if (!this.mt_open_trades.filter((item) => item.id == data.id)[0]) {
                        var similar_item = this.mt_open_trades.filter(
                            (item) =>
                                item.mt_account &&
                                item.mt_account.server == data.mt_account.server &&
                                item.mt_account.group == data.mt_account.group
                        )[0];
                        console.log("new open trade", data, similar_item, payload);
                        if (similar_item) {
                            data.path = similar_item.path;
                            data.level = similar_item.level;
                            this.mt_open_trades.push(data);
                        } else this.fetch_mt_open_trades();
                    }
                } else if (payload.context_type == "mt_deal") {
                    console.log("new deal", data);
                    // remove open trades from the table
                    var index = this.mt_open_trades.findIndex(
                        (item) => item.external_id == (data.open_trade_id || data.external_id)
                    );
                    if (index != -1) {
                        this.mt_open_trades.splice(index, 1);
                    }
                }
                this.$store.commit("app/SET_DATA_LIVE_TIME", moment().format(" HH:mm:ss - DD/MM/YYYY"));
            },
            disconnected() {
                console.log("Disconnected to the TradeRecordChannel channel");
            },
        },

        SymbolUpdateChannel: {
            connected() {
                console.log("Connected to the SymbolUpdateChannel");
            },
            rejected() {
                console.log("Rejected to the SymbolUpdateChannel");
            },
            received(data) {
                data.forEach((datum) => {
                    let mt_symbol_datum = datum.mt_symbol_datum;
                    let configuration = datum.mt_symbol_config.configuration;
                    try {
                        configuration = JSON.parse(datum.mt_symbol_config.configuration);
                    } catch (e) {
                        // console.log("error", configuration)
                    }
                    this.mt_open_trades.forEach((t, index) => {
                        if (t.server == mt_symbol_datum.server && t.symbol == mt_symbol_datum.symbol) {
                            t.current_price = t.action == 1 ? mt_symbol_datum.ask : mt_symbol_datum.bid;
                            let new_profit =
                                (t.current_price - t.open_price) * configuration["ContractSize"] * t.volume;
                            new_profit *= t.action == 0 ? 1 : -1;
                            new_profit *= datum.conversion_rate / this.conversion_rate[t.mt_account.currency];
                            t.profit = new_profit;
                        }
                    });
                    this.$store.commit("app/SET_DATA_LIVE_TIME", moment().format(" HH:mm:ss - DD/MM/YYYY"));
                });
            },
            disconnected() {
                console.log("Disconnected to the SymbolUpdateChannel");
            },
        },
    },

    async mounted() {
        this.initFilter();

        this.fetch_mt_open_trades();

        this.liveUpdateInterval = setInterval(() => {
            this.fetch_mt_open_trades(false);
        }, 60 * 1000);

        //? mockData
        // this.$cable.subscribe({
        //     channel: "TradeRecordChannel",
        // });
        // this.$cable.subscribe({
        //     channel: "SymbolUpdateChannel",
        // });

        axios
            // .get(`${process.env.VUE_APP_API_URL}/public_site_settings/account_setup`)
            //? mockData
            .get("/AccountSetup/data")
            .then((res) => {
                this.currencies = res.data.settings.currency_based;
            });

        // await axios.get(`${process.env.VUE_APP_API_URL}site_settings/risk_management/group_levels`).then((res) => {
        //     this.groupLevels = res.data.settings;
        // });

        // ? mockData
        await axios.get("/GroupLevel/data").then((res) => {
            this.groupLevels = res.data.settings;
        });

        await this.$nextTick();
        this.activeLevels = [1, 2, 3, this.levels.length];

        axios
            // .get(`${process.env.VUE_APP_API_URL}site_settings/risk_management/group_organization?recursive`)
            //? mockData
            .get("/GroupOrganization/data")
            .then((res) => {
                this.group_organization = {};
                if (res.data?.children) {
                    Object.keys(res.data.children).forEach((key) => {
                        this.group_organization = {
                            ...this.group_organization,
                            ...this.getAllChildren(res.data.children[key], key),
                        };
                    });
                }
            })
            .catch((err) => console.error(err));
    },

    destroyed() {
        clearInterval(this.liveUpdateInterval);
    },
};
</script>
