export default [
	{
		key: 'name',
		sortField: 'name',
		label: 'Name',
		thStyle: 'min-width: 150px',
		filter: {
			type: "input",
			default: "",
			placeholder: "All",
		},
	},
	{
		key: 'group',
		sortField: 'group',
		label: 'Group',
		thStyle: 'min-width: 150px',
		filter: {
			type: "input",
			default: "",
			placeholder: "All",
		},
	},
	{
		key: 'CurrentCredit',
		sortField: 'CurrentCredit',
		label: 'Current Credit',
		tdClass: 'text-right',
		canFilterOutZeroValues: true
	},
	{
		key: 'CurrentEquity',
		sortField: 'CurrentEquity',
		label: 'Current Equity',
		tdClass: 'text-right',
		canFilterOutZeroValues: true
	},
	{
		key: 'Withdrawable',
		sortField: 'Withdrawable',
		label: 'Withdrawable',
		tdClass: 'text-right',
		canFilterOutZeroValues: true
	},
	{
		key: 'ActualPL',
		sortField: 'ActualPL',
		label: 'P/L Today',
		tdClass: 'text-right',
		colorRedGreen: true,
		canFilterOutZeroValues: true
	},
	{
		key: '%ChangeEquity',
		sortField: '%ChangeEquity',
		label: '%Change Equity',
		tdClass: 'text-right',
		colorRedGreen: true,
		canFilterOutZeroValues: true
	},
	{
		key: 'PLYesterday',
		sortField: 'PLYesterday',
		label: 'P/L Yesterday',
		tdClass: 'text-right',
		colorRedGreen: true,
		canFilterOutZeroValues: true
	},
	{
		key: 'PLThisMonth',
		sortField: 'PLThisMonth',
		label: 'P/L This Month',
		tdClass: 'text-right',
		colorRedGreen: true,
		canFilterOutZeroValues: true
	},
	{
		key: 'PLLastMonth',
		sortField: 'PLLastMonth',
		label: 'P/L Last Month',
		tdClass: 'text-right',
		colorRedGreen: true,
		canFilterOutZeroValues: true
	},
	{
		key: 'PLOverall',
		sortField: 'PLOverall',
		label: 'P/L Overall',
		tdClass: 'text-right',
		colorRedGreen: true,
		canFilterOutZeroValues: true
	},
	{
		key: '#LotsThisMonth',
		sortField: '#LotsThisMonth',
		label: '#Lots This Month',
		tdClass: 'text-right',
		canFilterOutZeroValues: true
	},
	{
		key: '%ProfitableClosedTrade',
		sortField: '%ProfitableClosedTrade',
		label: '%Profitable Closed Trade',
		tdClass: 'text-right',
		colorRedGreen: true,
		canFilterOutZeroValues: true
	},
	{
		key: 'AVGProfitPerClosedTradeThisMonth',
		sortField: 'AVGProfitPerClosedTradeThisMonth',
		label: 'AVG Profit per Trade This Month',
		tdClass: 'text-right',
		colorRedGreen: true,
		canFilterOutZeroValues: true
	},
	{
		key: '%ProfitableTotalTrade',
		sortField: '%ProfitableTotalTrade',
		label: '%Profitable Total Trade',
		tdClass: 'text-right',
		colorRedGreen: true,
		canFilterOutZeroValues: true
	},
	{
		key: 'AVGFloatingPLPerOpenTrade',
		sortField: 'AVGFloatingPLPerOpenTrade',
		label: 'AVG Floating P/L per Open Trade',
		tdClass: 'text-right',
		colorRedGreen: true,
		canFilterOutZeroValues: true
	},
]