<template>
    <div class="company-chart">
        <b-card header-class="border-bottom py-1" body-class="pt-2">
            <template #header>
                <div>
                    <span class="font-weight-bolder font-small-4">
                        Client Equity History
                    </span>
                    <DigitalClock />
                </div>
            </template>
            <div class="company-chart__today-note" v-if="time_frame === 'Today'">
                Note: Today values are accumulated over hours
            </div>
            <div>
                <!-- <VueApexCharts type="line" height="400" :options="chartOptions" :series="chartSeries"></VueApexCharts> -->
                <!-- mockData -->
                <VueApexCharts
                    type="line"
                    height="380"
                    :options="chartOptions_mock"
                    :series="series_mock"
                ></VueApexCharts>
            </div>
        </b-card>
    </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import DigitalClock from "@/components/DigitalClock";
import apexChartData from "@/views/charts-and-maps/charts/apex-chart/apexChartData";
import chartMixins from "./chartMixins";
export default {
    data() {
        return {
            series_mock: [
                {
                    name: "Desktops",
                    data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
                    color: "#28c76f",
                },
            ],
            chartOptions_mock: {
                chart: {
                    height: 350,
                    type: "line",
                    zoom: {
                        enabled: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "straight",
                },

                grid: {
                    row: {
                        colors: ["#000000", "transparent"], // takes an array which will be repeated on columns
                        opacity: 0.5,
                    },
                },
                xaxis: {
                    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
                },
            },
        };
    },
    components: {
        VueApexCharts,
        DigitalClock,
    },
    props: {
        current_item: Object,
        time_frame: String,
        parent_timeframe: String,
        flag: Boolean,
    },
    mixins: [chartMixins],
    computed: {
        chartOptions() {
            return {
                ...apexChartData.accountEquityHistory.chartOptions,
                xaxis: {
                    ...apexChartData.accountEquityHistory.chartOptions.xaxis,
                    tickAmount: Math.min(24, this.dayStart - this.dayEnd),
                    tickPlacement: "on",
                },
            };
        },
    },
    watch: {
        current_item() {
            // this.fetchYesterdayHistoryUsingMetricHistory('CurrentUserStatistics', this.current_item.name, 'YesterdayEquity')
            this.fetchYesterdayHistoryUsingMetricData(this.current_item.name, "CurrentEquity");
            this.fetchTodayHistory("CurrentUserStatistics", this.current_item.name, "CurrentEquity");
            this.fetchCurrentValue("CurrentUserStatistics", this.current_item.name, "CurrentEquity");
        },
    },
    mounted() {
        // this.fetchYesterdayHistoryUsingMetricHistory('CurrentUserStatistics', this.current_item.name, 'YesterdayEquity')
        this.fetchYesterdayHistoryUsingMetricData(this.current_item.name, "CurrentEquity");
        this.fetchTodayHistory("CurrentUserStatistics", this.current_item.name, "CurrentEquity");
        this.fetchCurrentValue("CurrentUserStatistics", this.current_item.name, "CurrentEquity");
        this.setDayStartEnd("ThisMonth");
        this.liveUpdateInterval = setInterval(() => {
            this.fetchCurrentValue("CurrentUserStatistics", this.current_item.name, "CurrentEquity");
        }, 10 * 1000);
    },
};
</script>
<style></style>
