<template>
	<div class="table-functions__container">
		<span class="text-danger mr-1 cursor-pointer" v-if="isFilterShown" @click="$emit('clearFilter')">Clear Search</span>
		<font-awesome-icon :icon="['far', 'filter']" class="btn-icon mr-1" v-b-tooltip.hover.top="'Filter'" @click="$emit('toggleFilter')" />
		<font-awesome-icon :icon="['far', 'cog']" class="btn-icon mr-1" v-b-tooltip.hover.top="'Table Settings'" @click="$bvModal.show(tableName + 'Settings')" />
		<font-awesome-icon :icon="['far', 'file-export']" class="btn-icon" v-b-tooltip.hover.top="'Export CSV'" @click="$bvModal.show(`${tableName}-download-modal`)" />
		<b-modal :id="`${tableName}-download-modal`" title="Choose format type" centered @ok="$emit('exportFile', exportFormat)">
			<v-select
					v-model="exportFormat"
					:clearable="false"
					label="format"
					:options="['csv', 'xls']"
				/>
		</b-modal>
    <TableSettingsModal :fields="fields" :table="tableName" @reactivity="$emit('reactivity')" :id="tableName + 'Settings'"/>
	</div>
</template>

<script>
import TableSettingsModal from "@/components/TableSettingModal"
import { exportFile } from '@/helper'
export default {
	components: {
		TableSettingsModal
	},
	props: {
		tableName: {
			type: String
		},
		isFilterShown: {
			type: Boolean
		},
		fields: {
			type: Array
		}
	},
	data() {
		return {
			exportFormat: 'csv'
		}
	},
	methods: {
		exportFile
	}
}
</script>

<style>

</style>