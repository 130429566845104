<template>
    <b-card class="company-chart" header-class="border-bottom py-75" body-class="pt-2 pb-0">
        <template #header>
            <div class="d-flex justify-content-between align-items-center w-100">
                <div>
                    <span class="font-weight-bolder mb-0 font-small-4 text-heading-color">Equity</span>
                </div>
                <b-dropdown :text="timeframe" variant="flat-dark" right toggle-class="py-50 text-heading-color">
                    <b-dropdown-item
                        v-for="tf in timeframes"
                        :key="tf.value"
                        @click="setDayStartEnd(tf.value), (timeframe = tf.label)"
                        class="font-small-3 text-heading-color"
                    >
                        {{ tf.label }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </template>
        <div class="company-chart__today-note" v-if="timeframe === 'Today'">
            Note: Today values are accumulated over hours
        </div>
        <div v-if="fetchingState === 'FETCHED'">
            <div class="d-flex w-100 justify-content-center" style="height: 20px">
                <div class="legend-withdrawal ml-1" :class="{ disabled: disabled[0] }" @click="toggleSeries(0)">
                    P/L
                </div>
                <div class="legend-netdeposit ml-1" :class="{ disabled: disabled[1] }" @click="toggleSeries(1)">
                    Withdrawable
                </div>
                <div class="legend-deposit ml-1" :class="{ disabled: disabled[2] }" @click="toggleSeries(2)">
                    Equity
                </div>
            </div>
            <!-- <VueApexCharts height="400" :options="chartOptions" :series="chartSeries"></VueApexCharts> -->
            <!-- mockData -->
            <VueApexCharts type="line" height="350" :options="chartOptions_mock" :series="series_mock"></VueApexCharts>
        </div>
        <div v-else>
            <div class="w-100 d-flex justify-content-center align-items-center" style="height: 415px">
                <b-spinner variant="primary"></b-spinner>
            </div>
        </div>
    </b-card>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import DigitalClock from "@/components/DigitalClock.vue";
import apexChartData from "@/views/charts-and-maps/charts/apex-chart/apexChartData";
import chartMixins from "./chartMixins";
export default {
    components: {
        VueApexCharts,
        DigitalClock,
    },
    mixins: [chartMixins],
    data() {
        return {
            apexChartData,
            fetchingState: "FETCHING",
            timeframe: "This Month",
            interval: null,

            series_mock: [
                {
                    name: "High - 2013",
                    data: [28, 29, 33, 36, 32, 32, 33],
                },
                {
                    name: "Low - 2013",
                    data: [12, 11, 14, 18, 17, 13, 13],
                },
            ],
            chartOptions_mock: {
                chart: {
                    height: 350,
                    type: "line",
                    dropShadow: {
                        enabled: true,
                        color: "#000",
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2,
                    },
                    toolbar: {
                        show: false,
                    },
                },
                colors: ["#77B6EA", "#545454"],
                dataLabels: {
                    enabled: true,
                },
                stroke: {
                    curve: "smooth",
                },
                title: {
                    text: "Average High & Low Temperature",
                    align: "left",
                },
                grid: {
                    borderColor: "#e7e7e7",
                    row: {
                        colors: ["#000000", "transparent"], // takes an array which will be repeated on columns
                        opacity: 0.5,
                    },
                },
                markers: {
                    size: 1,
                },
                xaxis: {
                    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
                    title: {
                        text: "Month",
                    },
                },
                yaxis: {
                    title: {
                        text: "Temperature",
                    },
                    min: 5,
                    max: 40,
                },
                legend: {
                    position: "top",
                    horizontalAlign: "right",
                    floating: true,
                    offsetY: -25,
                    offsetX: -5,
                },
            },
        };
    },
    computed: {
        chartOptions() {
            return {
                ...apexChartData.companyEquityChart.chartOptions,
                colors: this.getColors(apexChartData.companyEquityChart.chartOptions.colors),
            };
        },
    },
    methods: {
        async fetchAll() {
            try {
                this.fetchingState = "FETCHING";
                this.setDayStartEnd("ThisMonth");
                await Promise.all([
                    // this.fetchYesterdayHistory(0, 'CompanyTotalValues', 'company', 'PLYesterday'),
                    // this.fetchYesterdayHistory(1, 'CompanyTotalValues', 'company', 'WithdrawableYesterday'),
                    // this.fetchYesterdayHistory(2, 'CompanyTotalValues', 'company', 'YesterdayEquity'),
                    this.fetchYesterdayHistoryUsingMetricData(0, "ActualPL"),
                    this.fetchYesterdayHistoryUsingMetricData(1, "Withdrawable"),
                    this.fetchYesterdayHistoryUsingMetricData(2, "CurrentEquity"),

                    this.fetchTodayHistory(0, "CompanyTotalValues", "company", "ActualPL"),
                    this.fetchTodayHistory(1, "CompanyTotalValues", "company", "Withdrawable"),
                    this.fetchTodayHistory(2, "CompanyTotalValues", "company", "CurrentEquity"),
                    this.fetchCurrentValues("CompanyTotalValues", "company", [
                        "ActualPL",
                        "Withdrawable",
                        "CurrentEquity",
                    ]),
                ]);
                this.fetchingState = "FETCHED";
            } catch (error) {
                this.fetchingState = "FAILED";
                console.error(error);
            }
        },
    },
    mounted() {
        this.series = [
            {
                name: "P/L",
                chartType: "line",
            },
            {
                name: "Withdrawable",
                chartType: "line",
            },
            {
                name: "Equity",
                chartType: "line",
            },
        ];
        this.fetchAll();
        this.interval = setInterval(() => {
            this.fetchCurrentValues("CompanyTotalValues", "company", ["ActualPL", "Withdrawable", "CurrentEquity"]);
        }, 10 * 1000);
    },
    destroyed() {
        clearInterval(this.interval);
    },
};
</script>
<style lang="scss" scoped>
@mixin legend($color) {
    content: " ";
    display: inline-block;
    height: 2px;
    width: 30px;
    transform: translateY(-3px);
    background: $color;
}
.legend-withdrawal::before {
    @include legend(#4472c4);
}
.legend-netdeposit::before {
    @include legend(#28c76f);
}
.legend-deposit::before {
    @include legend(#a5a5a5);
}
.legend-withdrawal,
.legend-netdeposit,
.legend-deposit {
    color: var(--secondary);
    font-size: 0.8rem;
    cursor: pointer;
    &.disabled {
        color: darken($color: #e4e6e8, $amount: 60);
    }
}
</style>
