<template>
    <div class="custom w-100">
        <div class="" v-if="showToday">
            <today-user-statistics-table :today_user_statistics="current_user_statistics" :isLoading="isLoading" />
        </div>
        <div class="" v-if="showCurrent">
            <current-user-statistics-table :current_user_statistics="current_user_statistics" :isLoading="isLoading" />
        </div>
    </div>
</template>
<script>
import Ripple from "vue-ripple-directive";
import TodayUserStatisticsTable from "./TodayUserStatisticsTable";
import CurrentUserStatisticsTable from "./CurrentUserStatisticsTable";
import axios from "@axios";
import moment from "moment";

export default {
    components: {
        TodayUserStatisticsTable,
        CurrentUserStatisticsTable,
    },
    directives: {
        Ripple,
    },

    props: {
        showToday: {
            type: Boolean,
            default: true,
        },
        showCurrent: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            current_user_statistics: [],
            isLoading: false,
        };
    },
    methods: {
        fetchData() {
            this.isLoading = true;
            axios.get("/CurrentUserStatistics/data").then((res) => {
                console.log("res", res);
                this.current_user_statistics = res.data;
                this.isLoading = false;
            });
        },

        update_metric_data(datum) {
            if (datum.metric_definition_name && ["CurrentUserStatistics"].includes(datum.metric_definition_name)) {
                let item = this.current_user_statistics.find((item) => item.id == datum.id);
                if (item) {
                    let diff = Object.keys(item.value).find((key) => {
                        return item.value[key] != datum.value[key];
                    });
                    if (diff) {
                        // console.log("update_metric_data", diff, item.value[diff], datum.value[diff])
                        item.value = datum.value;
                    }
                } else {
                    this.current_user_statistics.push(datum);
                }
            }
        },
    },

    channels: {
        MetricDataUpdateChannel: {
            connected() {
                console.log("Connected to the chat channel");
            },
            rejected() {
                console.log("Rejected to the chat channel");
            },
            received(data) {
                if (data?.event_name == "trade.MetricDatum.before_destroy") {
                    // console.log(data)
                    this.current_user_statistics = this.current_user_statistics.filter((datum) => {
                        return datum.id != data.payload.id;
                    });
                    return;
                }

                if (data?.event_name == "trade.MetricDatum.after_create") {
                    this.update_metric_data(data.payload);
                    return;
                }

                if (Array.isArray(data)) {
                    data.forEach((datum) => {
                        this.update_metric_data(datum);
                    });
                }
                this.$store.commit("app/SET_DATA_LIVE_TIME", moment().format(" HH:mm:ss - DD/MM/YYYY"));
            },
            disconnected() {
                console.log("Disconnected to the chat channel");
            },
        },
    },

    mounted() {
        // this.$store.commit('app/SET_DATA_LIVE_TIME', moment().format(' HH:mm:ss - DD/MM/YYYY'))
        this.fetchData();
        //? mockData
        // this.$cable.subscribe({
        //     channel: "MetricDataUpdateChannel",
        // });
    },
};
</script>
<style></style>
