<template>
    <div class="account-analysis">
        <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-1 table-title">Account Analysis - {{ companyCurrency }}</h4>
            <div class="d-flex">
                <span class="text-danger mr-1 cursor-pointer" v-if="isFilterShown" @click="clearFilter"
                    >Clear Search</span
                >
                <font-awesome-icon
                    :icon="['fal', 'filter']"
                    class="mr-1 btn-icon "
                    @click="toggleFilter"
                    v-b-tooltip.hover.top="'Filter'"
                />
                <font-awesome-icon
                    :icon="['far', 'compress-arrows-alt']"
                    class="btn-icon mr-1"
                    v-b-tooltip.hover.top="`Filter out 0 values: ${isFilterZeroShown ? 'on' : 'off'}`"
                    @click="toggleFilterZero"
                />
                <font-awesome-icon
                    :icon="['far', 'cog']"
                    class="btn-icon mr-1"
                    v-b-tooltip.hover.top="'Table Settings'"
                    v-b-modal.dd
                />
                <font-awesome-icon
                    :icon="['far', 'file-export']"
                    class="btn-icon"
                    v-b-tooltip.hover.top="'Export CSV'"
                    @click="$bvModal.show('account-analysis-download-modal')"
                />
                <b-modal
                    id="account-analysis-download-modal"
                    title="Choose format type"
                    centered
                    @ok="exportFile(exportData, exportFormat, 'AccountAnalysis')"
                >
                    <v-select v-model="exportFormat" :clearable="false" label="format" :options="['csv', 'xls']" />
                </b-modal>
            </div>
        </div>

        <b-card no-body class="px-50 rounded my-shadow ">
            <CommonTable
                :isLoading="isLoading"
                :fields="fields"
                :items="dataAfterCollapsed"
                :max-display-rows="maxDisplayRowCount"
                :is-header-small="true"
                :isFilterShown="isFilterShown"
                :isFilterZeroShown="isFilterZeroShown"
                @row-clicked="handleRowClicked"
                @filterChange="filterChange"
                @filterClear="filterClear"
                :canFilterOutZeroValues="canFilterOutZeroValues"
                @filterOutZeroValues="handleFilterOutZeroValues"
                :field_sort="field_sort"
                :isDesc="isDesc"
                @onHeaderClick="(field) => sortBy(field)"
                ref="table"
            >
                <template #summary-row>
                    <b-tr class="text-right">
                        <b-td class="text-center bg-black">Total</b-td>
                        <b-td
                            v-if="fields.map((i) => i.label).includes('Currency')"
                            class="text-center bg-black"
                        ></b-td>

                        <b-td
                            v-for="(field, index) in labels.filter((i) =>
                                fields
                                    .map((i) =>
                                        i.key
                                            .replace('/', '')
                                            .replace(' ', '')
                                            .replace('VolumeTraded', 'VolTraded')
                                    )
                                    .includes(i)
                            )"
                            :key="index"
                            class="bg-black"
                        >
                            <span
                                v-if="['ClosedPL', 'OpenPL', 'NetPL', 'Deposits', 'Withdrawals'].includes(field)"
                                :class="dataTotal[field] >= 0 ? 'text-success' : 'text-danger'"
                            >
                                {{ formatMoney(dataTotal[field], 2) }}
                            </span>
                            <span v-else>
                                {{ formatMoney(dataTotal[field], 2) }}
                            </span>
                        </b-td>
                    </b-tr>
                </template>

                <template #cell(name)="{ item }">
                    <div :style="{ 'padding-left': `${calcNamePadding(item.level)}px` }">
                        <dot v-if="isItemUncollapsible(item)" />
                        <span v-else class="cursor-pointer">
                            <font-awesome-icon
                                :icon="['fas', 'caret-down']"
                                size="lg"
                                class="mr-50"
                                @click="collapse(item)"
                                v-if="!collapsedSet.includes(item[whichpath])"
                            />
                            <font-awesome-icon
                                :icon="['fas', 'caret-right']"
                                size="lg"
                                class="mr-50"
                                @click="uncollapse(item)"
                                v-else
                            />
                        </span>
                        <NameField :name="item.name" />
                    </div>
                </template>
            </CommonTable>
            <div class="py-0 pr-1 d-flex justify-content-between card-footer-color">
                <div class="py-1 d-flex card-footer-color">
                    <FilterPills
                        :isLoading="isLoading"
                        v-model="activeLevels"
                        :levels="levels"
                        @toggleLevel="toggleLevel"
                    />

                    <div class="ml-5 d-flex">
                        <div class="my-auto mr-1 text-heading-color">Timeframe:</div>
                        <v-select
                            class="my-auto select-size-sm width-160px"
                            label="Time Frame"
                            :clearable="false"
                            v-model="time_frame"
                            :options="['Today', 'This Month', 'This Year', 'Overall', 'Custom Timeframe']"
                            :reduce="(time) => time.replace(/\s/g, '')"
                        />
                    </div>

                    <div class="ml-5 d-flex" v-if="time_frame == 'CustomTimeframe'">
                        <div class="d-flex">
                            <div class="my-auto w-50 text-heading-color">Start Date:</div>
                            <flat-pickr v-model="startDateModel" class="form-control" :config="{ maxDate: today }" />
                        </div>
                        <div class="d-flex ml-5">
                            <div class="my-auto w-50 text-heading-color">End Date:</div>
                            <flat-pickr v-model="endDateModel" class="form-control" :config="{ maxDate: today }" />
                        </div>
                        <b-button
                            class="ml-5 px-2"
                            pill
                            size="sm"
                            variant="success"
                            @click="$emit('applyCustomTimeframe', startDate, endDate)"
                            :disabled="!startDate || !endDate || endDate < startDate"
                        >
                            Apply
                        </b-button>
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <div class="mr-3 text-heading-color">
                        Accounts: {{ dataAfterCollapsed.filter((item) => isItemUncollapsible(item)).length }}
                    </div>
                    <RowCountPill v-model="maxDisplayRowCount" />
                </div>
            </div>
        </b-card>
        <TableSettingsModal
            :fields="accountAnalysisFields.filter((i, index) => index > 0)"
            table="Account Analysis"
            @reactivity="key += 1"
            id="dd"
        />
    </div>
</template>
<script>
import CommonTable from "@/components/tables/CommonTable";
import NameField from "@/components/tables/NameField.vue";
import accountAnalysisFields, { get_account_analysis_data } from "./accountAnalysisFields";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import moment from "moment";
import {
    formatMoney,
    exportFile,
    formatNumber,
    setFilterInLocalStorage,
    getFilterInLocalStorage,
    removeFilterInLocalStorage,
    isFilteredByRegion,
} from "@/helper";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import FilterPills from "@/components/tables/LevelPills.vue";
import RowCountPill from "@/components/tables/RowCountPill.vue";
import Dot from "@/components/Dot";
import TableSettingsModal from "@/components/TableSettingModal";
import { mapGetters } from "vuex";

const TABLE_NAME = "AccountAnalysis";

export default {
    components: {
        vSelect,
        flatPickr,
        CommonTable,
        FilterPills,
        Dot,
        TableSettingsModal,
        RowCountPill,
        NameField,
    },
    directives: {
        Ripple,
    },

    mounted() {
        this.initFilter();
        this.$emit("fetchAccountAnalysis");
        axios
            // .get(`${process.env.VUE_APP_API_URL}site_settings/risk_management/group_levels`)
            //? mockData
            .get("/GroupLevel/data")
            .then((res) => {
                this.groupLevels = res.data.settings;
                this.activeLevels = [this.groupLevels.length + 2];
            });

        axios
            // .get(`${process.env.VUE_APP_API_URL}/public_site_settings/account_setup`)
            //? mockData
            .get("/AccountSetup/data")
            .then((res) => {
                // this.currencies = res.data.settings..currency_based;
                //? mockData
                this.currencies = res.data.settings.live1.currency_based;
            });

        axios
            // .get(`${process.env.VUE_APP_API_URL}site_settings/risk_management/group_organization?recursive`)
            //? mockData
            .get("/GroupOrganization/data")
            .then((res) => {
                this.group_organization = {};
                if (res.data?.children) {
                    Object.keys(res.data.children).forEach((key) => {
                        this.group_organization = {
                            ...this.group_organization,
                            ...this.getAllChildren(res.data.children[key], key),
                        };
                    });
                }
            })
            .catch((err) => console.error(err));
    },

    props: ["startDate", "endDate", "isLoading"],

    data() {
        return {
            key: 77,
            accountAnalysisFields,
            time_frame: "ThisMonth",
            today: moment()
                .utc()
                .format("Y-MM-DD"),
            labels: [
                "ClosedPL",
                "OpenPL",
                "PL",
                "CurrentCredit",
                "CurrentEquity",
                "Withdrawable",
                "Commission",
                "Deposits",
                "Withdrawals",
                "VolTraded",
            ],
            groupLevels: [],
            activeLevels: [],
            collapsedSet: [],
            currencies: [],
            isFilterShown: false,
            isFilterZeroShown: true,
            maxDisplayRowCount: 19,
            filterValues: {},
            canFilterOutZeroValues: true,
            filterOutZeroValues: {},
            field_sort: "ClosedPL",
            isDesc: true,
            exportFormat: "csv",
            group_organization: {},
        };
    },

    computed: {
        ...mapGetters({
            is_current_user_statistics_fetched: "tableData/is_current_user_statistics_fetched",
            is_account_analysisfetched: "tableData/is_account_analysis_fetched",
            current_user_statistics: "tableData/current_user_statistics",
            account_analysis: "tableData/account_analysis",
        }),
        startDateModel: {
            get() {
                return this.startDate;
            },
            set(value) {
                this.$emit("setStartDate", value);
            },
        },
        endDateModel: {
            get() {
                return this.endDate;
            },
            set(value) {
                this.$emit("setEndDate", value);
            },
        },
        companyCurrency() {
            const riskMangementSettings = this.$store.getters["companySettings/settingsByPath"](["risk_management"]);
            if (riskMangementSettings.settings) return riskMangementSettings.settings.currency;
            return "";
        },
        fields() {
            let results = accountAnalysisFields;
            let restrict = JSON.parse(localStorage.getItem("Account Analysis Settings"));
            if (this.key && restrict) results = results.filter((i) => !restrict.includes(i.label));

            let currencyField = results.find((item) => item.key == "currency");
            if (currencyField) {
                currencyField.filter.options = [
                    { value: "", text: "All" },
                    ...this.currencies.map((currency) => ({ value: currency, text: currency })),
                ];
            }

            return results;
        },

        levels() {
            let result = this.groupLevels.map((levelName, index) => ({
                name: levelName,
                value: (item) => item.properties.path[index],
            }));

            return [
                ...result,
                {
                    name: "Client",
                    value: (item) =>
                        `${item.properties.mt_account?.name ?? ""} ${item.properties.mt_account?.email ?? ""}`,
                },
                { name: "Account", value: (item) => `${item.properties.server}-${item.properties.login}` },
            ];
        },

        whichpath() {
            let res = "path";
            if (!isFilteredByRegion(this.activeLevels, this.groupLevels.length)) {
                res = "subpath";
            }
            return res;
        },

        dataTotal() {
            let result = this.calculateSum(
                this.labels,
                this.dataAfterGroup.filter((item) => item.level == this.groupLevels.length + 2)
            );
            return result;
        },

        dataAfterCollapsed() {
            let results = JSON.parse(JSON.stringify(this.dataAfterFormat));
            // console.log("this.collapsedSet", this.collapsedSet)
            this.collapsedSet.forEach((path) => {
                results = results.filter((item) => !this.isChildOfParent(item[this.whichpath], path));
            });
            // console.log(results)
            return results;
        },

        dataAfterFormat() {
            let results = JSON.parse(JSON.stringify(this.dataAfterFilteredByZero));
            results = results.map((item) => {
                this.labels.forEach((field) => {
                    item[field] = formatMoney(item[field], 2);
                });
                return item;
            });
            return results;
        },

        dataAfterFilteredByZero() {
            let results = JSON.parse(JSON.stringify(this.dataAfterFiltered));

            Object.keys(this.filterOutZeroValues).forEach((key) => {
                if (this.filterOutZeroValues[key]) {
                    results = results.filter((item) => {
                        if (key != "Risk") return !isNaN(parseFloat(item[key])) && parseFloat(item[key]) !== 0;
                        else return item[key] !== 1;
                    });
                }
            });
            let validPaths = results.map((item) => item[this.whichpath]);
            results = JSON.parse(JSON.stringify(this.dataAfterSort));
            results = results.filter((item) => {
                let bool = false;
                validPaths.forEach((path) => {
                    if (this.isChildOfParent(path, item[this.whichpath]) || path == item[this.whichpath]) {
                        bool = true;
                        return;
                    }
                });
                return bool;
            });
            return results;
        },
        dataAfterFiltered() {
            let results = JSON.parse(JSON.stringify(this.dataAfterSort));
            Object.keys(this.filterValues).forEach((key) => {
                let field = this.fields.find((field) => field.key == key);
                if (field?.filter?.filterFunc) {
                    // console.log(results, this.filterValues[key])
                    results = field.filter.filterFunc(results, this.filterValues[key]);
                } else {
                    if (this.filterValues[key]) {
                        results = results.filter((item) => {
                            return (item[key] ?? "")
                                .toString()
                                .toLowerCase()
                                .includes(this.filterValues[key].toString().toLowerCase());
                        });
                    }
                }
            });
            let validPaths = results.map((item) => item[this.whichpath]);
            results = JSON.parse(JSON.stringify(this.dataAfterSort));
            results = results.filter((item) => {
                let bool = false;
                validPaths.forEach((path) => {
                    if (this.isChildOfParent(path, item[this.whichpath]) || path == item[this.whichpath]) {
                        bool = true;
                        return;
                    }
                });
                return bool;
            });
            return results;
        },

        dataAfterSort() {
            let desc_or_asc = this.isDesc ? -1 : 1;
            let result = [];
            let j = 0;
            // console.log(this.activeLevels)
            this.activeLevels.forEach((level, index) => {
                let pos_or_neg = index == 0 ? desc_or_asc : -desc_or_asc;
                let data = this.dataAfterGroup.filter((datum) => datum.level == level);
                data.sort(
                    (a, b) =>
                        pos_or_neg *
                        (a[this.field_sort] < b[this.field_sort] ? -1 : a[this.field_sort] > b[this.field_sort] ? 1 : 0)
                );

                // Check if table is filtered by region, if not we have to merge client with same email (diffrent region)
                if (
                    !this.isFilteredByRegion(this.activeLevels, this.groupLevels.length) &&
                    level === this.groupLevels.length + 1
                ) {
                    let noDuplicatedData = {};
                    data.forEach((datum) => {
                        if (noDuplicatedData[datum.name]) {
                            noDuplicatedData[datum.name] = this.mergeTwoClients(noDuplicatedData[datum.name], datum);
                        } else noDuplicatedData[datum.name] = datum;
                    });
                    data = Object.values(noDuplicatedData);
                }

                if (index == 0) {
                    result = result.concat(data);
                    j = level;
                } else {
                    // Find correct index and insert the value
                    // console.log(level, index, j, data)
                    data.forEach((current) => {
                        result.forEach((datum, index) => {
                            if (
                                datum.level == j &&
                                this.isChildOfParent(current[this.whichpath], datum[this.whichpath])
                            ) {
                                result.splice(index + 1, 0, current);
                            }
                        });
                    });
                    j = level;
                }
            });
            return result;
        },

        dataAfterGroup() {
            let results = [];
            this.levels.forEach((level, index) => {
                if (this.activeLevels.includes(index + 1)) {
                    let distinctValues = [
                        ...new Set(
                            this.items.map((item) => {
                                let path = item.path.split("/").slice(0, index + 1);
                                if (this.whichpath == "subpath") {
                                    path = path.slice(Math.min(...this.activeLevels) - 1);
                                }
                                return path.join("/");
                            })
                        ),
                    ];

                    results.push(
                        ...distinctValues.map((path) => {
                            let name = path.split("/");
                            name = this.group_organization[path]?.name ?? name[name.length - 1];

                            let data = this.items.filter(
                                (item) =>
                                    this.isChildOfParent(item[this.whichpath], path) || item[this.whichpath] == path
                            );
                            let tmp = this.calculateSum(this.labels, data);

                            if (index == this.levels.length - 1) {
                                tmp = {
                                    ...data[0],
                                    ...tmp,
                                };
                            }

                            return {
                                ...tmp,
                                name: name,
                                level: index + 1,
                                isUncollapsible: index + 1 === Math.max(...this.activeLevels),
                                [this.whichpath]: path,
                            };
                        })
                    );
                }
            });
            return results;
        },

        items() {
            return this.user_statistics
                ?.filter((item) => item?.properties?.path)
                .map((item) => {
                    let group = item.properties.group.split("\\");
                    group = group[group.length - 1];

                    let tmp = this.labels.reduce((obj, field) => {
                        if (item.value?.[field + this.time_frame] == null) {
                            if (item.value?.[field + this.time_frame] === undefined) {
                                obj[field] = item.value?.[field];
                            }
                            obj[field] = obj[field] ?? NaN;
                        } else {
                            obj[field] = item.value?.[field + this.time_frame];
                        }
                        return obj;
                    }, {});

                    // console.log(item.value, tmp)
                    let path = this.levels.map((level) => level.value(item));
                    let subpath = path.slice(Math.min(...this.activeLevels) - 1);
                    return {
                        ...tmp,
                        level: this.groupLevels.length + 2,
                        path: path.join("/"),
                        subpath: subpath.join("/"),
                        name: `${item.properties.server}-${item.properties.login}`,
                        cus_id: item.cus_id,
                        aa_id: item.aa_id,
                        currency: item.properties.currency,
                    };
                });
        },

        user_statistics() {
            return get_account_analysis_data(this.current_user_statistics, this.account_analysis);
        },

        exportData() {
            return [
                [...this.fields.map((field) => field.label)],
                ...this.items.map((item) => {
                    return this.fields.map((field) => {
                        if (!isNaN(item[field.key])) return formatNumber(item[field.key], 2);
                        return item[field.key];
                    });
                }),
            ];
        },
    },

    methods: {
        moment,
        formatMoney,
        exportFile,

        isFilteredByRegion() {
            let check = this.activeLevels.some((level) => level <= this.groupLevels.length);
            return check;
        },
        isChildOfParent(child, parent) {
            let child_path = child.split("/");
            let parent_path = parent.split("/");
            return (
                child_path.length > parent_path.length && child_path.slice(0, parent_path.length).join("/") == parent
            );
        },
        getMail(client) {
            let arr = client.name.split(" ");
            return arr.pop();
        },
        mergeTwoClients(clientA, clientB) {
            let result = clientA;
            Object.keys(clientA).forEach((key) => {
                if (this.fields.map((field) => field.key).includes(key) && !isNaN(clientA[key])) {
                    result[key] += clientB[key];
                }
            });
            // console.log('merge', result)
            return result;
        },

        getAllChildren(node, path) {
            let result = { [path]: node.settings };
            if (node.children) {
                Object.keys(node.children).forEach((key) => {
                    result = { ...result, ...this.getAllChildren(node.children[key], path + "/" + key) };
                });
            }
            return result;
        },

        sortBy(field) {
            if (field) {
                this.field_sort = field;
                this.isDesc = !this.isDesc;
            }
        },

        initFilter() {
            if (getFilterInLocalStorage(TABLE_NAME)) {
                let filterValues = getFilterInLocalStorage(TABLE_NAME);

                this.isFilterShown = true;
                this.$refs.table.initFilter(filterValues);
            }
            if (getFilterInLocalStorage(TABLE_NAME + "Zero")) {
                let filterValues = getFilterInLocalStorage(TABLE_NAME + "Zero");
                this.isFilterZeroShown = filterValues.show ? true : false;
                this.$refs.table.initFilterZero(filterValues);
            }
        },

        toggleFilterZero() {
            this.isFilterZeroShown = !this.isFilterZeroShown;
            if (this.isFilterZeroShown) {
                let filterOutZeroValues = getFilterInLocalStorage(TABLE_NAME + "Zero") || {};

                setFilterInLocalStorage(TABLE_NAME + "Zero", {
                    ...filterOutZeroValues,
                    show: true,
                });
            } else {
                let filterOutZeroValues = getFilterInLocalStorage(TABLE_NAME + "Zero") || {};

                setFilterInLocalStorage(TABLE_NAME + "Zero", {
                    ...filterOutZeroValues,
                    show: false,
                });
            }
        },
        toggleFilter() {
            this.isFilterShown = !this.isFilterShown;
            if (this.isFilterShown) {
                setFilterInLocalStorage(TABLE_NAME, {});
            } else {
                removeFilterInLocalStorage(TABLE_NAME);
            }
        },

        clearFilter() {
            this.$refs.table.clearFilter();
        },

        filterChange(filterValues) {
            Object.keys(filterValues).forEach((key) => {
                this.$set(this.filterValues, key, filterValues[key]);
            });
            if (this.isFilterShown) setFilterInLocalStorage(TABLE_NAME, filterValues);
        },

        filterClear(filterValues) {
            Object.keys(filterValues).forEach((key) => {
                this.$set(this.filterValues, key, filterValues[key]);
            });
            if (this.isFilterShown) setFilterInLocalStorage(TABLE_NAME, filterValues);
        },

        handleFilterOutZeroValues(filterOutZeroValues) {
            Object.keys(filterOutZeroValues).forEach((key) => {
                if (key != "show") this.$set(this.filterOutZeroValues, key, filterOutZeroValues[key]);
            });
            if (this.isFilterZeroShown)
                setFilterInLocalStorage(TABLE_NAME + "Zero", {
                    ...filterOutZeroValues,
                    show: this.isFilterZeroShown,
                });
        },

        toggleFilter() {
            this.isFilterShown = !this.isFilterShown;
        },

        calculateSum(labels, data) {
            return labels.reduce((obj, key) => {
                if (data.length == 1) {
                    // console.log(key, data, data[0][key])
                    obj[key] = data[0][key];
                } else {
                    obj[key] = data.reduce((sum, item) => sum + (isNaN(item[key]) ? 0 : item[key]), 0);
                }
                return obj;
            }, {});
        },

        isItemUncollapsible(item) {
            return item.level == Math.max(...this.activeLevels);
        },

        collapse(item) {
            this.collapsedSet.push(item[this.whichpath]);
        },

        uncollapse(item) {
            this.collapsedSet = this.collapsedSet.filter((path) => path != item[this.whichpath]);
        },

        calcNamePadding(level) {
            return 25 * this.activeLevels.indexOf(level);
        },

        toggleLevel(level) {
            // console.log(level, this.activeLevels)
            this.collapsedSet = this.collapsedSet.filter((path) => path.split("/").length >= level);
        },

        viewAccountDetails(item) {
            item = this.user_statistics.find((x) => x.aa_id == item?.aa_id);
            // console.log(item)
            if (item) this.$emit("viewAccountDetails", item, this.time_frame);
        },

        handleRowClicked(e) {
            // console.log(e)
            this.viewAccountDetails(e.item);
        },
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style lang="scss" scoped>
.account-analysis ::v-deep .vs__dropdown-toggle {
    border-radius: 20px !important;
}

.account-analysis ::v-deep .form-control.flatpickr-input {
    border-radius: 20px !important;
    font-size: 0.75rem;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    height: 30px;
    width: 160px !important;
}

.account-analysis ::v-deep .width-160px {
    width: 160px !important;
}
.scroll-area {
    position: relative;
    margin: auto;
    width: 100%;
    height: 900px;
}
</style>
