var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"statics_card_width"},[_c('statistic-card-with-area-chart',{attrs:{"icon":['far', 'piggy-bank'],"statistic":((_vm.symbolOfCurrency(_vm.companyCurrency)) + " " + (_vm.formatMoney(_vm.dataTotal.PLThisMonth, 2))),"statistic-title":"P/L This month","chart-data":[
                {
                    data: _vm.pLInMonthAgo,
                    name: 'Amount',
                } ],"color":"purple"}})],1),_c('div',{staticClass:"statics_card_width"},[_c('statistic-card-with-area-chart',{attrs:{"icon":['far', 'inbox-in'],"statistic":((_vm.symbolOfCurrency(_vm.companyCurrency)) + " " + (_vm.formatMoney(_vm.dataTotal.MonthlyNetDeposit, 2))),"statistic-title":"Net deposit this month","chart-data":[
                {
                    data: _vm.netDepositInMonthAgo,
                    name: 'Amount',
                } ],"color":"success"}})],1),_c('div',{staticClass:"statics_card_width"},[_c('statistic-card-with-area-chart',{attrs:{"icon":['far', 'piggy-bank'],"statistic":((_vm.symbolOfCurrency(_vm.companyCurrency)) + " " + (_vm.formatMoney(_vm.dataTotal.ActualPL, 2))),"statistic-title":"P/L Today","chart-data":[
                {
                    data: _vm.pLOnDayAgo,
                    name: 'Amount',
                } ],"color":"danger"}})],1),_c('div',{staticClass:"statics_card_width"},[_c('statistic-card-with-area-chart',{attrs:{"icon":['far', 'inbox-in'],"statistic":((_vm.symbolOfCurrency(_vm.companyCurrency)) + " " + (_vm.formatMoney(_vm.dataTotal.NetDeposits, 2))),"statistic-title":"Net Deposit Today","chart-data":[
                {
                    data: _vm.netDepositOnDayAgo,
                    name: 'Amount',
                } ],"color":"warning"}})],1),_c('div',{staticClass:"statics_card_width"},[_c('statistic-card-with-area-chart',{attrs:{"icon":['far', 'piggy-bank'],"statistic":((_vm.symbolOfCurrency(_vm.companyCurrency)) + " " + (_vm.formatMoney(_vm.dataTotal.Withdrawable, 2))),"statistic-title":"Withdrawable","chart-data":[
                {
                    data: _vm.withdrawableAgo,
                    name: 'Amount',
                } ],"color":"info"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }