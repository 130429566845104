<template>
  <div>
    <b-button
      size="sm"
      :class="`my-shadow ml-1 ${activeLevels.includes(index + 1) ? 'button-active': ''}`"
      v-ripple.400="'rgba(60, 60, 60, 0.6)'" variant="outline-dark" pill
      v-for="(item, index) in levels"
      :key="index"
      @click="toggleLevel(index + 1)"
      class="level-pill"
    >
      {{item.name}}
    </b-button>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  props: ["activeLevels", "levels", "isLoading", "disabled"],
  model: {
    prop: "activeLevels",
    event: "updateActiveLevels"
  },
  methods: {
    toggleLevel(level) {
      if (this.isLoading || this.disabled?.includes?.(level)) return
      let newActiveLevels = this.activeLevels
      if (this.activeLevels.includes(level)) {
        newActiveLevels = newActiveLevels.filter(l => l != level)
      } else {
        newActiveLevels.push(level)
        newActiveLevels.sort()
      }
      this.$emit("updateActiveLevels", newActiveLevels)
      this.$emit("toggleLevel", level)
    },
  
  },
  mounted() {
    // console.log(this.levels)
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/variables/_variables-components.scss';
.level-pill {
  min-width: 76px !important;
  font-size: 12px !important;
  &:not(.button-active) {
    color: $theme-dark-headings-color !important;
  }
}

</style>