<template>
	<span v-if="typeof(name) == 'string' && name.includes('@')">
		{{ getClientName(name) }}
		<span class="font-small-2" style="color: #b0b3b8">
			{{ getClientEmail(name) }}
		</span>
	</span>
	<span v-else>
		{{ name }}
	</span>
</template>

<script>
export default {
	props: ['name'],
	methods: {
    getClientName(clientNameString) {
      return clientNameString.split(' ').slice(0, -1).join(' ')
    },
    getClientEmail(clientNameString) {
      let length = clientNameString.split(' ').length
      return clientNameString.split(' ')[length - 1]
    }
	},
	mounted() {
	}
}
</script>

<style>

</style>