<template>
    <b-card class="company-chart" header-class="border-bottom py-75" body-class="pt-2 pb-0">
        <template #header>
            <div class="d-flex justify-content-between align-items-center w-100">
                <div>
                    <span class="font-weight-bolder mb-0 font-small-4 text-heading-color">Deposits/Withdrawals</span>
                </div>
                <b-dropdown :text="timeframe" variant="flat-dark" right toggle-class="py-50 text-heading-color">
                    <b-dropdown-item
                        v-for="tf in timeframes"
                        :key="tf.value"
                        @click="setDayStartEnd(tf.value), (timeframe = tf.label)"
                        class="font-small-3 text-heading-color"
                    >
                        {{ tf.label }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </template>
        <div class="company-chart__today-note" v-if="timeframe === 'Today'">
            Note: Today values are accumulated over hours
        </div>
        <div v-if="fetchingState === 'FETCHED'">
            <div class="d-flex w-100 justify-content-center" style="height: 20px">
                <div class="legend-withdrawal ml-1" :class="{ disabled: disabled[0] }" @click="toggleSeries(0)">
                    Withdrawal
                </div>
                <div class="legend-netdeposit ml-1" :class="{ disabled: disabled[1] }" @click="toggleSeries(1)">
                    Net Deposit
                </div>
                <div class="legend-deposit ml-1" :class="{ disabled: disabled[2] }" @click="toggleSeries(2)">
                    Deposit
                </div>
            </div>
            <!-- <VueApexCharts height="380" :options="chartOptions" :series="chartSeries"></VueApexCharts> -->
            <!-- mockData -->
            <VueApexCharts type="line" height="380" :options="chartOptions_mock" :series="series_mock"></VueApexCharts>
        </div>
        <div v-else>
            <div class="w-100 d-flex justify-content-center align-items-center" style="height: 415px">
                <b-spinner variant="primary"></b-spinner>
            </div>
        </div>
    </b-card>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import DigitalClock from "@/components/DigitalClock.vue";
import apexChartData from "@/views/charts-and-maps/charts/apex-chart/apexChartData";
import chartMixins from "./chartMixins";
export default {
    components: {
        VueApexCharts,
        DigitalClock,
    },
    mixins: [chartMixins],
    data() {
        return {
            apexChartData,
            fetchingState: "FETCHING",
            timeframe: "This Month",

            //? mockData
            series_mock: [
                {
                    name: "Session Duration",
                    data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10],
                },
                {
                    name: "Page Views",
                    data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35],
                },
                {
                    name: "Total Visits",
                    data: [87, 57, 74, 99, 75, 38, 62, 47, 82, 56, 45, 47],
                },
            ],
            chartOptions_mock: {
                chart: {
                    height: 350,
                    type: "line",
                    zoom: {
                        enabled: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    width: [5, 7, 5],
                    curve: "straight",
                    dashArray: [0, 8, 5],
                },
                title: {
                    text: "Page Statistics",
                    align: "left",
                },
                legend: {
                    tooltipHoverFormatter: function(val, opts) {
                        return val + " - " + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + "";
                    },
                },
                markers: {
                    size: 0,
                    hover: {
                        sizeOffset: 6,
                    },
                },
                xaxis: {
                    categories: [
                        "01 Jan",
                        "02 Jan",
                        "03 Jan",
                        "04 Jan",
                        "05 Jan",
                        "06 Jan",
                        "07 Jan",
                        "08 Jan",
                        "09 Jan",
                        "10 Jan",
                        "11 Jan",
                        "12 Jan",
                    ],
                },
                tooltip: {
                    y: [
                        {
                            title: {
                                formatter: function(val) {
                                    return val + " (mins)";
                                },
                            },
                        },
                        {
                            title: {
                                formatter: function(val) {
                                    return val + " per session";
                                },
                            },
                        },
                        {
                            title: {
                                formatter: function(val) {
                                    return val;
                                },
                            },
                        },
                    ],
                },
                grid: {
                    borderColor: "#f1f1f1",
                },
            },
        };
    },
    computed: {
        chartOptions() {
            return {
                ...apexChartData.companyEquityChart.chartOptions,
                colors: this.getColors(apexChartData.companyEquityChart.chartOptions.colors),
            };
        },
    },
    methods: {
        async fetchAll() {
            try {
                this.fetchingState = "FETCHING";
                this.setDayStartEnd("ThisMonth");
                await Promise.all([
                    this.fetchYesterdayHistoryUsingMetricData(0, "WithdrawalToday"),
                    this.fetchYesterdayHistoryUsingMetricData(1, "NetDeposits"),
                    this.fetchYesterdayHistoryUsingMetricData(2, "DepositToday"),

                    this.fetchTodayHistory(0, "CompanyTotalValues", "company", "WithdrawalToday"),
                    this.fetchTodayHistory(1, "CompanyTotalValues", "company", "NetDeposits"),
                    this.fetchTodayHistory(2, "CompanyTotalValues", "company", "DepositToday"),
                    this.fetchCurrentValues("CompanyTotalValues", "company", [
                        "WithdrawalToday",
                        "NetDeposits",
                        "DepositToday",
                    ]),
                ]);
                this.fetchingState = "FETCHED";
                // console.log('dis', this.disabled)
            } catch (error) {
                this.fetchingState = "FAILED";
                console.error(error);
            }
        },
    },
    mounted() {
        this.series = [
            {
                name: "Withdrawal",
                chartType: "line",
            },
            {
                name: "Net Deposit",
                chartType: "line",
            },
            {
                name: "Deposit",
                chartType: "line",
            },
        ];
        this.fetchAll();
        this.interval = setInterval(() => {
            this.fetchCurrentValues("CompanyTotalValues", "company", [
                "WithdrawalToday",
                "NetDeposits",
                "DepositToday",
            ]);
        }, 10 * 1000);
    },
    destroyed() {
        clearInterval(this.interval);
    },
};
</script>
<style scoped lang="scss">
@mixin legend($color) {
    content: " ";
    display: inline-block;
    height: 2px;
    width: 30px;
    transform: translateY(-3px);
    background: $color;
}
.legend-withdrawal::before {
    @include legend(#4472c4);
}
.legend-netdeposit::before {
    @include legend(#28c76f);
}
.legend-deposit::before {
    @include legend(#a5a5a5);
}
.legend-withdrawal,
.legend-netdeposit,
.legend-deposit {
    color: var(--secondary);
    font-size: 0.8rem;
    cursor: pointer;
    &.disabled {
        color: darken($color: #e4e6e8, $amount: 50);
    }
}
</style>
