<template>
    <div>
        <div class="d-flex justify-content-between">
            <h4 class="mb-1 table-title">Today User Statistics - {{ companyCurrency }}</h4>
            <div class="d-flex">
                <span class="text-danger mr-1 cursor-pointer" v-if="isFilterShown" @click="clearFilter"
                    >Clear Search</span
                >
                <font-awesome-icon
                    :icon="['far', 'filter']"
                    class="btn-icon mr-1"
                    v-b-tooltip.hover.top="'Filter'"
                    @click="toggleFilter"
                />
                <font-awesome-icon
                    :icon="['far', 'compress-arrows-alt']"
                    class="btn-icon mr-1"
                    v-b-tooltip.hover.top="`Filter out 0 values: ${isFilterZeroShown ? 'on' : 'off'}`"
                    @click="toggleFilterZero"
                />
                <font-awesome-icon
                    :icon="['far', 'cog']"
                    class="btn-icon mr-1"
                    v-b-tooltip.hover.top="'Table Settings'"
                    v-b-modal.tus
                />
                <font-awesome-icon
                    :icon="['far', 'file-export']"
                    class="btn-icon"
                    v-b-tooltip.hover.top="'Export CSV'"
                    @click="$bvModal.show('tus-download-modal')"
                />
                <b-modal
                    id="tus-download-modal"
                    title="Choose format type"
                    centered
                    @ok="exportFile(exportData, exportFormat, 'TodayUserStatistics')"
                >
                    <v-select v-model="exportFormat" :clearable="false" label="format" :options="['csv', 'xls']" />
                </b-modal>
            </div>
        </div>
        <b-card no-body class="px-50 rounded my-shadow">
            <CommonTable
                :isLoading="isLoading"
                :fields="fields"
                :items="dataAfterCollapsed"
                :max-display-rows="maxDisplayRowCount"
                :isFilterShown="isFilterShown"
                :isFilterZeroShown="isFilterZeroShown"
                @filterChange="filterChange"
                @filterClear="filterClear"
                :canFilterOutZeroValues="canFilterOutZeroValues"
                @filterOutZeroValues="handleFilterOutZeroValues"
                :field_sort="field_sort"
                :isDesc="isDesc"
                @onHeaderClick="(field) => sortBy(field)"
                ref="table"
            >
                <template #summary-row>
                    <b-tr class="text-right">
                        <b-td class="text-center bg-black" colspan="1">Total</b-td>
                        <b-td
                            v-if="fields.map((i) => i.label).includes('Group')"
                            class="text-center bg-black"
                            colspan="1"
                        ></b-td>
                        <b-td v-if="fields.map((i) => i.label).includes('Closed Lots')" class="bg-black">{{
                            formatMoney(dataTotal.ClosedLotsToday, 2)
                        }}</b-td>
                        <b-td v-if="fields.map((i) => i.label).includes('Traded Volume')" class="bg-black">{{
                            formatMoney(dataTotal.ClosedVolumeToday, 2)
                        }}</b-td>
                        <b-td
                            v-if="fields.map((i) => i.label).includes('Closed P/L')"
                            :class="dataTotal.ClosedPLToday >= 0 ? 'bg-black text-success' : 'bg-black text-danger'"
                            >{{ formatMoney(dataTotal.ClosedPLToday, 2) }}</b-td
                        >
                        <b-td v-if="fields.map((i) => i.label).includes('Swap on Closed P/L')" class="bg-black">{{
                            formatMoney(dataTotal.ClosedSwapToday, 2)
                        }}</b-td>
                        <b-td
                            v-if="fields.map((i) => i.label).includes('Total Closed P/L')"
                            :class="
                                dataTotal.TotalClosedPLToday >= 0 ? 'bg-black text-success' : 'bg-black text-danger'
                            "
                            >{{ formatMoney(dataTotal.TotalClosedPLToday, 2) }}</b-td
                        >
                        <b-td v-if="fields.map((i) => i.label).includes('Open Lots')" class="bg-black">{{
                            formatMoney(dataTotal.OpenLots, 2)
                        }}</b-td>
                        <b-td v-if="fields.map((i) => i.label).includes('Open Volume')" class="bg-black">{{
                            formatMoney(dataTotal.OpenVolume, 2)
                        }}</b-td>
                        <b-td
                            v-if="fields.map((i) => i.label).includes('Floating P/L')"
                            :class="dataTotal.FloatingPL >= 0 ? 'bg-black text-success' : 'bg-black text-danger'"
                            >{{ formatMoney(dataTotal.FloatingPL, 2) }}</b-td
                        >
                        <b-td v-if="fields.map((i) => i.label).includes('Swap on Floating P/L')" class="bg-black">{{
                            formatMoney(dataTotal.TotalFloatingSwap, 2)
                        }}</b-td>
                        <b-td
                            v-if="fields.map((i) => i.label).includes('Total Floating P/L')"
                            :class="dataTotal.TotalFloatingPL >= 0 ? 'bg-black text-success' : 'bg-black text-danger'"
                            >{{ formatMoney(dataTotal.TotalFloatingPL, 2) }}</b-td
                        >
                        <b-td v-if="fields.map((i) => i.label).includes('Actual P/L')" class="bg-black">{{
                            formatMoney(dataTotal.ActualPL, 2)
                        }}</b-td>
                        <b-td v-if="fields.map((i) => i.label).includes('Net Deposits')" class="bg-black">{{
                            formatMoney(dataTotal.NetDeposits, 2)
                        }}</b-td>
                        <b-td v-if="fields.map((i) => i.label).includes('Net Credit')" class="bg-black">{{
                            formatMoney(dataTotal.NetCredits, 2)
                        }}</b-td>
                        <b-td
                            v-if="fields.map((i) => i.label).includes('Closed P/L Yesterday')"
                            :class="dataTotal.ClosedPLYesterday >= 2 ? 'bg-black text-success' : 'bg-black text-danger'"
                            >{{ formatMoney(dataTotal.ClosedPLYesterday, 2) }}</b-td
                        >
                    </b-tr>
                </template>

                <template #cell(name)="{ item }">
                    <div :style="{ 'padding-left': `${calcNamePadding(item.level)}px` }">
                        <dot v-if="isItemUncollapsible(item)" />
                        <span v-else class="cursor-pointer">
                            <font-awesome-icon
                                :icon="['fas', 'caret-down']"
                                size="lg"
                                class="mr-50"
                                @click="collapse(item)"
                                v-if="!collapsedSet.includes(item[whichpath])"
                            />
                            <font-awesome-icon
                                :icon="['fas', 'caret-right']"
                                size="lg"
                                class="mr-50"
                                @click="uncollapse(item)"
                                v-else
                            />
                        </span>
                        <NameField :name="item.name" />
                    </div>
                </template>
            </CommonTable>
            <div class="py-1 pr-1 d-flex justify-content-between card-footer-color">
                <FilterPills
                    :isLoading="isLoading"
                    v-model="activeLevels"
                    :levels="levels"
                    @toggleLevel="toggleLevel"
                />
                <div class="d-flex align-items-center">
                    <div class="mr-3 text-heading-color">
                        Accounts: {{ dataAfterCollapsed.filter((item) => isItemUncollapsible(item)).length }}
                    </div>
                    <RowCountPill v-model="maxDisplayRowCount" />
                </div>
            </div>
        </b-card>
        <TableSettingsModal
            :fields="todayUserStatisticsFields.filter((i, index) => index > 0)"
            table="Today User Statistics"
            @reactivity="key += 1"
            id="tus"
        />
    </div>
</template>
<script>
import axios from "@axios";
import CommonTable from "@/components/tables/CommonTable";
import Ripple from "vue-ripple-directive";
import {
    formatMoney,
    exportFile,
    formatNumber,
    setFilterInLocalStorage,
    getFilterInLocalStorage,
    removeFilterInLocalStorage,
    isFilteredByRegion,
    getClientEmail,
    mergeTwoClients,
} from "@/helper";
import FilterPills from "@/components/tables/LevelPills.vue";
import NameField from "@/components/tables/NameField.vue";
import RowCountPill from "@/components/tables/RowCountPill.vue";
import Dot from "@/components/Dot";
import todayUserStatisticsFields from "./todayUserStatisticsFields";
import TableSettingsModal from "@/components/TableSettingModal";
import vSelect from "vue-select";

const TABLE_NAME = "TodayUserStatistics";

export default {
    components: {
        CommonTable,
        FilterPills,
        Dot,
        TableSettingsModal,
        RowCountPill,
        NameField,
        vSelect,
    },
    props: ["today_user_statistics", "isLoading"],
    directives: {
        Ripple,
    },
    data() {
        return {
            exportFormat: "csv",
            key: 10,
            labels: [
                "ClosedLotsToday",
                "ClosedVolumeToday",
                "ClosedPLToday",
                "ClosedSwapToday",
                "TotalClosedPLToday",
                "OpenLots",
                "OpenVolume",
                "FloatingPL",
                "TotalFloatingSwap",
                "TotalFloatingPL",
                "ActualPL",
                "NetDeposits",
                "NetCredits",
                "ClosedPLYesterday",
            ],
            todayUserStatisticsFields,
            groupLevels: [],
            activeLevels: [],
            collapsedSet: [],
            maxDisplayRowCount: 19,
            isFilterShown: false,
            isFilterZeroShown: true,
            filterValues: {},
            filterOutZeroValues: {},
            field_sort: "TotalClosedPLToday",
            isDesc: true,
            group_organization: {},
            canFilterOutZeroValues: true,
        };
    },

    computed: {
        companyCurrency() {
            const riskMangementSettings = this.$store.getters["companySettings/settingsByPath"](["risk_management"]);
            // console.log('currency', riskMangementSettings)
            if (riskMangementSettings.settings) return riskMangementSettings.settings.currency;
            return "";
        },
        fields() {
            let restrict = JSON.parse(localStorage.getItem("Today User Statistics Settings"));
            if (this.key && restrict) return todayUserStatisticsFields.filter((i) => !restrict.includes(i.label));
            return todayUserStatisticsFields;
        },

        fieldsMap() {
            return this.fields.reduce((res, val) => {
                return Object.assign(res, { [val.key]: val });
            }, {});
        },

        levels() {
            let result = this.groupLevels.map((levelName, index) => ({
                name: levelName,
                value: (item) => item.properties.path[index],
            }));
            return [
                ...result,
                {
                    name: "Client",
                    value: (item) =>
                        `${item.properties.mt_account?.name ?? ""} ${item.properties.mt_account?.email ?? ""}`,
                },
                { name: "Account", value: (item) => `${item.properties.server}-${item.properties.login}` },
            ];
        },

        whichpath() {
            let res = "path";
            if (!isFilteredByRegion(this.activeLevels, this.groupLevels.length)) {
                res = "subpath";
            }
            return res;
        },

        dataTotal() {
            let result = this.calculateSum(
                this.labels,
                this.dataAfterGroup.filter((item) => item.level == this.groupLevels.length + 2)
            );
            return result;
        },

        dataAfterCollapsed() {
            let results = JSON.parse(JSON.stringify(this.dataAfterFormat));
            this.collapsedSet.forEach((path) => {
                results = results.filter((item) => !this.isChildOfParent(item[this.whichpath], path));
            });
            // console.log(results)
            return results;
        },

        dataAfterFormat() {
            let results = JSON.parse(JSON.stringify(this.dataAfterFilteredByZero));
            results = results.map((item) => {
                this.labels.forEach((field) => {
                    item[field] = formatMoney(item[field], 2);
                });
                return item;
            });
            return results;
        },

        dataAfterFilteredByZero() {
            let results = JSON.parse(JSON.stringify(this.dataAfterFiltered));

            Object.keys(this.filterOutZeroValues).forEach((key) => {
                if (this.filterOutZeroValues[key]) {
                    results = results.filter((item) => {
                        if (key != "Risk") return !isNaN(parseFloat(item[key])) && parseFloat(item[key]) !== 0;
                        else return item[key] !== 1;
                    });
                }
            });
            let validPaths = results.map((item) => item[this.whichpath]);
            results = JSON.parse(JSON.stringify(this.dataAfterSort));
            results = results.filter((item) => {
                let bool = false;
                validPaths.forEach((path) => {
                    if (this.isChildOfParent(path, item[this.whichpath]) || path == item[this.whichpath]) {
                        bool = true;
                        return;
                    }
                });
                return bool;
            });
            return results;
        },

        dataAfterFiltered() {
            let results = JSON.parse(JSON.stringify(this.dataAfterSort));
            Object.keys(this.filterValues).forEach((key) => {
                let field = this.fields.find((field) => field.key == key);
                if (field?.filter?.filterFunc) {
                    // console.log(results, this.filterValues[key])
                    results = field.filter.filterFunc(results, this.filterValues[key]);
                } else {
                    if (this.filterValues[key]) {
                        results = results.filter((item) => {
                            return (this.formatValue(item, key) ?? "")
                                .toString()
                                .toLowerCase()
                                .includes(this.filterValues[key].toString().toLowerCase());
                        });
                    }
                }
            });
            let validPaths = results.map((item) => item[this.whichpath]);
            results = JSON.parse(JSON.stringify(this.dataAfterSort));
            results = results.filter((item) => {
                let bool = false;
                validPaths.forEach((path) => {
                    if (this.isChildOfParent(path, item[this.whichpath]) || path == item[this.whichpath]) {
                        bool = true;
                        return;
                    }
                });
                return bool;
            });
            return results;
        },

        dataAfterSort() {
            let desc_or_asc = this.isDesc ? -1 : 1;
            let result = [];
            let j = 0;
            this.activeLevels.forEach((level, index) => {
                let pos_or_neg = index == 0 ? desc_or_asc : -desc_or_asc;
                let data = this.dataAfterGroup.filter((datum) => datum.level == level);
                data.sort((a, b) => {
                    let x = a[this.field_sort];
                    let y = b[this.field_sort];
                    if (this.fieldsMap[this.field_sort].displayAbsolute) {
                        x = Math.abs(x);
                        y = Math.abs(y);
                    }
                    return pos_or_neg * (x < y ? -1 : x > y ? 1 : 0);
                });

                // Check if table is filtered by region, if not we have to merge client with same email (diffrent region)
                if (
                    !isFilteredByRegion(this.activeLevels, this.groupLevels.length) &&
                    level === this.groupLevels.length + 1
                ) {
                    let noDuplicatedData = {};
                    data.forEach((datum) => {
                        if (noDuplicatedData[datum.name]) {
                            noDuplicatedData[datum.name] = mergeTwoClients(
                                noDuplicatedData[datum.name],
                                datum,
                                this.fields
                            );
                        } else noDuplicatedData[datum.name] = datum;
                    });
                    data = Object.values(noDuplicatedData);
                }

                if (index == 0) {
                    result = result.concat(data);
                    j = level;
                } else {
                    // Find correct index and insert the value
                    // console.log(level, index, j, data)
                    data.forEach((current) => {
                        result.forEach((datum, index) => {
                            if (
                                datum.level == j &&
                                this.isChildOfParent(current[this.whichpath], datum[this.whichpath])
                            ) {
                                result.splice(index + 1, 0, current);
                            }
                        });
                    });
                    j = level;
                }
            });
            // console.log("dataAfterSort", results)
            return result;
        },

        dataAfterGroup() {
            let results = [];
            // console.log(this.items)

            this.levels.forEach((level, index) => {
                if (this.activeLevels.includes(index + 1)) {
                    let distinctValues = [
                        ...new Set(
                            this.items.map((item) => {
                                let path = item.path.split("/").slice(0, index + 1);
                                if (this.whichpath == "subpath") {
                                    path = path.slice(Math.min(...this.activeLevels) - 1);
                                }
                                return path.join("/");
                            })
                        ),
                    ];

                    results.push(
                        ...distinctValues.map((path) => {
                            let name = path.split("/");
                            name = this.group_organization[path]?.name ?? name[name.length - 1];

                            let data = this.items.filter(
                                (item) =>
                                    this.isChildOfParent(item[this.whichpath], path) || item[this.whichpath] == path
                            );
                            let tmp = this.calculateSum(this.labels, data);

                            if (index == this.levels.length - 1) {
                                tmp = {
                                    ...data[0],
                                    ...tmp,
                                };
                            }

                            return {
                                name: name,
                                ...tmp,
                                level: index + 1,
                                isUncollapsible: index + 1 === Math.max(...this.activeLevels),
                                [this.whichpath]: path,
                            };
                        })
                    );
                }
            });
            // console.log("dataAfterGroup", results)
            return results;
        },

        items() {
            return this.today_user_statistics
                .filter((item) => item?.properties?.path)
                .map((item) => {
                    let groupPath = item.properties.path.join("/");
                    let group = item.properties.group.split("\\");
                    Object.keys(this.group_organization).forEach((key) => {
                        if (key === groupPath) group = this.group_organization[key].name;
                    });
                    let tmp = this.labels.reduce((obj, key) => {
                        obj[key] = item.value?.[key] ?? NaN;
                        return obj;
                    }, {});
                    let path = this.levels.map((level) => level.value(item));
                    let subpath = path.slice(Math.min(...this.activeLevels) - 1);
                    return {
                        ...tmp,
                        level: this.groupLevels.length + 2,
                        path: path.join("/"),
                        subpath: subpath.join("/"),
                        name: item.properties.server + "-" + item.properties.login,
                        group,
                    };
                });
        },

        exportData() {
            return [
                [...this.fields.map((field) => field.label)],
                ...this.items.map((item) => {
                    return this.fields.map((field) => {
                        if (!isNaN(item[field.key])) return formatNumber(item[field.key], 2);
                        return item[field.key];
                    });
                }),
            ];
        },
    },

    watch: {
        today_user_statistics(newVal, oldVal) {
            // console.log("update", newVal)
        },
    },

    methods: {
        formatMoney,
        exportFile,

        isChildOfParent(child, parent) {
            let child_path = child.split("/");
            let parent_path = parent.split("/");
            return (
                child_path.length > parent_path.length && child_path.slice(0, parent_path.length).join("/") == parent
            );
        },

        calculateSum(labels, data) {
            return labels.reduce((obj, key) => {
                if (data.length == 1) {
                    obj[key] = data[0][key];
                } else {
                    obj[key] = data.reduce((sum, item) => sum + (isNaN(item[key]) ? 0 : item[key]), 0);
                }
                return obj;
            }, {});
        },

        sortBy(field) {
            if (field) {
                this.field_sort = field;
                this.isDesc = !this.isDesc;
            }
        },

        initFilter() {
            if (getFilterInLocalStorage(TABLE_NAME)) {
                let filterValues = getFilterInLocalStorage(TABLE_NAME);

                this.isFilterShown = true;
                this.$refs.table.initFilter(filterValues);
            }
            if (getFilterInLocalStorage(TABLE_NAME + "Zero")) {
                let filterValues = getFilterInLocalStorage(TABLE_NAME + "Zero");
                this.isFilterZeroShown = filterValues.show ? true : false;
                this.$refs.table.initFilterZero(filterValues);
            }
        },

        toggleFilterZero() {
            this.isFilterZeroShown = !this.isFilterZeroShown;
            if (this.isFilterZeroShown) {
                let filterOutZeroValues = getFilterInLocalStorage(TABLE_NAME + "Zero") || {};

                setFilterInLocalStorage(TABLE_NAME + "Zero", {
                    ...filterOutZeroValues,
                    show: true,
                });
            } else {
                let filterOutZeroValues = getFilterInLocalStorage(TABLE_NAME + "Zero") || {};

                setFilterInLocalStorage(TABLE_NAME + "Zero", {
                    ...filterOutZeroValues,
                    show: false,
                });
            }
        },
        toggleFilter() {
            this.isFilterShown = !this.isFilterShown;
            if (this.isFilterShown) {
                setFilterInLocalStorage(TABLE_NAME, {});
            } else {
                removeFilterInLocalStorage(TABLE_NAME);
            }
        },

        clearFilter() {
            this.$refs.table.clearFilter();
        },

        filterChange(filterValues) {
            Object.keys(filterValues).forEach((key) => {
                this.$set(this.filterValues, key, filterValues[key]);
            });
            if (this.isFilterShown) setFilterInLocalStorage(TABLE_NAME, filterValues);
        },

        filterClear(filterValues) {
            Object.keys(filterValues).forEach((key) => {
                this.$set(this.filterValues, key, filterValues[key]);
            });
            if (this.isFilterShown) setFilterInLocalStorage(TABLE_NAME, filterValues);
        },

        handleFilterOutZeroValues(filterOutZeroValues) {
            Object.keys(filterOutZeroValues).forEach((key) => {
                if (key != "show") this.$set(this.filterOutZeroValues, key, filterOutZeroValues[key]);
            });
            if (this.isFilterZeroShown)
                setFilterInLocalStorage(TABLE_NAME + "Zero", {
                    ...filterOutZeroValues,
                    show: this.isFilterZeroShown,
                });
        },

        isItemUncollapsible(item) {
            return item.level == Math.max(...this.activeLevels);
        },

        collapse(item) {
            this.collapsedSet.push(item[this.whichpath]);
        },

        uncollapse(item) {
            this.collapsedSet = this.collapsedSet.filter((path) => path != item[this.whichpath]);
        },

        calcNamePadding(level) {
            return 25 * this.activeLevels.indexOf(level);
        },

        toggleLevel(level) {
            // console.log(level, this.activeLevels)
            this.collapsedSet = this.collapsedSet.filter((path) => path.split("/").length >= level);
        },

        getAllChildren(node, path) {
            let result = { [path]: node.settings };
            if (node.children) {
                Object.keys(node.children).forEach((key) => {
                    result = { ...result, ...this.getAllChildren(node.children[key], path + "/" + key) };
                });
            }
            return result;
        },
    },

    mounted() {
        this.initFilter();

        axios
            // .get(`${process.env.VUE_APP_API_URL}site_settings/risk_management/group_levels`)
            // mockData
            .get("/GroupLevel/data")
            .then((res) => {
                this.groupLevels = res.data.settings;
                this.activeLevels = [this.groupLevels.length + 2];
            });

        axios
            // .get(`${process.env.VUE_APP_API_URL}site_settings/risk_management/group_organization?recursive`)
            //? mockData
            .get("/GroupOrganization/data")
            .then((res) => {
                this.group_organization = {};
                if (res.data?.children) {
                    Object.keys(res.data.children).forEach((key) => {
                        this.group_organization = {
                            ...this.group_organization,
                            ...this.getAllChildren(res.data.children[key], key),
                        };
                    });
                }
            })
            .catch((err) => console.error(err));
    },
};
</script>
