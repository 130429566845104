import store from '@/store'

const companyCurrency = () => {
	const riskMangementSettings = store.getters['companySettings/settingsByPath'](['risk_management'])
	if (riskMangementSettings.settings)
		return riskMangementSettings.settings.currency
	return ''
}

export default [
	{
		label: 'Name',
		key: 'name',
		sortField: 'external_id',
		filter: {
			type: "input",
			default: "",
			placeholder: "All",
		},
	},
	{
		label: 'Account',
		key: 'account',
		sortField: 'login',
		filter: {
			type: "input",
			default: "",
			placeholder: "All",
		},
	},
	{
		label: 'Time',
		key: 'time',
		sortField: 'open_time',
	},
	{
		label: 'Reason',
		key: 'reason',
		tdClass: "text-left",
		filter: {
			type: "select",
			options: [
				{ value: "", text: "All" },
				{ value: "CLIENT", text: "CLIENT" },
				{ value: "EXPERT", text: "EXPERT" },
				{ value: "DEALER", text: "DEALER" },
			],
			default: ""
		},
    thStyle: 'min-width: 100px'
	},
	{
		label: 'Type',
		key: 'type',
		sortField: 'action',
		filter: {
			type: "select",
			options: [
				{ value: "", text: "All" },
				{ value: "Buy", text: "Buy" },
				{ value: "Sell", text: "Sell" },
			],
			default: ""
		},
    thStyle: 'min-width: 100px'
	},
	{
		label: 'Symbol',
		key: 'symbol',
		sortField: 'symbol',
	},
	{
		label: 'Volume',
		key: 'volume',
		tdClass: "text-right",
		sortField: 'volume',
	},
	{
		label: 'Swap',
		tdClass: "text-right",
		key: 'storage',
		sortField: 'storage',
		canFilterOutZeroValues: true
	},
	{
		label: 'Profit',
		key: 'profit',
		tdClass: "text-right",
		colorRedGreen: true,
		sortField: 'profit',
		canFilterOutZeroValues: true
	},
	{
		label: 'Currency',
		key: 'currency',
		sortField: 'currency',
		filter: {
			type: "select",
			options: [
				{ value: "", text: "All" },
			],
			default: "",
		}
	},
	{
		label: `Profit (company_currency)`,
		key: 'profit_usd',
		colorRedGreen: true,
		tdClass: "text-right",
		sortField: 'profit_usd',
		canFilterOutZeroValues: true,
    thStyle: 'min-width: 100px'
	}
]