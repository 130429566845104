import axios from "@axios";
import moment from "moment";
import { timeDiff } from "@/helper";
import { endOfDays } from "@/utils/endOfDays";
import { mapGetters } from "vuex";

export default {
    props: {
        dateStart: String,
        dateEnd: String,
    },
    data() {
        return {
            liveUpdateInterval: null,
            series: [
                {
                    name: "",
                    chartType: "line",
                },
            ],
            disabled: [],
            yesterdayHistory: [],
            todayHistory: [],
            currentValues: [],
            dayStart: 0, // dayStart >= dayEnd >= 0, dayStart = i means (today - i days)
            dayEnd: 0,

            timeframes: [
                {
                    label: "Today",
                    value: "Today",
                },
                {
                    label: "This Week",
                    value: "ThisWeek",
                },
                {
                    label: "This Month",
                    value: "ThisMonth",
                },
                {
                    label: "This Year",
                    value: "ThisYear",
                },
            ],
        };
    },
    methods: {
        toggleSeries(index) {
            this.$set(this.disabled, index, !this.disabled[index]);
            if (this.disabled.every((e) => e)) this.$set(this.disabled, index, false);
        },
        setDayStartEnd(timeframe) {
            switch (timeframe) {
                case "Today":
                    this.dayStart = 0;
                    this.dayEnd = 0;
                    break;
                case "ThisWeek":
                    this.dayStart = (new Date().getDay() + 6) % 7;
                    this.dayEnd = 0;
                    break;
                case "ThisMonth":
                    this.dayStart = new Date().getDate() - 1;
                    this.dayEnd = 0;
                    break;
                case "ThisYear":
                    let now = new Date();
                    let start = new Date(now.getFullYear(), 0, 0);
                    let diff = timeDiff(now, start, "D");
                    this.dayStart = Math.min(this.yesterdayHistory[0].length, diff) - 1;
                    this.dayEnd = 0;
                    break;
                case "Overall":
                    this.dayStart = this.yesterdayHistory.length - 1;
                    this.dayEnd = 0;
                    break;
                case "CustomTimeframe":
                    if (this.dateStart && this.dateEnd) {
                        this.dayStart = timeDiff(this.dateStart, new Date().toDateString(), "D") + 1;
                        this.dayEnd = timeDiff(this.dateEnd, new Date().toDateString(), "D") + 1;
                    }
                    break;
            }
        },
        async fetchYesterdayHistoryUsingMetricData(index, metric_value) {
            try {
                let res = await axios
                    // .get(
                    //     `${process.env.VUE_APP_API_URL}/metric/HistoryCompanyTotal/data?filter=company_`
                    // )
                    //? mockData
                    .get("/CurrentUserStatistics/data");

                let history = res.data
                    .sort((a, b) => (a.name < b.name) - (b.name < a.name))
                    .map((datum) => {
                        return {
                            ...datum,
                            timestamps: datum.properties.date,
                            value: datum.value[metric_value],
                        };
                    });
                this.$set(this.yesterdayHistory, index, history);
                this.disabled[index] = false;
            } catch (error) {
                console.error(error);
            }
        },
        async fetchYesterdayHistory(index, metric_def, metric_datum, metric_value) {
            try {
                let filter = {
                    log_rule: "daily",
                };
                let res = await axios
                    // .get(
                    //     `${
                    //         process.env.VUE_APP_API_URL
                    //     }/metric/${metric_def}/data/${metric_datum}/history/${metric_value}?filter=${JSON.stringify(
                    //         filter
                    //     )}`
                    // )
                    //? mockData
                    .get("/CurrentUserStatistics/data");
                this.$set(
                    this.yesterdayHistory,
                    index,
                    res.data.sort((a, b) => (a.timestamps < b.timestamps) - (b.timestamps < a.timestamps))
                );

                this.$set(this.disabled, index, false);
            } catch (err) {
                console.error(err);
            }
        },
        async fetchTodayHistory(index, metric_def, metric_datum, metric_value) {
            try {
                let filter = {
                    log_rule: "hourly",
                };
                //?mockData (don't know the real format data)

                // let res = await axios.get(
                //     `${
                //         process.env.VUE_APP_API_URL
                //     }/metric/${metric_def}/data/${metric_datum}/history/${metric_value}?filter=${JSON.stringify(
                //         filter
                //     )}`
                // );
                let res = await axios.get("/AccountAnalysis/data");

                this.$set(
                    this.todayHistory,
                    index,
                    res.data
                        .sort((a, b) => (a.timestamps < b.timestamps) - (b.timestamps < a.timestamps))
                        .filter((datapoint) => {
                            let datapointDate = moment
                                .utc(datapoint.timestamps)
                                .utcOffset(this.timezoneOffset)
                                .format("YYYY-MM-DD");
                            let todayDate = moment()
                                .utcOffset(this.timezoneOffset)
                                .format("YYYY-MM-DD");
                            return datapointDate == todayDate;
                        })
                );
            } catch (err) {
                console.error(err);
            }
        },
        async fetchCurrentValues(metric_def, metric_datum, metric_value = []) {
            try {
                //?mockData (don't know the real format data)
                // let res = await axios.get(`${process.env.VUE_APP_API_URL}/metric/${metric_def}/data/${metric_datum}`);

                let res = await axios.get("/AccountAnalysis/data");

                if (metric_value.length > 0) {
                    this.currentValues = metric_value.map((name) => {
                        // return res.data.value[name];
                        //? mockData
                        return res.data[0].value[name];
                    });
                }
                this.$store.commit("app/SET_DATA_LIVE_TIME", moment().format(" HH:mm:ss - DD/MM/YYYY"));
            } catch (err) {
                console.error(err);
            }
        },
        seriesOfZeroes(dayStart, dayEnd) {
            let data = [];
            // today
            if (dayStart === dayEnd && dayEnd === 0) {
                let currentMoment = moment().utcOffset(this.timezoneOffset);
                let h = 0;
                while (currentMoment.format("H") != 0) {
                    data.push({
                        x: currentMoment.format("HH") + ":00",
                        y: 0,
                    });
                    h++;
                    currentMoment.subtract(1, "hours");
                }
                return data;
            }
            // this week, this month
            let eod = endOfDays(dayStart - dayEnd + 1, this.timezoneOffset);
            for (let i = dayEnd; i <= dayStart; ++i) {
                data.push({
                    x: moment.parseZone(eod[i - 1]).format("DD/MM"),
                    y: 0,
                });
            }
            return data;
        },
        getColors(defaultColors) {
            let colors = [];
            if (this.disabled && this.disabled.length > 0) {
                this.disabled.forEach((flag, index) => {
                    if (!flag) colors.push(defaultColors[index]);
                });
                return colors;
            }
            return defaultColors;
        },
    },
    computed: {
        ...mapGetters({
            timezoneOffset: "companySettings/timezoneOffset",
        }),
        chartSeriesBeforeToday() {
            return this.series.map((sr, idx) => {
                if (!this.yesterdayHistory[idx])
                    return {
                        name: sr.name,
                        type: sr.chartType,
                        data: this.seriesOfZeroes(this.dayStart, this.dayEnd),
                    };

                let history = this.yesterdayHistory[idx].filter((x) => x.value != null);
                if (history.length == 0) return this.seriesOfZeroes(this.dayStart, this.dayEnd);

                let historyIndex = 0;
                let data = [];
                let dayEnd = this.dayEnd + (this.dayEnd === 0 ? 1 : 0);
                let eod = endOfDays(this.dayStart - this.dayEnd + 1, this.timezoneOffset);

                for (let i = dayEnd; i <= this.dayStart; ++i) {
                    let dayI = eod[i];
                    // find a datapoint in history that match the today (-i days)
                    while (historyIndex < history.length && history[historyIndex].timestamps > dayI) {
                        historyIndex++;
                    }
                    if (historyIndex < history.length && timeDiff(dayI, history[historyIndex].timestamps, "H") < 24) {
                        data.push({
                            x: moment
                                .parseZone(dayI)
                                .utcOffset(this.timezoneOffset)
                                .format("DD/MM"),
                            y: history[historyIndex].value,
                        });
                    } else
                        data.push({
                            x: moment
                                .parseZone(dayI)
                                .utcOffset(this.timezoneOffset)
                                .format("DD/MM"),
                            y: 0,
                        });
                }
                // console.log(sr.name, data)
                return {
                    name: sr.name,
                    type: sr.chartType,
                    data: data.reverse(),
                };
            });
        },
        chartSeriesToday() {
            return this.series.map((sr, idx) => {
                if (!this.todayHistory[idx])
                    return {
                        name: sr.name,
                        type: sr.chartType,
                        data: this.seriesOfZeroes(this.dayStart, this.dayEnd),
                    };
                let data = [];
                let currentMoment = moment.utc().utcOffset(this.timezoneOffset);
                data.push({
                    x: currentMoment.format("HH:mm"),
                    y: this.currentValues[idx],
                });
                let h = 0;
                while (currentMoment.format("H") != 0) {
                    // console.log(currentMoment.format('HH'))
                    data.push({
                        x: currentMoment.format("HH") + ":00",
                        y: this.todayHistory[idx][h]?.value ?? 0,
                    });
                    h++;
                    currentMoment.subtract(1, "hours");
                }
                // console.log(data)
                return {
                    name: sr.name,
                    type: sr.chartType,
                    data: data.reverse(),
                };
            });
        },
        chartSeries() {
            // console.log(this.dayEnd)
            if (this.dayEnd === 0 && this.dayStart === 0) return this.chartSeriesToday;
            if (this.dayEnd > 0) return this.chartSeriesBeforeToday;
            return this.series
                .map((sr, idx) => {
                    let data = this.chartSeriesBeforeToday[idx].data;
                    data = [
                        ...data,
                        {
                            x: moment()
                                .utcOffset(this.timezoneOffset)
                                .format("DD/MM"),
                            y: this.currentValues[idx],
                        },
                    ];
                    return {
                        name: sr.name,
                        type: sr.chartType,
                        data,
                    };
                })
                .filter((sr, idx) => !this.disabled[idx]);
        },
    },
    watch: {
        time_frame(value) {
            this.setDayStartEnd(value);
        },
        dateStart() {
            if (this.dateStart && this.dateEnd) this.setDayStartEnd(this.time_frame);
        },
        dateEnd() {
            if (this.dateStart && this.dateEnd) this.setDayStartEnd(this.time_frame);
        },
    },
    mounted() {},
    destroyed() {
        if (this.liveUpdateInterval) clearInterval(this.liveUpdateInterval);
    },
};
