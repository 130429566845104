export default [
	{
		label: 'Name',
		key: 'name',
		sortField: 'name',
		filter: {
			type: "input",
			default: "",
			placeholder: "All",
		},
	},
	{
		label: 'Exposure Indicator',
		key: 'ExposureIndicator',
		tdStyle: 'min-width: 300px'
	},
	{
		label: 'Net Exposure',
		key: 'NetExposure',
		sortField: 'NetExposure',
		displayAbsolute: true,
	},
	{
		label: 'Risk',
		key: 'Risk',
		sortField: 'Risk',
		filter: {
			type: "select",
			options: [
				{ value: 0, text: "All" },
				{ value: 1, text: "Low" },
				{ value: 2, text: "Medium" },
				{ value: 3, text: "High" },
			],
			default: "",
		},
		// canFilterOutZeroValues: true
	},
	{
		label: 'Company Floating P/L',
		key: 'CompanyFloatingPL',
		sortField: 'CompanyFloatingPL',
    colorRedGreen: true,
		canFilterOutZeroValues: true
	},
	{
		label: 'Net Position Lots',
		key: 'NetPositionLots',
		sortField: 'NetPositionLots',
		canFilterOutZeroValues: true,
		displayAbsolute: true,
	},
	{
		label: 'Long Lots',
		key: 'LongLots',
		sortField: 'LongLots',
    tdClass: "text-right",
		canFilterOutZeroValues: true
	},
	{
		label: 'Short Lots',
		key: 'ShortLots',
		sortField: 'ShortLots',
    tdClass: "text-right",
		canFilterOutZeroValues: true
	},
	{
		label: 'Closed P/L',
		key: 'ClosedPLToday',
		sortField: 'ClosedPLToday',
    tdClass: "text-right",
    colorRedGreen: true,
		canFilterOutZeroValues: true
	},
	{
		label: 'Closed P/L per Lot',
		key: 'ClosedPLPerLot',
		sortField: 'ClosedPLPerLot',
    tdClass: "text-right",
    colorRedGreen: true,
		canFilterOutZeroValues: true
	}
]