<template>
  <!-- Table Settings Modal -->
  <b-modal 
    :id="id" 
    cancel-variant="secondary" 
    ok-only 
    ok-title="Confirm" 
    centered 
    size="xs" 
    class="table-setting-modal"
    body-class="text-left"
    footer-class="d-flex justify-content-center"
    :title="table + ' Settings'" @ok="saveTableSettings" @show="onModalOpen"
  >
    <div v-for="(item, index) in fields" :key="index" >
      <b-form-checkbox
        :value="item"
        v-model="table_settings"
        class="custom-control-primary my-1"
      >
        {{ item.label }}
      </b-form-checkbox>
    </div>
  </b-modal>
</template>
<script>
export default {
  data(){
    return {
      table_settings: [],
      canFilterOutZeroValues: true
    }
  },

  props: ['fields', 'table', 'id'],
  
  methods: {
    saveTableSettings() {
      let restrict = this.fields.filter(i => !this.table_settings.includes(i)).map(i => i.label)
      
      localStorage.setItem(this.table + ' Settings', JSON.stringify(restrict))

      this.$emit('reactivity')
    },
    onModalOpen(){
      let restrict = JSON.parse(localStorage.getItem(this.table + ' Settings'))
      if(restrict){
        this.table_settings = this.fields.filter(i => !restrict.includes(i.label))
      }
      else this.table_settings = this.fields
    }
  },
};
</script>