import { formatMoney } from '@/helper'
export default [
	{
		key: "name",
		sortField: 'name',
		label: "Name",
		thStyle: 'min-width: 150px',
		filter: {
			type: "input",
			default: "",
			placeholder: "All",
		},
	},
	{
		key: "group",
		sortField: 'group',
		label: "Group",
		thStyle: 'min-width: 150px',
		filter: {
			type: "input",
			default: "",
			placeholder: "All",
		},
	},
	{
		key: "ClosedLotsToday",
		sortField: 'ClosedLotsToday',
		label: "Closed Lots",
		tdClass: 'text-right',
		canFilterOutZeroValues: true
	},
	{
		key: "ClosedVolumeToday",
		sortField: 'ClosedVolumeToday',
		label: "Traded Volume",
		tdClass: 'text-right',
		canFilterOutZeroValues: true
	},
	{
		key: "ClosedPLToday",
		sortField: 'ClosedPLToday',
		label: "Closed P/L",
		tdClass: 'text-right',
		colorRedGreen: true,
		canFilterOutZeroValues: true
	},
	{
		key: "ClosedSwapToday",
		sortField: 'ClosedSwapToday',
		label: "Swap on Closed P/L",
		tdClass: 'text-right',
		canFilterOutZeroValues: true
	},
	{
		key: "TotalClosedPLToday",
		sortField: 'TotalClosedPLToday',
		label: "Total Closed P/L",
		tdClass: 'text-right',
		colorRedGreen: true,
		canFilterOutZeroValues: true
	},
	{
		key: "OpenLots",
		sortField: 'OpenLots',
		label: "Open Lots",
		tdClass: 'text-right',
		canFilterOutZeroValues: true,
	},
	{
		key: "OpenVolume",
		sortField: 'OpenVolume',
		label: "Open Volume",
		tdClass: 'text-right',
		canFilterOutZeroValues: true
	},
	{
		key: "FloatingPL",
		sortField: 'FloatingPL',
		label: "Floating P/L",
		tdClass: 'text-right',
		colorRedGreen: true,
		canFilterOutZeroValues: true
	},
	{
		key: "TotalFloatingSwap",
		sortField: 'TotalFloatingSwap',
		label: "Swap on Floating P/L",
		tdClass: 'text-right',
		canFilterOutZeroValues: true
	},
	{
		key: "TotalFloatingPL",
		sortField: 'TotalFloatingPL',
		label: "Total Floating P/L",
		tdClass: 'text-right',
		colorRedGreen: true,
		canFilterOutZeroValues: true
	},
	{
		key: "ActualPL",
		sortField: 'ActualPL',
		label: "Actual P/L",
		tdClass: 'text-right',
		canFilterOutZeroValues: true
	},
	{
		key: "NetDeposits",
		sortField: 'NetDeposits',
		label: "Net Deposits",
		tdClass: 'text-right',
		canFilterOutZeroValues: true
	},
	{
		key: "NetCredits",
		sortField: 'NetCredits',
		label: "Net Credit",
		tdClass: 'text-right',
		canFilterOutZeroValues: true
	},
	{
		key: "ClosedPLYesterday",
		sortField: 'ClosedPLYesterday',
		label: "Closed P/L Yesterday",
		tdClass: 'text-right',
		colorRedGreen: true,
		canFilterOutZeroValues: true
	}
]