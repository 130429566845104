export default [
	{
		label: 'Name',
		key: 'name',
    sortField: 'name',
    filter: {
      type: "input",
      default: "",
      placeholder: "All",
    },
	},
	{
		label: 'Symbol',
		key: 'symbol',
    filter: {
      type: "input",
      default: "",
      placeholder: "All",
    },
    sortField: 'symbol',
	},
	{
		label: 'Net Vol',
		key: 'NetPositionLots',
		sortable: true,
    colorRedGreen: true,
    sortField: 'NetPositionLots',
    tdClass: "text-right",
    canFilterOutZeroValues: true,
    displayAbsolute: true,
    thStyle: 'min-width: 100px'
	},
	{
		label: 'Exposure',
		key: 'NetExposure',
		sortable: true,
    colorRedGreen: true,
    sortField: 'NetExposure',
    tdClass: "text-right",
    canFilterOutZeroValues: true,
    displayAbsolute: true,
    thStyle: 'min-width: 100px'
	},
	{
		label: 'Risk',
		key: 'Risk',
		sortable: true,
    sortField: 'Risk',
    filter: {
      type: "select",
      options: [
        {value: 0, text: "All"},
        {value: 1, text: "Low"},
        {value: 2, text: "Medium"},
        {value: 3, text: "High"},
      ],
      default: "",
    },
    // canFilterOutZeroValues: true
	},
]