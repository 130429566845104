<template>
    <span class="dot" :style="`height: ${size}px; width: ${size}px`"></span>
</template>
<script>
export default {
    props: {
        size: {
            type: Number,
            default: 5
        }
    }
}
</script>
<style scoped>
.dot {
    margin-right: 2px;  
    background-color: #B0B3B8;
    border-radius: 50%;
    display: inline-block;
}
</style>