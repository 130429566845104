export default [
    {
        label: "Name",
        key: "name",
        sortField: "name",
        filter: {
            type: "input",
            default: "",
            placeholder: "All",
        },
        thStyle: "min-width: 150px",
    },
    {
        label: "Currency",
        key: "currency",
        sortField: "currency",
        filter: {
            type: "select",
            options: [{ value: "", text: "All" }],
            default: "",
        },
    },
    {
        label: "Closed P/L",
        key: "ClosedPL",
        sortField: "ClosedPL",
        tdClass: "text-right",
        colorRedGreen: true,
        canFilterOutZeroValues: true,
    },
    {
        label: "Open P/L",
        key: "OpenPL",
        sortField: "OpenPL",
        tdClass: "text-right",
        colorRedGreen: true,
        canFilterOutZeroValues: true,
    },
    {
        label: "Net P/L",
        key: "PL",
        sortField: "PL",
        tdClass: "text-right",
        colorRedGreen: true,
        canFilterOutZeroValues: true,
    },
    {
        label: "Current Credit",
        key: "CurrentCredit",
        sortField: "CurrentCredit",
        tdClass: "text-right",
        canFilterOutZeroValues: true,
    },
    {
        label: "Current Equity",
        key: "CurrentEquity",
        sortField: "CurrentEquity",
        tdClass: "text-right",
        canFilterOutZeroValues: true,
    },
    {
        label: "Withdrawable",
        key: "Withdrawable",
        sortField: "Withdrawable",
        tdClass: "text-right",
        canFilterOutZeroValues: true,
    },
    {
        label: "Commission",
        key: "Commission",
        sortField: "Commission",
        tdClass: "text-right",
        canFilterOutZeroValues: true,
    },
    {
        label: "Deposits",
        key: "Deposits",
        sortField: "Deposits",
        tdClass: "text-right",
        canFilterOutZeroValues: true,
        colorRedGreen: true,
    },
    {
        label: "Withdrawals",
        key: "Withdrawals",
        sortField: "Withdrawals",
        tdClass: "text-right",
        canFilterOutZeroValues: true,
        colorRedGreen: true,
    },
    {
        label: "Volume Traded",
        key: "VolTraded",
        sortField: "VolTraded",
        tdClass: "text-right",
        canFilterOutZeroValues: true,
    },
];

export const convert_cus_value_name = {
    MonthlyVolume: "VolTradedThisMonth",
    MonthlyNetDeposit: "NetDepositsThisMonth",
    TotalClosedPLToday: "ClosedPLToday",
    ActualPL: "PLToday",
    AgentCommissionToday: "CommissionToday",
    DepositToday: "DepositsToday",
    WithdrawalToday: "WithdrawalsToday",
    ClosedVolumeToday: "VolTradedToday",
    NetDeposits: "NetDepositsToday",
    TotalClosedPLToday: "ClosedPLToday",
    ClosedLotsToday: "LotsTradedToday",
    TotalFloatingPL: "OpenPL",
    NetPLCustomTimeframe: "PLCustomTimeframe",
    AgentCommissionCustomTimeframe: "CommissionCustomTimeframe",
};

export const inv_cus_value_name = () => {
    return Object.entries(convert_cus_value_name).reduce((obj, [key, val]) => Object.assign(obj, { [val]: key }), {});
};

export const get_account_analysis_data = (cus_data, aa_data) => {
    let res = {};
    cus_data.forEach((datum) => {
        datum.value = Object.entries(datum.value).reduce(
            (obj, [key, val]) => Object.assign(obj, { [convert_cus_value_name[key] ?? key]: val }),
            {}
        );
        res[datum.name] = datum;
        res[datum.name].cus_id = datum.id;
    });
    aa_data.forEach((datum) => {
        let tmp_value = Object.entries(datum.value).reduce(
            (obj, [key, val]) =>
                key.includes("CustomTimeframe")
                    ? Object.assign(obj, { [convert_cus_value_name[key] ?? key]: val })
                    : obj,
            {}
        );
        res[datum.name].aa_id = datum.id;
        res[datum.name].value = {
            ...res[datum.name].value,
            ...tmp_value,
        };
    });
    // console.log(res)
    return Object.values(res);
};
