export default [
	{
		label: 'Symbol',
		key: 'symbol',
		sortField: 'symbol',
		tdClass: 'text-center',
		filter: {
			type: "input",
			default: "",
			placeholder: "All",
		},
	},
	{
		label: 'Open P/L',
		key: 'openPL',
		sortField: 'openPL',
		tdClass: 'text-right',
		canFilterOutZeroValues: true,
	},
	{
		label: 'Closed P/L',
		key: 'closedPL',
		sortField: 'closedPL',
		tdClass: 'text-right',
		canFilterOutZeroValues: true,
	},
	{
		label: 'Net P/L',
		key: 'netPL',
		sortField: 'netPL',
		tdClass: 'text-right',
		canFilterOutZeroValues: true,
	},
	{
		label: 'Volume Traded',
		key: 'volumeTraded',
		sortField: 'volumeTraded',
		tdClass: 'text-right',
		canFilterOutZeroValues: true,
	},
	{
		label: 'Lots Traded',
		key: 'lotsTraded',
		sortField: 'lotsTraded',
		tdClass: 'text-right',
		canFilterOutZeroValues: true,
	}
]