export const NUM_DAYS = 366
export const DAY_STEP = 1

const endOfDays = (len, timezoneOffset /* minute */) => {
  let output = []
  for (let i = 0; i < len; i += DAY_STEP) {
    let date = new Date();
    date.setDate(date.getDate() - i)
    date.setUTCHours(23 - timezoneOffset / 60, 59, 59, 999)
    output.push(date.toISOString())

  }
  return output
}
export { endOfDays }