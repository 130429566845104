<template>
	<span>
		<span class="font-small-3 text-secondary">
			{{ time }}
		</span>
	</span>
</template>

<script>
import moment from 'moment'
export default {
	data() {
		return {
			time: moment().format('HH:mm:ss - DD/MM/YYYY'),
			updateInterval: null
		}
	},
	mounted() {
		this.updateInterval = setInterval(() => {
			this.time = moment().format('HH:mm:ss - DD/MM/YYYY')
		}, 1000)
	},
	destroyed() {
		clearInterval(this.updateInterval)
	}
}
</script>

<style>

</style>