<template>
    <div class="company-chart">
        <b-card header-class="border-bottom py-1" body-class="pt-2">
            <template #header>
                <div>
                    <span class="font-weight-bolder font-small-4">
                        Client Profit History
                    </span>
                    <DigitalClock />
                </div>
            </template>
            <div class="company-chart__today-note" v-if="time_frame === 'Today'">
                Note: Today values are accumulated over hours
            </div>
            <div>
                <!-- <VueApexCharts type="bar" height="400" :options="chartOptions" :series="chartSeries"></VueApexCharts> -->

                <!-- mockData -->
                <VueApexCharts
                    type="line"
                    height="380"
                    :options="chartOptions_mock"
                    :series="series_mock"
                ></VueApexCharts>
            </div>
        </b-card>
    </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import DigitalClock from "@/components/DigitalClock";
import apexChartData from "@/views/charts-and-maps/charts/apex-chart/apexChartData";
import chartMixins from "./chartMixins";
import axios from "@axios";

export default {
    components: {
        VueApexCharts,
        DigitalClock,
    },
    data() {
        return {
            //? mockData
            series_mock: [
                {
                    name: "Fake Title 1",
                    type: "column",
                    data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160],
                    color: "#28c76f",
                },
            ],
            chartOptions_mock: {
                chart: {
                    height: 350,
                    type: "line",
                },
                stroke: {
                    width: [0, 4],
                },

                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1],
                },
                labels: [
                    "01 Jan 2001",
                    "02 Jan 2001",
                    "03 Jan 2001",
                    "04 Jan 2001",
                    "05 Jan 2001",
                    "06 Jan 2001",
                    "07 Jan 2001",
                    "08 Jan 2001",
                    "09 Jan 2001",
                    "10 Jan 2001",
                    "11 Jan 2001",
                    "12 Jan 2001",
                ],
                xaxis: {
                    type: "datetime",
                },
            },
        };
    },
    props: {
        current_item: Object,
        time_frame: String,
        parent_timeframe: String,
        flag: Boolean,
    },
    mixins: [chartMixins],
    methods: {},
    computed: {
        chartOptions() {
            return {
                ...apexChartData.accountProfitHistory.chartOptions,
                xaxis: {
                    ...apexChartData.accountProfitHistory.chartOptions.xaxis,
                    tickAmount: Math.min(24, this.dayStart - this.dayEnd),
                    tickPlacement: "on",
                },
            };
        },
        thisMomentFormatted() {
            return moment().format("HH:mm:ss");
        },
    },
    watch: {
        current_item() {
            // this.fetchYesterdayHistoryUsingMetricHistory('CurrentUserStatistics', this.current_item.name, 'PLYesterday')
            this.fetchYesterdayHistoryUsingMetricData(this.current_item.name, "ActualPL");
            this.fetchTodayHistory("CurrentUserStatistics", this.current_item.name, "ActualPL");
            this.fetchCurrentValue("CurrentUserStatistics", this.current_item.name, "ActualPL");
        },
    },
    mounted() {
        this.fetchYesterdayHistoryUsingMetricData(this.current_item.name, "ActualPL");
        // this.fetchYesterdayHistoryUsingMetricHistory('CurrentUserStatistics', this.current_item.name, 'PLYesterday')
        this.fetchTodayHistory("CurrentUserStatistics", this.current_item.name, "ActualPL");
        this.fetchCurrentValue("CurrentUserStatistics", this.current_item.name, "ActualPL");
        this.setDayStartEnd("ThisMonth");
        this.liveUpdateInterval = setInterval(() => {
            this.fetchCurrentValue("CurrentUserStatistics", this.current_item.name, "ActualPL");
        }, 10 * 1000);
    },
};
</script>
