<template>
    <div>
        <div class="d-flex justify-content-between ">
            <h4 class="mb-1 table-title">Market Exposure</h4>
            <div class="d-flex">
                <span class="text-danger mr-1 cursor-pointer" v-if="isFilterShown" @click="clearFilter"
                    >Clear Search</span
                >
                <font-awesome-icon
                    :icon="['far', 'filter']"
                    class="btn-icon mr-1"
                    v-b-tooltip.hover.top="'Filter'"
                    @click="toggleFilter"
                />
                <font-awesome-icon
                    :icon="['far', 'compress-arrows-alt']"
                    class="btn-icon mr-1"
                    v-b-tooltip.hover.top="`Filter out 0 values: ${isFilterZeroShown ? 'on' : 'off'}`"
                    @click="toggleFilterZero"
                />
                <font-awesome-icon
                    :icon="['far', 'cog']"
                    class="btn-icon mr-1"
                    v-b-tooltip.hover.top="'Table Settings'"
                    v-b-modal.me
                />
                <font-awesome-icon
                    :icon="['far', 'file-export']"
                    class="btn-icon"
                    v-b-tooltip.hover.top="'Export CSV'"
                    @click="$bvModal.show('market-exposure-download-modal')"
                />
                <b-modal
                    id="market-exposure-download-modal"
                    title="Choose format type"
                    centered
                    @ok="exportFile(exportData, exportFormat, tableName)"
                >
                    <v-select v-model="exportFormat" :clearable="false" label="format" :options="['csv', 'xls']" />
                </b-modal>
            </div>
        </div>
        <b-card no-body class="my-shadow px-50" :key="key">
            <CommonTable
                :isLoading="isLoading"
                :fields="fields"
                :items="dataAfterCollapsed"
                :max-display-rows="maxDisplayRowCount"
                :has-summary-row="false"
                :is-header-small="true"
                :isFilterShown="isFilterShown"
                @filterChange="filterChange"
                @filterClear="filterClear"
                :isFilterZeroShown="isFilterZeroShown"
                @filterOutZeroValues="handleFilterOutZeroValues"
                :field_sort="field_sort"
                :isDesc="isDesc"
                @onHeaderClick="(field) => sortBy(field)"
                ref="table"
            >
                <template #cell(name)="{ item }">
                    <div :style="{ 'padding-left': `${calcNamePadding(item.level)}px` }">
                        <dot v-if="isItemUncollapsible(item)" />
                        <span v-else class="cursor-pointer">
                            <font-awesome-icon
                                :icon="['fas', 'caret-down']"
                                size="lg"
                                class="mr-50"
                                @click="collapse(item)"
                                v-if="!collapsedSet.includes(item[whichpath])"
                            />
                            <font-awesome-icon
                                :icon="['fas', 'caret-right']"
                                size="lg"
                                class="mr-50"
                                @click="uncollapse(item)"
                                v-else
                            />
                        </span>
                        <NameField :name="item.name" />
                    </div>
                </template>
                <template #cell(ExposureIndicator)="data">
                    <div class="text-left" style="min-width: 300px">
                        <div class="position-relative" v-if="data.item.ExposureIndicator">
                            <div
                                :style="{
                                    height: '15px',
                                    width: `${(data.item.ExposureIndicator[0] * 50) / maximumOfExposureIndicator}%`,
                                    top: '-7px',
                                    right: '50%',
                                }"
                                class="bg-success position-absolute"
                            ></div>
                            <div
                                :style="{
                                    height: '15px',
                                    width: `${(Math.abs(data.item.ExposureIndicator[1]) * 50) /
                                        maximumOfExposureIndicator}%`,
                                    top: '-7px',
                                    left: '50%',
                                }"
                                class="bg-danger position-absolute"
                            ></div>
                        </div>
                    </div>
                </template>
                <template #cell(NetExposure)="data">
                    <div :class="{ 'text-danger': data.item.NetExposure < 0 }" class="text-right">
                        <span>
                            {{ symbolOfCurrency(companyCurrency) }}{{ formatMoney(Math.abs(data.item.NetExposure), 0) }}
                        </span>
                    </div>
                </template>
                <template #cell(NetPositionLots)="data">
                    <div :class="{ 'text-danger': data.item.NetPositionLots < 0 }" class="text-right">
                        <span>
                            {{ formatMoney(Math.abs(data.item.NetPositionLots), 2) }}
                        </span>
                    </div>
                </template>
                <template #cell(Risk)="data">
                    <div>
                        <div
                            @click="log_item(data.item)"
                            :class="riskStyle(calculateRisk(data.item.symbol, data.item.NetExposure))"
                            style="width: 10px; height: 10px; border-radius: 5px"
                        />
                    </div>
                </template>
                <template #cell(CompanyFloatingPL)="data">
                    <div
                        :class="
                            `${data.item.CompanyFloatingPL.toString().includes('-') ? 'text-danger' : 'text-success'}`
                        "
                        class="text-right"
                    >
                        {{ symbolOfCurrency(companyCurrency) }}{{ data.item.CompanyFloatingPL }}
                    </div>
                </template>
                <!-- <template #cell(NetPositionLots)="data">
          <div :class="{'text-danger': data.item.NetPositionLots < 0}" class="text-right">
            {{ data.item.NetPositionLots != null ? formatMoney(Math.abs(data.item.NetPositionLots)) : ''}}
          </div>
        </template> -->
                <!-- <template #cell(LongLots)="data">
          <div class="text-right">
            {{formatMoney(data.item.LongLots)}}
          </div>
        </template>
        <template #cell(ShortLots)="data">
          <div  class="text-right">
            {{formatMoney(data.item.ShortLots)}}
          </div>
        </template> -->
                <!-- <template #cell(ClosedPLToday)="data"> 
          <div :class="`${data.item.ClosedPLToday < 0 ? 'text-danger' : 'text-success'}`" class="text-right">
            {{ symbolOfCurrency(companyCurrency) }}{{formatMoney(data.item.ClosedPLToday)}}
          </div>
        </template>
        <template #cell(ClosedPLPerLot)="data">
          <div :class="`${data.item.ClosedPLPerLot < 0 ? 'text-danger' : 'text-success'}`" class="text-right">
            {{ symbolOfCurrency(companyCurrency) }}{{formatMoney(data.item.ClosedPLPerLot)}}
          </div>
        </template> -->
            </CommonTable>
            <div class="py-1 pr-1 d-flex justify-content-between card-footer-color">
                <FilterPills
                    :isLoading="isLoading"
                    v-model="activeLevels"
                    :levels="levels"
                    :disabled="[group_level + 1]"
                    @toggleLevel="toggleLevel"
                />
                <div class="d-flex align-items-center">
                    <div class="mr-3 text-heading-color">Symbols: {{ uniqueCurrentSymbols.length }}</div>
                    <RowCountPill v-model="maxDisplayRowCount" />
                </div>
            </div>
        </b-card>
        <TableSettingsModal
            :fields="marketExposureFields.filter((i, index) => index > 0)"
            table="Martket Exposure"
            @reactivity="key += 1"
            id="me"
        />
    </div>
</template>
<script>
import marketExposureFields from "./fields/marketExposureFields.js";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import {
    formatMoney,
    exportFile,
    formatNumber,
    setFilterInLocalStorage,
    getFilterInLocalStorage,
    removeFilterInLocalStorage,
    symbolOfCurrency,
    isFilteredByRegion,
    mergeTwoClients,
    getClientEmail,
    getPathWithoutGroup,
} from "@/helper";
import moment from "moment";
import Dot from "@/components/Dot";
import CommonTable from "@/components/tables/CommonTable.vue";
import RowCountPill from "@/components/tables/RowCountPill.vue";
import TableFunctions from "@/components/tables/TableFunctions.vue";
import { VBTooltip } from "bootstrap-vue";
import TableSettingsModal from "@/components/TableSettingModal";
import NameField from "@/components/tables/NameField";
import vSelect from "vue-select";
import riskStyle from "@/utils/riskStyle";
import FilterPills from "@/components/tables/LevelPills.vue";
import { mapGetters } from "vuex";

const TABLE_NAME = "MarketExposure";

export default {
    components: {
        Dot,
        CommonTable,
        TableSettingsModal,
        RowCountPill,
        NameField,
        FilterPills,
        vSelect,
        TableFunctions,
    },

    directives: {
        "b-tooltip": VBTooltip,
        Ripple,
    },

    props: {},

    data() {
        return {
            tableName: TABLE_NAME,
            key: 1,
            activeLevels: [],

            labels: [
                "ExposureIndicator",
                "NetExposure",
                "Risk",
                "CompanyFloatingPL",
                "NetPositionLots",
                "LongLots",
                "ShortLots",
                "ClosedPLToday",
                "ClosedPLPerLot",
            ],
            numberLabels: [
                "ExposureIndicator",
                "NetPositionLots",
                "NetExposure",
                "CompanyFloatingPL",
                "LongLots",
                "ShortLots",
                "ClosedPLToday",
                "ClosedPLPerLot",
            ],

            collapsedSet: [],

            group_organization: {},

            field_sort: "NetExposure",
            isDesc: true,

            isLoading: false,

            marketExposureFields,
            maxDisplayRowCount: 19,
            isFilterShown: false,
            filterValues: {},
            isFilterZeroShown: true,
            filterOutZeroValues: {},

            exportFormat: "csv",
        };
    },

    computed: {
        ...mapGetters({
            market_exposure: "tableData/market_exposure",
            risk_threshold: "tableData/risk_threshold",
            groupLevels: "tableData/group_levels",
            group_level: "tableData/max_group_level",
        }),

        companyCurrency() {
            const riskMangementSettings = this.$store.getters["companySettings/settingsByPath"](["risk_management"]);
            if (riskMangementSettings.settings) return riskMangementSettings.settings.currency;
            return "";
        },
        exportData() {
            return [
                [...this.fields.map((field) => field.label)],
                ...this.items
                    .filter((item) => item.NetExposure != 0)
                    .map((item) => {
                        return [
                            item.name + "-" + item.symbol,
                            item.ExposureIndicator
                                ? item.ExposureIndicator.map((e) => formatNumber(e, 2)).join("/")
                                : "",
                            formatNumber(item.NetExposure, 2),
                            item.Risk,
                            formatNumber(item.CompanyFloatingPL, 2),
                            formatNumber(item.NetPositionLots, 2),
                            formatNumber(item.LongLots, 2),
                            formatNumber(item.ShortLots, 2),
                            formatNumber(item.ClosedPLToday, 2),
                            formatNumber(item.ClosedPLPerLot, 2),
                        ];
                    }),
            ];
        },
        fields() {
            let restrict = JSON.parse(localStorage.getItem("Market Exposure Settings"));
            if (this.key && restrict) return marketExposureFields.filter((i) => !restrict.includes(i.label));
            return marketExposureFields;
        },

        fieldsMap() {
            return this.fields.reduce((res, val) => {
                return Object.assign(res, { [val.key]: val });
            }, {});
        },

        levels() {
            let result = this.groupLevels.map((levelName, index) => ({
                name: levelName,
                value: (item) => item.path[index],
            }));
            return [
                ...result,
                { name: "Symbol", value: (item) => `${item.properties.symbol}` },
                { name: "Client", value: (item) => `${item.properties.user}` },
                {
                    name: "Account",
                    value: (item) => `${item.properties.server}-${item.properties.login}-${item.properties.symbol}`,
                },
            ];
        },

        whichpath() {
            let res = "path";
            if (!isFilteredByRegion(this.activeLevels, this.group_level)) {
                res = "subpath";
            }
            return res;
        },

        maximumOfExposureIndicator() {
            return this.dataAfterCollapsed.reduce((maximum, item) => {
                return Math.max(maximum, item.ExposureIndicator[0], item.ExposureIndicator[1]);
            }, 0);
        },

        dataAfterCollapsed() {
            let results = JSON.parse(JSON.stringify(this.dataAfterFormat));
            this.collapsedSet.forEach((path) => {
                results = results.filter((item) => !this.isChildOfParent(item[this.whichpath], path));
            });
            return results;
        },

        dataAfterFormat() {
            let results = JSON.parse(JSON.stringify(this.dataAfterFilteredByZero));
            results = results.map((item) => {
                this.fields.forEach(({ key }) => {
                    item[key] = this.formatValue(item, key);
                });
                return item;
            });
            return results;
        },

        dataAfterFilteredByZero() {
            let results = JSON.parse(JSON.stringify(this.dataAfterFiltered));
            let filter = { ...this.filterOutZeroValues, NetExposure: true };
            Object.keys(filter).forEach((key) => {
                if (filter[key] && key !== "show") {
                    results = results.filter((item) => {
                        if (key != "Risk") return parseFloat(item[key]) !== 0;
                        else return this.calculateRisk(item.symbol, item.NetExposure) !== 1;
                    });
                }
            });
            let validPaths = results.map((item) => item[this.whichpath]);
            results = JSON.parse(JSON.stringify(this.dataAfterFiltered));
            results = results.filter((item) => {
                let bool = false;
                validPaths.forEach((path) => {
                    if (this.isChildOfParent(path, item[this.whichpath]) || path == item[this.whichpath]) {
                        bool = true;
                        return;
                    }
                });
                return bool;
            });
            return results;
        },

        dataAfterFiltered() {
            let results = JSON.parse(JSON.stringify(this.dataAfterSort));
            Object.keys(this.filterValues).forEach((key) => {
                let field = this.fields.find((field) => field.key == key);
                if (field?.filter?.filterFunc) {
                    results = field.filter.filterFunc(results, this.filterValues[key]);
                } else {
                    if (this.filterValues[key]) {
                        results = results.filter((item) => {
                            return (this.formatValue(item, key) ?? "")
                                .toString()
                                .toLowerCase()
                                .includes(this.filterValues[key].toString().toLowerCase());
                        });
                    }
                }
            });
            let validPaths = results.map((item) => item[this.whichpath]);
            results = JSON.parse(JSON.stringify(this.dataAfterSort));
            results = results.filter((item) => {
                let bool = false;
                validPaths.forEach((path) => {
                    if (this.isChildOfParent(path, item[this.whichpath]) || path == item[this.whichpath]) {
                        bool = true;
                        return;
                    }
                });
                return bool;
            });
            return results;
        },

        dataAfterSort() {
            let desc_or_asc = this.isDesc ? -1 : 1;
            let result = [];
            let j = 0;
            this.activeLevels.forEach((level, index) => {
                let pos_or_neg = index == 0 ? desc_or_asc : -desc_or_asc;
                let data = this.dataAfterGroup.filter((datum) => datum.level == level);
                data.sort((a, b) => {
                    let x = a[this.field_sort];
                    let y = b[this.field_sort];
                    if (this.fieldsMap[this.field_sort].displayAbsolute) {
                        x = Math.abs(x);
                        y = Math.abs(y);
                    }
                    return pos_or_neg * (x < y ? -1 : x > y ? 1 : 0);
                });

                // Check if table is filtered by region, if not we have to merge client with same email (diffrent region)
                if (
                    !isFilteredByRegion(this.activeLevels, this.groupLevels.length + 1) &&
                    level === this.groupLevels.length + 1
                ) {
                    let noDuplicatedData = {};
                    data.forEach((datum) => {
                        if (noDuplicatedData[datum.name]) {
                            noDuplicatedData[datum.name] = mergeTwoClients(
                                noDuplicatedData[datum.name],
                                datum,
                                this.fields
                            );
                        } else noDuplicatedData[datum.name] = datum;
                    });
                    data = Object.values(noDuplicatedData);
                }

                if (index == 0) {
                    result = result.concat(data);
                    j = level;
                } else {
                    // Find correct index and insert the value
                    data.forEach((current) => {
                        result.forEach((datum, index) => {
                            if (
                                datum.level == j &&
                                this.isChildOfParent(current[this.whichpath], datum[this.whichpath])
                            ) {
                                result.splice(index + 1, 0, current);
                            }
                        });
                    });
                    j = level;
                }
            });
            return result;
        },

        dataAfterGroup() {
            let results = [];
            this.levels.forEach((level, index) => {
                if (this.activeLevels.includes(index + 1)) {
                    let distinctValues = [
                        ...new Set(
                            this.items.map((item) => {
                                let path = item.path.split("/").slice(0, index + 1);
                                if (this.whichpath == "subpath") {
                                    path = path.slice(Math.min(...this.activeLevels) - 1);
                                }
                                return path.join("/");
                            })
                        ),
                    ];

                    results.push(
                        ...distinctValues.map((path) => {
                            let name = path.split("/");
                            name = this.group_organization[path]?.name ?? name[name.length - 1];

                            let data = this.items.filter(
                                (item) =>
                                    this.isChildOfParent(item[this.whichpath], path) || item[this.whichpath] == path
                            );
                            let tmp = this.calculateSum(this.numberLabels, data);

                            if (index == this.levels.length - 1) {
                                tmp = {
                                    ...data[0],
                                    ...tmp,
                                };
                            }

                            return {
                                name: name,
                                ...tmp,
                                level: index + 1,
                                isUncollapsible: index + 1 === Math.max(...this.activeLevels),
                                [this.whichpath]: path,
                            };
                        })
                    );
                }
            });
            return results;
        },

        items() {
            let result = this.market_exposure
                .filter((item) => item.value.NetPositionLots && item.value.NetExposure)
                .map((item) => {
                    let values = JSON.parse(JSON.stringify(item.value));
                    let tmp = this.labels.reduce((obj, key) => {
                        obj[key] = values?.[key] ?? NaN;
                        return obj;
                    }, {});
                    let path = this.levels.map((level) =>
                        level.value({ ...item, path: item.properties.region.split("/") })
                    );
                    let subpath = path.slice(Math.min(...this.activeLevels) - 1);

                    return {
                        ...tmp,
                        name: item.properties.server + "-" + item.properties.login,
                        path: path.join("/"),
                        subpath: subpath.join("/"),
                        level: this.levels.length,
                        symbol: item.properties.symbol,
                    };
                });
            return result;
        },

        uniqueCurrentSymbols() {
            let symbols = this.dataAfterFormat
                .filter((item) => item.level == this.group_level + 1)
                .map((item) => item.name);
            return [...new Set(symbols)];
        },
    },

    watch: {
        filterValues(newV) {},
    },

    methods: {
        formatMoney,
        exportFile,
        moment,
        riskStyle,
        symbolOfCurrency,

        isChildOfParent(child, parent) {
            let child_path = child.split("/");
            let parent_path = parent.split("/");
            return (
                child_path.length > parent_path.length && child_path.slice(0, parent_path.length).join("/") == parent
            );
        },

        sortBy(field) {
            if (field) {
                this.field_sort = field;
                this.isDesc = !this.isDesc;
            }
        },

        formatValue(item, field) {
            switch (field) {
                // case 'NetPositionLots':
                //   return item.NetPositionLots ? formatMoney(item.NetPositionLots, 2) : ''
                // case 'NetExposure':
                //   return item.NetExposure ? `${symbolOfCurrency(this.companyCurrency)}${formatMoney(item.NetExposure, 0)}` : ''
                case "LongLots":
                case "ShortLots":
                case "CompanyFloatingPL":
                    return formatMoney(item[field]);
                case "ClosedPLToday":
                case "ClosedPLPerLot":
                    return `${symbolOfCurrency(this.companyCurrency)}${formatMoney(item[field])}`;
                default:
                    return item[field];
            }
        },

        isItemUncollapsible(item) {
            return item.level == Math.max(...this.activeLevels);
        },

        collapse(item) {
            item.isCollapsed = true;
            this.collapsedSet.push(item[this.whichpath]);
        },

        uncollapse(item) {
            item.isCollapsed = false;
            this.collapsedSet = this.collapsedSet.filter((path) => path != item[this.whichpath]);
        },

        calcNamePadding(level) {
            return 25 * this.activeLevels.indexOf(level);
        },

        toggleLevel(level) {
            this.collapsedSet = this.collapsedSet.filter((path) => path.split("/").length >= level);
        },

        initFilter() {
            if (getFilterInLocalStorage(TABLE_NAME)) {
                let filterValues = getFilterInLocalStorage(TABLE_NAME);

                this.isFilterShown = true;
                this.$refs.table.initFilter(filterValues);
            }
            if (getFilterInLocalStorage(TABLE_NAME + "Zero")) {
                let filterValues = getFilterInLocalStorage(TABLE_NAME + "Zero");
                this.isFilterZeroShown = filterValues.show ? true : false;
                this.$refs.table.initFilterZero(filterValues);
            }
        },

        toggleFilter() {
            this.isFilterShown = !this.isFilterShown;
            if (this.isFilterShown) {
                setFilterInLocalStorage(TABLE_NAME, {});
            } else {
                removeFilterInLocalStorage(TABLE_NAME);
            }
        },
        toggleFilterZero() {
            this.isFilterZeroShown = !this.isFilterZeroShown;
            if (this.isFilterZeroShown) {
                let filterOutZeroValues = getFilterInLocalStorage(TABLE_NAME + "Zero") || {};

                setFilterInLocalStorage(TABLE_NAME + "Zero", {
                    ...filterOutZeroValues,
                    show: true,
                });
            } else {
                let filterOutZeroValues = getFilterInLocalStorage(TABLE_NAME + "Zero") || {};

                setFilterInLocalStorage(TABLE_NAME + "Zero", {
                    ...filterOutZeroValues,
                    show: false,
                });
            }
        },

        clearFilter() {
            this.$refs.table.clearFilter();
        },

        filterChange(filterValues) {
            Object.keys(filterValues).forEach((key) => {
                this.$set(this.filterValues, key, filterValues[key]);
            });
            if (this.isFilterShown) setFilterInLocalStorage(TABLE_NAME, filterValues);
        },

        handleFilterOutZeroValues(filterOutZeroValues) {
            Object.keys(filterOutZeroValues).forEach((key) => {
                if (key != "show") this.$set(this.filterOutZeroValues, key, filterOutZeroValues[key]);
            });
            if (this.isFilterZeroShown)
                setFilterInLocalStorage(TABLE_NAME + "Zero", {
                    ...filterOutZeroValues,
                    show: this.isFilterZeroShown,
                });
        },

        filterClear(filterValues) {
            Object.keys(filterValues).forEach((key) => {
                this.$set(this.filterValues, key, filterValues[key]);
            });
            if (this.isFilterShown) setFilterInLocalStorage(TABLE_NAME, filterValues);
        },

        calculateRisk(symbol, value) {
            // recalculate Risk
            let medium_risk = this.risk_threshold.custom[symbol]?.medium ?? this.risk_threshold.default.medium;
            let high_risk = this.risk_threshold.custom[symbol]?.high ?? this.risk_threshold.default.high;
            if (Math.abs(value) > high_risk) return 3;
            if (Math.abs(value) > medium_risk) return 2;
            return 1;
        },

        calculateSum(numberLabels, data) {
            let res = this.numberLabels.reduce((obj, key) => {
                if (!data[0][key].length) {
                    obj[key] = data.reduce((sum, item) => sum + (isNaN(item[key]) ? 0 : item[key] ?? 0), 0);
                } else {
                    obj[key] = data.reduce(
                        (sum, item) => sum.map((i, j) => i + item[key][j]),
                        data[0][key].map((i) => 0)
                    );
                }
                return obj;
            }, {});
            return res;
        },

        sortBy(field) {
            this.field_sort = field;
            this.isDesc = !this.isDesc;
        },

        fetch_market_exposure() {},

        getAllChildren(node, path) {
            let result = { [path]: node.settings };
            if (node.children) {
                Object.keys(node.children).forEach((key) => {
                    result = { ...result, ...this.getAllChildren(node.children[key], path + "/" + key) };
                });
            }
            return result;
        },
    },

    async mounted() {
        this.isLoading = true;
        try {
            this.initFilter();

            if (Object.keys(this.risk_threshold).length == 0) this.$store.dispatch("tableData/fetchRiskThreshold");

            if (this.groupLevels.length == 0) await this.$store.dispatch("tableData/fetchGroupLevels");

            this.activeLevels = [this.group_level + 1, this.levels.length];

            if (this.market_exposure.length == 0) await this.$store.dispatch("tableData/fetchMarketExposure");

            this.collapsedSet = this.dataAfterGroup
                .filter((item) => item.level == this.group_level + 1)
                .map((item) => item[this.whichpath]);
            this.isLoading = false;
        } catch (err) {
            this.isLoading = false;
        }

        this.$store.commit("app/SET_DATA_LIVE_TIME", moment().format(" HH:mm:ss - DD/MM/YYYY"));

        axios
            // .get(`${process.env.VUE_APP_API_URL}site_settings/risk_management/group_organization?recursive`)
            //? mockData
            .get("/GroupOrganization/data")
            .then((res) => {
                this.group_organization = {};
                if (res.data?.children) {
                    Object.keys(res.data.children).forEach((key) => {
                        this.group_organization = {
                            ...this.group_organization,
                            ...this.getAllChildren(res.data.children[key], key),
                        };
                    });
                }
            })
            .catch((err) => console.error(err));
    },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/variables/_variables-components.scss";
.filter ::v-deep th {
    max-height: 60px !important;
    height: 60px !important;
    border-top: solid 1px #3d3d3d;
    border-bottom: solid 1px #3d3d3d;
}
.market-exposure-table ::v-deep td {
    max-height: 30px !important;
    height: 30px !important;
}
.level-pill {
    min-width: 77px;
    font-size: 12px !important;
    &:not(.button-active) {
        color: $theme-dark-headings-color !important;
    }
}
</style>
