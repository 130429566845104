<template>
    <b-card header-class="border-bottom py-75" body-class="pt-2 pb-0" class="company-chart">
        <template #header>
            <div class="d-flex justify-content-between w-100 align-items-center">
                <div>
                    <span class="mb-0 font-small-4 font-weight-bolder text-heading-color">
                        Profit/Loss
                    </span>
                </div>
                <div>
                    <b-dropdown :text="timeframe" variant="flat-dark" right toggle-class="py-50 text-heading-color">
                        <b-dropdown-item
                            v-for="tf in timeframes"
                            :key="tf.value"
                            @click="setDayStartEnd(tf.value), (timeframe = tf.label)"
                            class="font-small-3 text-heading-color"
                        >
                            {{ tf.label }}
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
        </template>

        <div class="company-chart__today-note" v-if="timeframe === 'Today'">
            Note: Today values are accumulated over hours
        </div>

        <div v-if="fetchingState === 'FETCHED'">
            <div class="d-flex w-100 justify-content-center" style="height: 20px">
                <div class="legend-pl">
                    P/L
                </div>
            </div>
            <!-- <VueApexCharts height="380" type="bar" :options="chartOptions" :series="chartSeries"></VueApexCharts> -->

            <!-- mockData -->
            <VueApexCharts type="line" height="380" :options="chartOptions_mock" :series="series_mock"></VueApexCharts>
        </div>
        <div v-else>
            <div class="w-100 d-flex justify-content-center align-items-center" style="height: 415px">
                <b-spinner variant="primary"></b-spinner>
            </div>
        </div>
    </b-card>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import apexChartData from "@/views/charts-and-maps/charts/apex-chart/apexChartData";
import chartMixins from "./chartMixins";
import DigitalClock from "@/components/DigitalClock.vue";
export default {
    components: {
        VueApexCharts,
        DigitalClock,
    },
    mixins: [chartMixins],
    data() {
        return {
            fetchingState: "FETCHING",
            timeframe: "This Month",
            interval: null,

            //? mockData
            series_mock: [
                {
                    name: "Fake Title 1",
                    type: "column",
                    data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160],
                    color: "#28c76f",
                },
            ],
            chartOptions_mock: {
                chart: {
                    height: 350,
                    type: "line",
                },
                stroke: {
                    width: [0, 4],
                },

                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1],
                },
                labels: [
                    "01 Jan 2001",
                    "02 Jan 2001",
                    "03 Jan 2001",
                    "04 Jan 2001",
                    "05 Jan 2001",
                    "06 Jan 2001",
                    "07 Jan 2001",
                    "08 Jan 2001",
                    "09 Jan 2001",
                    "10 Jan 2001",
                    "11 Jan 2001",
                    "12 Jan 2001",
                ],
                xaxis: {
                    type: "datetime",
                },
            },
        };
    },
    computed: {
        chartOptions() {
            return {
                ...apexChartData.profitLossChart.chartOptions,
            };
        },
    },
    methods: {
        async fetchAll() {
            try {
                this.fetchingState = "FETCHING";
                this.setDayStartEnd("ThisMonth");
                await Promise.all([
                    this.fetchYesterdayHistoryUsingMetricData(0, "ActualPL"),

                    this.fetchTodayHistory(0, "CompanyTotalValues", "company", "ActualPL"),

                    this.fetchCurrentValues("CompanyTotalValues", "company", ["ActualPL"]),
                ]);
                this.fetchingState = "FETCHED";
            } catch (error) {
                this.fetchingState = "FAILED";
                console.error(error);
            }
        },
    },
    mounted() {
        this.series = [
            {
                name: "P/L",
                chartType: "bar",
                legend: {
                    show: true,
                    position: "top",
                    markers: {
                        width: 30,
                        height: 3,
                        strokeWidth: 10,
                        strokeColor: "#ccc",
                        fillColors: "#ccc",
                        radius: 12,
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
            },
        ];
        this.fetchAll();
        this.interval = setInterval(() => {
            this.fetchCurrentValues("CompanyTotalValues", "company", ["ActualPL"]);
        }, 10 * 1000);
    },
    destroyed() {
        clearInterval(this.interval);
    },
};
</script>
<style scoped lang="scss">
.legend-pl::before {
    content: " ";
    display: inline-block;
    height: 10px;
    width: 30px;
    background: var(--primary);
}
.legend-pl {
    color: var(--secondary);
    font-size: 0.8rem;
}
</style>
