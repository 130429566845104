<template>
    <b-card no-body class="statistic-card">
        <b-card-body class="pb-0 d-flex justify-content-between">
            <b-avatar class="mb-1" :variant="`light-${color}`" size="45">
                <font-awesome-icon :icon="icon" class="font-medium-3" />
            </b-avatar>
            <div class="truncate text-right">
                <p class="title">{{ statisticTitle }}</p>
                <h2 class="mb-25 font-weight-bolder statistic">
                    {{ statistic }}
                </h2>
            </div>
        </b-card-body>
        <div class="chart-wrapper py-1">
            <vue-apex-charts type="area" height="100" :options="chartOptionsComputed" :series="chartSeries" />
        </div>
    </b-card>
</template>

<script>
import { BCard, BCardBody, BAvatar } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import { areaChartOptions } from "./chartOptions";
import { formatMoney } from "@/helper";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
    components: {
        VueApexCharts,
        BCard,
        BCardBody,
        BAvatar,
    },
    props: {
        icon: {
            type: Array,
            required: true,
        },
        statistic: {
            type: [Number, String],
            required: true,
        },
        statisticTitle: {
            type: String,
            default: "",
        },
        color: {
            type: String,
            default: "primary",
        },
        chartData: {
            type: Array,
            default: () => [],
        },
        chartOptions: {
            type: Object,
            default: null,
        },
    },
    computed: {
        ...mapGetters({
            timezoneOffset: "companySettings/timezoneOffset",
        }),
        chartOptionsComputed() {
            if (this.chartOptions === null) {
                const options = JSON.parse(JSON.stringify(areaChartOptions));
                options.theme.monochrome.color = $themeColors[this.color];
                options.tooltip.y = {
                    formatter(value, { series, seriesIndex, dataPointIndex, w }) {
                        return formatMoney(value);
                    },
                    title: {
                        formatter: (seriesName) => "",
                    },
                };
                options.chart.animations = {
                    enabled: true,
                    easing: "easeinout",
                    dynamicAnimation: {
                        enabled: false,
                    },
                    animateGradually: {
                        enabled: false,
                    },
                };

                return options;
            }
            return this.chartOptions;
        },
        chartSeries() {
            return this.chartData;
        },
    },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/variables/variables-components.scss";
.statistic-card {
    .statistic {
        color: $theme-dark-body-color;
    }
    .title {
        color: $theme-dark-headings-color;
    }
}
.chart-wrapper {
    width: 100%;
    // ::v-deep .apexcharts-tooltip.apexcharts-active {
    //   top: -20px !important;
    // }
}
</style>
