import axios from "@axios";
import moment from "moment";
import { timeDiff } from "@/helper";
import { endOfDays } from "@/utils/endOfDays";
import { mapGetters } from "vuex";

export default {
    props: {
        dateStart: String,
        dateEnd: String,
    },
    data() {
        return {
            liveUpdateInterval: null,
            yesterdayHistory: [],
            todayHistory: [],
            currentValue: 0,
            dayStart: 0, // dayStart >= dayEnd >= 0, dayStart = i means (today - i days)
            dayEnd: 0,
        };
    },
    methods: {
        setDayStartEnd(timeframe) {
            switch (timeframe) {
                case "Today":
                    this.dayStart = 0;
                    this.dayEnd = 0;
                    break;
                case "ThisMonth":
                    this.dayStart = new Date().getDate() - 1;
                    this.dayEnd = 0;
                    break;
                case "ThisYear":
                    let now = new Date();
                    let start = new Date(now.getFullYear(), 0, 0);
                    let diff = timeDiff(now, start, "D");
                    this.dayStart = Math.min(this.yesterdayHistory.length, diff) - 1;
                    this.dayEnd = 0;
                    break;
                case "Overall":
                    this.dayStart = this.yesterdayHistory.length - 1;
                    this.dayEnd = 0;
                    break;
                case "CustomTimeframe":
                    if (this.dateStart && this.dateEnd) {
                        this.dayStart = timeDiff(this.dateStart, new Date().toDateString(), "D") + 1;
                        this.dayEnd = timeDiff(this.dateEnd, new Date().toDateString(), "D") + 1;
                    }
                    break;
            }
            // console.log(this.dayStart, this.dayEnd)
        },
        async fetchYesterdayHistoryUsingMetricData(name, metric_value) {
            try {
                // let res = await axios.get(
                //     `${process.env.VUE_APP_API_URL}/metric/HistoryCurrentUserStats/data?filter=${name}_`
                // );
                //? mockData
                let res = await axios.get("/AccountAnalysis/data");
                this.yesterdayHistory = res.data
                    .sort((a, b) => (a.properties.date < b.properties.date) - (b.properties.date < a.properties.date))
                    .map((datum) => {
                        return {
                            ...datum,
                            timestamps: datum.properties.date,
                            value: datum.value[metric_value],
                        };
                    });
            } catch (error) {
                console.error(error);
            }
        },
        async fetchYesterdayHistoryUsingMetricHistory(metric_def, metric_datum, metric_value) {
            try {
                let filter = {
                    log_rule: "daily",
                };
                let res = await axios.get(
                    `${
                        process.env.VUE_APP_API_URL
                    }/metric/${metric_def}/data/${metric_datum}/history/${metric_value}?filter=${JSON.stringify(
                        filter
                    )}`
                );
                this.yesterdayHistory = res.data.sort(
                    (a, b) => (a.timestamps < b.timestamps) - (b.timestamps < a.timestamps)
                );
            } catch (err) {
                console.error(err);
            }
        },
        async fetchTodayHistory(metric_def, metric_datum, metric_value) {
            try {
                let filter = {
                    log_rule: "hourly",
                };
                let res = await axios
                    // .get(`${process.env.VUE_APP_API_URL}/metric/${metric_def}/data/${metric_datum}/history/${metric_value}?filter=${JSON.stringify(filter)}`)
                    //? mockData
                    .get("/CurrentUserStatistics/data");
                this.todayHistory = res.data.sort(
                    (a, b) => (a.timestamps < b.timestamps) - (b.timestamps < a.timestamps)
                );
                this.todayHistory = this.todayHistory.filter((datapoint) => {
                    let datapointDate = moment
                        .utc(datapoint.timestamps)
                        .utcOffset(this.timezoneOffset)
                        .format("YYYY-MM-DD");
                    let todayDate = moment()
                        .utcOffset(this.timezoneOffset)
                        .format("YYYY-MM-DD");
                    return datapointDate == todayDate;
                });
                // console.log(this.todayHistory)
            } catch (err) {
                console.error(err);
            }
        },
        async fetchCurrentValue(metric_def, metric_datum, metric_value) {
            try {
                // let res = await axios.get(`${process.env.VUE_APP_API_URL}/metric/${metric_def}/data/${metric_datum}`);
                let res = await axios.get("/AccountAnalysis/data");
                // this.currentValue = res.data.value[metric_value];

                //? mockData
                this.currentValue = res.data[0].value[metric_value];

                // console.log(this.currentValue)
            } catch (err) {
                console.error(err);
            }
        },
    },
    computed: {
        ...mapGetters({
            timezoneOffset: "companySettings/timezoneOffset",
        }),
        chartSeriesBeforeToday() {
            let history = this.yesterdayHistory.filter((x) => x.value != null);
            if (history.length == 0) return new Array(this.dayStart - this.dayEnd);
            let historyIndex = 0;
            let data = [];
            let dayEnd = this.dayEnd + (this.dayEnd === 0 ? 1 : 0);
            let eod = endOfDays(this.dayStart - this.dayEnd + 1, this.timezoneOffset);

            for (let i = dayEnd; i <= this.dayStart; ++i) {
                let dayI = eod[i];
                // find a datapoint in history that match the today (-i days)
                while (historyIndex < history.length && history[historyIndex].timestamps > dayI) {
                    historyIndex++;
                }
                if (historyIndex < history.length && timeDiff(dayI, history[historyIndex].timestamps, "H") <= 24) {
                    data.push({
                        x: moment
                            .parseZone(dayI)
                            .utcOffset(this.timezoneOffset)
                            .format("DD/MM"),
                        y: history[historyIndex].value,
                    });
                } else
                    data.push({
                        x: moment
                            .parseZone(dayI)
                            .utcOffset(this.timezoneOffset)
                            .format("DD/MM"),
                        y: 0,
                    });
            }
            return data.reverse();
        },
        chartSeriesToday() {
            let data = [];
            let currentMoment = moment.utc().utcOffset(this.timezoneOffset);
            data.push({
                x: currentMoment.format("HH:mm"),
                y: this.currentValue,
            });
            // console.log(this.currentValue)

            let index = 0;
            while (currentMoment.format("H") != 0) {
                data.push({
                    x: currentMoment.format("HH") + ":00",
                    y: this.todayHistory[index]?.value ?? 0,
                });
                index++;
                currentMoment.subtract(1, "hours");
            }
            data.reverse();
            return data;
        },
        chartSeries() {
            let data = this.chartSeriesBeforeToday;
            if (this.dayEnd === 0) {
                if (this.dayStart === 0) data = this.chartSeriesToday;
                else {
                    data = [
                        ...data,
                        {
                            x: moment().format("DD/MM"),
                            y: this.currentValue,
                        },
                    ];
                }
            }
            return [
                {
                    name: "",
                    data,
                },
            ];
        },
    },
    watch: {
        time_frame(value) {
            this.setDayStartEnd(value);
        },
        dateStart() {
            if (this.dateStart && this.dateEnd) this.setDayStartEnd(this.time_frame);
        },
        dateEnd() {
            if (this.dateStart && this.dateEnd) this.setDayStartEnd(this.time_frame);
        },
    },
    mounted() {},
    destroyed() {
        if (this.liveUpdateInterval) clearInterval(this.liveUpdateInterval);
    },
};
