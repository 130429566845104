<template>
    <div class="row m-0 justify-content-between account-details" v-if="current_item">
        <div class="col-6 p-0 d-flex" v-if="show1stComponent" :class="show2ndComponent ? 'col-6' : 'col-12'">
            <div class="mr-75 w-50 account-analysis">
                <h4 class="mb-1 table-title">Account Analysis</h4>
                <b-card
                    no-body
                    class="rounded my-shadow p-50"
                    :class="{
                        'is-custom-timeframe': time_frame === 'CustomTimeframe',
                    }"
                >
                    <b-table-simple
                        no-border-collapse
                        small
                        hover
                        bordered
                        responsive
                        striped
                        class="mb-0 table-no-header"
                        style="min-height: 510px "
                    >
                        <b-tbody v-if="isLoading || isQueryingTimeframe">
                            <div
                                colspan="6"
                                class="d-flex align-items-center justify-content-center"
                                style="height: 510px"
                            >
                                <b-spinner variant="primary" />
                            </div>
                        </b-tbody>
                        <b-tbody v-else>
                            <b-tr>
                                <b-td class="text-left"><dot /> Login</b-td>
                                <b-td class="cursor-pointer text-right px-2">
                                    {{ current_item.properties.login }}
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td><dot /> Email</b-td>
                                <b-td class="text-right px-2">{{
                                    current_item.properties.mt_account && current_item.properties.mt_account.email
                                }}</b-td>
                            </b-tr>
                            <b-tr
                                ><b-td><dot /> Name</b-td>
                                <b-td class="text-right px-2">{{
                                    current_item.properties.mt_account && current_item.properties.mt_account.name
                                }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> Currency</b-td>
                                <b-td class="text-right px-2">{{ current_item.properties.currency }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> Group</b-td>
                                <b-td class="text-right px-2">{{ current_item.properties.group }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> Closed Profit</b-td>
                                <b-td class="text-right px-2">{{
                                    formatMoney(current_item.value["ClosedPL" + time_frame], 2)
                                }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> Closed Win</b-td>
                                <b-td class="text-right px-2">{{
                                    formatMoney(current_item.value["ClosedWin" + time_frame], 2)
                                }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> Closed Loss</b-td>
                                <b-td class="text-right px-2">{{
                                    formatMoney(current_item.value["ClosedLoss" + time_frame], 2)
                                }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> SO Compensation</b-td>
                                <b-td class="text-right px-2">{{
                                    formatMoney(current_item.value["SOCompensation" + time_frame], 2)
                                }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> Total Closed Profit</b-td>
                                <b-td class="text-right px-2">{{
                                    formatMoney(current_item.value["ActualClosedPL" + time_frame], 2)
                                }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> Deposits</b-td>
                                <b-td class="text-right px-2">{{
                                    formatMoney(current_item.value["Deposits" + time_frame], 2)
                                }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> Withdrawals</b-td>
                                <b-td class="text-right px-2">{{
                                    formatMoney(current_item.value["Withdrawals" + time_frame], 2)
                                }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> Net Deposit</b-td>
                                <b-td class="text-right px-2">{{
                                    formatMoney(current_item.value["NetDeposits" + time_frame], 2)
                                }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> Company</b-td>
                                <b-td class="px-2">{{
                                    current_item.properties.path ? current_item.properties.path[0] : ""
                                }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> Server</b-td>
                                <b-td class="px-2">{{ current_item.properties.server }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> Region</b-td>
                                <b-td class="px-2">{{
                                    current_item.properties.path ? current_item.properties.path[1] : ""
                                }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> Team</b-td>
                                <b-td class="px-2">{{
                                    current_item.properties.path ? current_item.properties.path[2] : ""
                                }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> Registration Date</b-td
                                ><b-td class="px-2">{{
                                    moment(
                                        current_item.properties.mt_account &&
                                            current_item.properties.mt_account.register_time
                                    ).format("DD/MM/YYYY")
                                }}</b-td></b-tr
                            >
                        </b-tbody>
                    </b-table-simple>

                    <div class="ml-50 py-1 mr-2">
                        <div class="d-flex">
                            <div class="my-auto w-50 mr-1 text-heading-color">Timeframe:</div>
                            <v-select
                                class="select-size-sm width-160px"
                                label="Time Frame"
                                :clearable="false"
                                :value="time_frame"
                                @input="$emit('set_time_frame', $event)"
                                :options="['Today', 'This Month', 'This Year', 'Overall', 'Custom Timeframe']"
                                :reduce="(time) => time.replace(/\s/g, '')"
                            />
                        </div>
                        <template v-if="time_frame == 'CustomTimeframe'">
                            <div class="d-flex mt-2">
                                <div class="my-auto w-50 mr-1">Start Date:</div>
                                <flat-pickr
                                    v-model="startDate"
                                    class="form-control"
                                    :config="{ maxDate: new Date(today) }"
                                />
                            </div>
                            <div class="d-flex mt-2">
                                <div class="my-auto w-50 mr-1">End Date:</div>
                                <flat-pickr
                                    v-model="endDate"
                                    class="form-control"
                                    :config="{ maxDate: new Date(today) }"
                                />
                            </div>
                            <div class="text-center mt-1">
                                <b-button
                                    class="px-2"
                                    size="sm"
                                    pill
                                    variant="success"
                                    @click="applyCustomTimeframe()"
                                    :disabled="!startDate || !endDate || endDate < startDate"
                                >
                                    Apply
                                </b-button>
                            </div>
                        </template>
                    </div>
                </b-card>
            </div>

            <div class="mx-75 w-50 account-profit-summary">
                <h4 class="mb-1 table-title">Account Profit Summary</h4>
                <b-card
                    no-body
                    class="p-50 rounded my-shadow d-flex"
                    :class="{
                        'is-custom-timeframe': time_frame === 'CustomTimeframe',
                    }"
                >
                    <b-table-simple
                        no-border-collapse
                        small
                        hover
                        bordered
                        responsive
                        striped
                        class="mb-0 table-no-header"
                    >
                        <b-thead> </b-thead>
                        <b-tbody v-if="isLoading || isQueryingTimeframe">
                            <div
                                colspan="6"
                                class="d-flex align-items-center justify-content-center"
                                style="height: 480px"
                            >
                                <b-spinner variant="primary" />
                            </div>
                        </b-tbody>
                        <b-tbody v-else>
                            <b-tr>
                                <b-td class="text-left"><dot /> Login</b-td>
                                <b-td class="px-2 text-right">{{ current_item.properties.login }}</b-td>
                            </b-tr>
                            <b-tr
                                ><b-td><dot /> Closed P/L</b-td>
                                <b-td class="px-2 text-right">{{
                                    formatMoney(current_item.value["ClosedPL" + time_frame], 2)
                                }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> Closed Win</b-td>
                                <b-td class="px-2 text-right">{{
                                    formatMoney(current_item.value["ClosedWin" + time_frame], 2)
                                }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> Closed Loss</b-td>
                                <b-td class="px-2 text-right">{{
                                    formatMoney(current_item.value["ClosedLoss" + time_frame], 2)
                                }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> SO Compensation</b-td>
                                <b-td class="text-right px-2">{{
                                    formatMoney(current_item.value["SOCompensation" + time_frame], 2)
                                }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> Total Closed Profit</b-td>
                                <b-td class="text-right px-2">{{
                                    formatMoney(current_item.value["ActualClosedPL" + time_frame], 2)
                                }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> Floating P/L</b-td>
                                <b-td class="px-2 text-right">{{
                                    formatMoney(
                                        current_item.value[
                                            "OpenPL" + (time_frame == "CustomTimeframe" ? time_frame : "")
                                        ],
                                        2
                                    )
                                }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> Floating Win</b-td>
                                <b-td class="px-2 text-right">{{
                                    current_item.value[
                                        "OpenWin" + (time_frame == "CustomTimeframe" ? time_frame : "")
                                    ] != null
                                        ? formatMoney(
                                              current_item.value[
                                                  "OpenWin" + (time_frame == "CustomTimeframe" ? time_frame : "")
                                              ],
                                              2
                                          )
                                        : "N/A"
                                }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> Floating Loss</b-td>
                                <b-td class="px-2 text-right">{{
                                    current_item.value[
                                        "OpenLoss" + (time_frame == "CustomTimeframe" ? time_frame : "")
                                    ] != null
                                        ? formatMoney(
                                              current_item.value[
                                                  "OpenLoss" + (time_frame == "CustomTimeframe" ? time_frame : "")
                                              ],
                                              2
                                          )
                                        : "N/A"
                                }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> Overall P/L</b-td>
                                <b-td class="px-2 text-right">{{
                                    formatMoney(current_item.value["PL" + time_frame], 2)
                                }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> Overall Win</b-td>
                                <b-td class="px-2 text-right">{{
                                    current_item.value["NetWin" + time_frame] != null
                                        ? formatMoney(current_item.value["NetWin" + time_frame], 2)
                                        : "N/A"
                                }}</b-td></b-tr
                            >
                            <b-tr
                                ><b-td><dot /> Overall Loss</b-td>
                                <b-td class="px-2 text-right">{{
                                    current_item.value["NetLoss" + time_frame] != null
                                        ? formatMoney(current_item.value["NetLoss" + time_frame], 2)
                                        : "N/A"
                                }}</b-td></b-tr
                            >
                        </b-tbody>
                    </b-table-simple>
                </b-card>
            </div>
        </div>

        <div class="p-0 account-p-l pl-75" :class="show1stComponent ? 'col-6' : 'col-12'" v-if="show2ndComponent">
            <div>
                <div class="d-flex justify-content-between">
                    <h4 class="mb-1 table-title ">Account P/L</h4>
                    <div class="mr-3">Symbols: {{ items.length }}</div>
                    <div>
                        <span class="text-danger mr-1 cursor-pointer" v-if="isFilterShown" @click="clearFilter"
                            >Clear Search</span
                        >
                        <font-awesome-icon
                            :icon="['far', 'filter']"
                            class="btn-icon mr-1"
                            v-b-tooltip.hover.top="'Filter'"
                            @click="toggleFilter"
                        />
                        <font-awesome-icon
                            :icon="['far', 'compress-arrows-alt']"
                            class="btn-icon mr-1"
                            v-b-tooltip.hover.top="`Filter out 0 values: ${isFilterZeroShown ? 'on' : 'off'}`"
                            @click="toggleFilterZero"
                        />
                        <font-awesome-icon
                            :icon="['far', 'cog']"
                            class="btn-icon mr-1"
                            v-b-tooltip.hover.top="'Table Settings'"
                            v-b-modal.apl
                        />
                        <font-awesome-icon
                            :icon="['far', 'file-export']"
                            class="btn-icon"
                            v-b-tooltip.hover.top="'Export CSV'"
                            @click="$bvModal.show('apl-download-modal')"
                        />
                        <b-modal
                            id="apl-download-modal"
                            title="Choose format type"
                            centered
                            @ok="exportFile(exportData, exportFormat, 'AccountDetails')"
                        >
                            <v-select
                                v-model="exportFormat"
                                :clearable="false"
                                label="format"
                                :options="['csv', 'xls']"
                            />
                        </b-modal>
                    </div>
                </div>
                <b-card
                    no-body
                    class="px-50 rounded my-shadow overflow-hidden"
                    :class="{
                        'is-custom-timeframe': time_frame === 'CustomTimeframe',
                    }"
                >
                    <!-- <CommonTable
                        :isLoading="isLoading || isQueryingTimeframe"
                        :fields="fields"
                        :items="dataAfterFilterOutZeroValues"
                        :max-display-rows="time_frame === 'CustomTimeframe' ? 19 : 15"
                        :isFilterShown="isFilterShown"
                        :isFilterZeroShown="isFilterZeroShown"
                        :is-header-small="true"
                        @filterChange="filterChange"
                        @filterClear="filterClear"
                        :canFilterOutZeroValues="canFilterOutZeroValues"
                        @filterOutZeroValues="handleFilterOutZeroValues"
                        :field_sort="field_sort"
                        :isDesc="isDesc"
                        @onHeaderClick="(field) => sortBy(field)"
                        ref="table"
                    >
                        <template #summary-row>
                            <b-tr class="text-right">
                                <b-td class="text-center bg-black">Total</b-td>
                                <b-td v-if="fields.map((i) => i.label).includes('Open P/L')" class="bg-black ">
                                    {{ formatMoney(dataTotal.CompanyFloatingPL, 2) }}
                                </b-td>
                                <b-td v-if="fields.map((i) => i.label).includes('Closed P/L')" class="bg-black ">
                                    {{ formatMoney(dataTotal.ClosedPL, 2) }}
                                </b-td>
                                <b-td v-if="fields.map((i) => i.label).includes('Net P/L')" class="bg-black ">
                                    {{ formatMoney(parseFloat(dataTotal.CompanyFloatingPL + dataTotal.ClosedPL), 2) }}
                                </b-td>
                                <b-td v-if="fields.map((i) => i.label).includes('Volume Traded')" class="bg-black ">
                                    {{ formatMoney(dataTotal.VolTraded, 2) }}
                                </b-td>
                                <b-td v-if="fields.map((i) => i.label).includes('Lots Traded')" class="bg-black ">
                                    {{ formatMoney(dataTotal.LotsTraded, 2) }}
                                </b-td>
                            </b-tr>
                        </template>
                    </CommonTable> -->

                    <!-- mockData -->
                    <CommonTable
                        :items="items_mock"
                        :isLoading="isLoading || isQueryingTimeframe"
                        :fields="fields"
                        :max-display-rows="time_frame === 'CustomTimeframe' ? 19 : 15"
                        :isFilterShown="isFilterShown"
                        :isFilterZeroShown="isFilterZeroShown"
                        :is-header-small="true"
                        @filterChange="filterChange"
                        @filterClear="filterClear"
                        :canFilterOutZeroValues="canFilterOutZeroValues"
                        @filterOutZeroValues="handleFilterOutZeroValues"
                        :field_sort="field_sort"
                        :isDesc="isDesc"
                        @onHeaderClick="(field) => sortBy(field)"
                        ref="table"
                    >
                        <template #summary-row>
                            <b-tr class="text-right">
                                <b-td class="text-center bg-black">Total</b-td>
                                <b-td v-if="fields.map((i) => i.label).includes('Open P/L')" class="bg-black ">
                                    {{ formatMoney(50, 2) }}
                                </b-td>
                                <b-td v-if="fields.map((i) => i.label).includes('Closed P/L')" class="bg-black ">
                                    {{ formatMoney(10, 2) }}
                                </b-td>
                                <b-td v-if="fields.map((i) => i.label).includes('Net P/L')" class="bg-black ">
                                    {{ formatMoney(150, 2) }}
                                </b-td>
                                <b-td v-if="fields.map((i) => i.label).includes('Volume Traded')" class="bg-black ">
                                    {{ formatMoney(200, 2) }}
                                </b-td>
                                <b-td v-if="fields.map((i) => i.label).includes('Lots Traded')" class="bg-black ">
                                    {{ formatMoney(250, 2) }}
                                </b-td>
                            </b-tr>
                        </template>
                    </CommonTable>

                    <div v-if="show2ndTimeframe" class="ml-50 py-1 mr-2 custom-position">
                        <div class="d-flex">
                            <div class="my-auto w-50 mr-1">Timeframe:</div>
                            <v-select
                                class="select-size-sm width-160px"
                                label="Time Frame"
                                :clearable="false"
                                :value="time_frame"
                                @input="$emit('set_time_frame', $event)"
                                :options="['Today', 'This Month', 'This Year', 'Overall', 'Custom Timeframe']"
                                :reduce="(time) => time.replace(/\s/g, '')"
                            />
                        </div>
                        <template v-if="time_frame == 'CustomTimeframe'">
                            <div class="d-flex mt-2">
                                <div class="my-auto w-50 mr-1">Start Date:</div>
                                <flat-pickr
                                    v-model="startDate"
                                    class="form-control"
                                    :config="{ maxDate: new Date(today) }"
                                />
                            </div>
                            <div class="d-flex mt-2">
                                <div class="my-auto w-50 mr-1">End Date:</div>
                                <flat-pickr
                                    v-model="endDate"
                                    class="form-control"
                                    :config="{ maxDate: new Date(today) }"
                                />
                            </div>
                            <div class="text-center mt-1">
                                <b-button
                                    class=""
                                    pill
                                    variant="success"
                                    @click="applyCustomTimeframe()"
                                    :disabled="!startDate || !endDate || endDate < startDate"
                                >
                                    Apply
                                </b-button>
                            </div>
                        </template>
                    </div>
                </b-card>
            </div>
        </div>
        <TableSettingsModal
            :fields="accountPlFields.filter((i, index) => index > 0)"
            table="Account P/L"
            @reactivity="key += 1"
            id="apl"
        />
    </div>
</template>
<script>
import CommonTable from "@/components/tables/CommonTable";
import accountPlFields from "./accountPlFields";
import Ripple from "vue-ripple-directive";
import {
    formatMoney,
    exportFile,
    formatNumber,
    setFilterInLocalStorage,
    getFilterInLocalStorage,
    removeFilterInLocalStorage,
} from "@/helper";
import moment from "moment";
import Dot from "@/components/Dot";
import axios from "@axios";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import TableSettingsModal from "@/components/TableSettingModal";
import { fieldPatternMatcher } from "@casl/ability";
import { mapGetters } from "vuex";
const TABLE_NAME = "AccountDetails";
export default {
    components: {
        CommonTable,
        Dot,
        vSelect,
        flatPickr,
        TableSettingsModal,
    },
    props: {
        current_item: Object,
        time_frame: String,
        parent_timeframe: String,
        parent_start_date: String,
        parent_end_date: String,
        flag: Boolean,
        show2ndTimeframe: {
            type: Boolean,
            default: false,
        },
        show1stComponent: {
            type: Boolean,
            default: true,
        },
        show2ndComponent: {
            type: Boolean,
            default: true,
        },
    },

    directives: {
        Ripple,
    },
    data() {
        return {
            // time_frame: "ThisMonth",
            account_symbols: [],
            exportFormat: "csv",
            key: 99,
            isLoading: false,
            isQueryingTimeframe: false,
            today: moment()
                .utc()
                .format("Y-MM-DD"),
            startDate: this.parent_start_date,
            endDate: this.parent_end_date,
            accountPlFields,
            isFilterShown: false,
            isFilterZeroShown: false,
            filterValues: {},
            canFilterOutZeroValues: true,
            filterOutZeroValues: {},
            field_sort: "PL",
            isDesc: true,
            items_mock: [
                { symbol: "USDJPY", openPL: 10.0, closedPL: 20.0, netPL: 30.0, volumeTraded: 40.0, lotsTraded: 50.0 },
                { symbol: "EURHKD", openPL: 10.0, closedPL: 20.0, netPL: 30.0, volumeTraded: 40.0, lotsTraded: 50.0 },
                { symbol: "EURSEK", openPL: 10.0, closedPL: 20.0, netPL: 30.0, volumeTraded: 40.0, lotsTraded: 50.0 },
                { symbol: "EURZAR", openPL: 10.0, closedPL: 20.0, netPL: 30.0, volumeTraded: 40.0, lotsTraded: 50.0 },
                { symbol: "GBPDKK", openPL: 10.0, closedPL: 20.0, netPL: 30.0, volumeTraded: 40.0, lotsTraded: 50.0 },
            ],
        };
    },
    methods: {
        moment,
        formatMoney,
        exportFile,
        sortBy(field) {
            if (field) {
                this.field_sort = field;
                this.isDesc = !this.isDesc;
            }
        },

        getTimeframeValue(item, value) {
            return item.hasOwnProperty(value + this.time_frame) ? item[value + this.time_frame] : item[value];
        },

        handleFilterOutZeroValues(filterOutZeroValues) {
            Object.keys(filterOutZeroValues).forEach((key) => {
                if (key != "show") this.$set(this.filterOutZeroValues, key, filterOutZeroValues[key]);
            });
            if (this.isFilterZeroShown)
                setFilterInLocalStorage(TABLE_NAME + "Zero", {
                    ...filterOutZeroValues,
                    show: this.isFilterZeroShown,
                });
        },

        initFilter() {
            if (!getFilterInLocalStorage(TABLE_NAME)) return;

            let filterValues = getFilterInLocalStorage(TABLE_NAME);

            this.isFilterShown = true;
            this.$refs.table.initFilter(filterValues);
        },

        toggleFilterZero() {
            this.isFilterZeroShown = !this.isFilterZeroShown;
            if (this.isFilterZeroShown) {
                let filterOutZeroValues = getFilterInLocalStorage(TABLE_NAME + "Zero") || {};

                setFilterInLocalStorage(TABLE_NAME + "Zero", {
                    ...filterOutZeroValues,
                    show: true,
                });
            } else {
                let filterOutZeroValues = getFilterInLocalStorage(TABLE_NAME + "Zero") || {};

                setFilterInLocalStorage(TABLE_NAME + "Zero", {
                    ...filterOutZeroValues,
                    show: false,
                });
            }
        },
        toggleFilter() {
            this.isFilterShown = !this.isFilterShown;
            if (this.isFilterShown) {
                setFilterInLocalStorage(TABLE_NAME, {});
            } else {
                removeFilterInLocalStorage(TABLE_NAME);
            }
        },

        clearFilter() {
            this.$refs.table.clearFilter();
        },

        filterChange(filterValues) {
            Object.keys(filterValues).forEach((key) => {
                this.$set(this.filterValues, key, filterValues[key]);
            });
            if (this.isFilterShown) setFilterInLocalStorage(TABLE_NAME, filterValues);
        },

        filterClear(filterValues) {
            Object.keys(filterValues).forEach((key) => {
                this.$set(this.filterValues, key, filterValues[key]);
            });
            if (this.isFilterShown) setFilterInLocalStorage(TABLE_NAME, filterValues);
        },

        applyCustomTimeframe() {
            this.isQueryingTimeframe = true;
            return this.$emit(
                "applyCustomTimeframeSingleItem",
                this.startDate,
                this.endDate,
                (res) => {
                    this.isQueryingTimeframe = false;
                },
                (res) => {
                    this.isQueryingTimeframe = false;
                }
            );
        },

        update_account_symbols(account_symbols) {
            // console.log('fetched symbols', account_symbols)
            // this.isQueryingTimeframe = false
            this.account_symbols = this.account_symbols.map((x) =>
                Object.assign(x, account_symbols.find((y) => y.id == x.id) || {})
            );
            // console.log('my symbols', this.account_symbols)
            // console.log('common symbols', this.account_symbols.filter(a => account_symbols.find(b => b.id == a.id)))
        },

        isBothOpenAndClosePlZero(item) {
            return (item.openPL == 0 || isNaN(item.openPL)) && (item.closedPL == 0 || isNaN(item.closedPL));
        },
    },
    computed: {
        ...mapGetters({
            // account_symbols: "tableData/market_exposure"
        }),

        isTimeframeCustomAndBeforeToday() {
            let today = new Date(this.today).getTime();
            let to_date = new Date(this.to_date).getTime();
            return this.time_frame === "CustomTimeframe" && today > to_date;
        },

        fields() {
            let restrict = JSON.parse(localStorage.getItem("Account P/L Settings"));
            if (this.key && restrict) return accountPlFields.filter((i) => !restrict.includes(i.label));
            return accountPlFields;
        },

        to_date() {
            let res = new Date(this.endDate);
            res.setUTCHours(0, 0, 0);
            return res;
        },

        dataTotal() {
            let label = ["CompanyFloatingPL", "ClosedPL", "VolTraded", "LotsTraded"];
            let result = {};
            label.forEach((lb) => {
                result[lb] = 0;
            });
            this.current_account_symbols.forEach((item) => {
                label.forEach((field) => {
                    result[field] += this.getTimeframeValue(item.value, field);
                });
            });
            // console.log('datatotal', result)
            return result;
        },

        dataAfterFilterOutZeroValues() {
            let results = JSON.parse(JSON.stringify(this.dataAfterFiltered));

            // console.log(this.filterOutZeroValues)
            Object.keys(this.filterOutZeroValues).forEach((key) => {
                if (this.filterOutZeroValues[key]) {
                    results = results.filter((item) => {
                        if (key != "Risk") return !isNaN(parseFloat(item[key])) && parseFloat(item[key]) !== 0;
                        else return item[key] !== 1;
                    });
                }
            });
            return results;
        },

        dataAfterFiltered() {
            let results = JSON.parse(JSON.stringify(this.dataAfterFormat));
            Object.keys(this.filterValues).forEach((key) => {
                let field = this.fields.find((field) => field.key == key);
                if (field?.filter?.filterFunc) {
                    results = field.filter.filterFunc(results, this.filterValues[key]);
                } else {
                    if (this.filterValues[key]) {
                        results = results.filter((item) => {
                            return (item[key] ?? "")
                                .toString()
                                .toLowerCase()
                                .includes(this.filterValues[key].toLowerCase());
                        });
                    }
                }
            });
            return results;
        },

        dataAfterFormat() {
            return this.dataAfterSort.map((item) => {
                return {
                    ...item,
                    openPL: formatMoney(item.openPL, 2),
                    closedPL: formatMoney(item.closedPL, 2),
                    netPL: formatMoney(item.netPL, 2),
                    volumeTraded: formatMoney(item.volumeTraded, 2),
                    lotsTraded: formatMoney(item.lotsTraded, 2),
                };
            });
        },

        dataAfterSort() {
            let pos_or_neg = this.isDesc ? -1 : 1;
            let results = JSON.parse(JSON.stringify(this.items));
            results.sort(
                (a, b) =>
                    pos_or_neg *
                    (a[this.field_sort] < b[this.field_sort] ? -1 : a[this.field_sort] > b[this.field_sort] ? 1 : 0)
            );
            return results;
        },

        items() {
            return this.current_account_symbols
                .map((item) => {
                    return {
                        symbol: item.properties.symbol,
                        openPL: this.getTimeframeValue(item.value, "CompanyFloatingPL"),
                        closedPL: item.value["ClosedPL" + this.time_frame],
                        netPL:
                            this.getTimeframeValue(item.value, "CompanyFloatingPL") +
                            item.value["ClosedPL" + this.time_frame],
                        volumeTraded: item.value["VolTraded" + this.time_frame],
                        lotsTraded: item.value["LotsTraded" + this.time_frame],
                    };
                })
                .filter((item) => !this.isBothOpenAndClosePlZero(item));
        },

        current_account_symbols() {
            // console.log('account symbols', this.account_symbols.length)
            // console.log(this.account_symbols)
            var result = this.account_symbols.filter(
                (item) =>
                    item.properties.login == this.current_item.properties.login &&
                    item.properties.server == this.current_item.properties.server &&
                    (item.value["ClosedPL" + this.time_frame] ||
                        this.getTimeframeValue(item.value, "CompanyFloatingPL"))
            );
            // console.log(result)
            // if (this.time_frame == "CustomTimeframe") {
            //   let to_date = this.parent_end_date
            //   if (to_date != this.today)
            //     result = result.filter(item => item.value["ClosedPL" + this.time_frame] != null)
            //   console.log(result)
            // }
            return result;
        },

        exportData() {
            return [
                ["Login", this.current_item.properties.login],
                ["Email", this.current_item.properties.mt_account?.email],
                ["Name", this.current_item.properties.mt_account?.name],
                ["Currency", this.current_item.properties.currency],
                ["Group", this.current_item.properties.group],
                ["Closed Profit", formatNumber(this.current_item.value["ClosedPL" + this.time_frame], 2)],
                ["Closed Win", formatNumber(this.current_item.value["ClosedWin" + this.time_frame], 2)],
                ["Closed Loss", formatNumber(this.current_item.value["ClosedLoss" + this.time_frame], 2)],
                ["Deposits", formatNumber(this.current_item.value["Deposits" + this.time_frame], 2)],
                ["Withdrawals", formatNumber(this.current_item.value["Withdrawals" + this.time_frame], 2)],
                ["Net Deposit", formatNumber(this.current_item.value["NetDeposits" + this.time_frame], 2)],
                ["Company", this.current_item.properties.path ? this.current_item.properties.path[0] : ""],
                ["Server", this.current_item.properties.server],
                ["Region", this.current_item.properties.path ? this.current_item.properties.path[1] : ""],
                ["Team", this.current_item.properties.path ? this.current_item.properties.path[2] : ""],
                [
                    "Registration Date",
                    moment(this.current_item.properties.mt_account?.register_time).format("DD/MM/YYYY"),
                ],
                [],
                ["Login", this.current_item.properties.login],
                ["Closed P/L", formatNumber(this.current_item.value["ClosedPL" + this.time_frame], 2)],
                ["Closed Win", formatNumber(this.current_item.value["ClosedWin" + this.time_frame], 2)],
                ["Closed Loss", formatNumber(this.current_item.value["ClosedLoss" + this.time_frame], 2)],
                [
                    "Floating P/L",
                    formatNumber(
                        this.current_item.value[
                            "OpenPL" + (this.time_frame == "CustomTimeframe" ? this.time_frame : "")
                        ],
                        2
                    ),
                ],
                [
                    "Floating Win",
                    this.current_item.value[
                        "OpenWin" + (this.time_frame == "CustomTimeframe" ? this.time_frame : "")
                    ] != null
                        ? formatNumber(
                              this.current_item.value[
                                  "OpenWin" + (this.time_frame == "CustomTimeframe" ? this.time_frame : "")
                              ],
                              2
                          )
                        : "N/A",
                ],
                [
                    "Floating Loss",
                    this.current_item.value[
                        "OpenLoss" + (this.time_frame == "CustomTimeframe" ? this.time_frame : "")
                    ] != null
                        ? formatNumber(
                              this.current_item.value[
                                  "OpenLoss" + (this.time_frame == "CustomTimeframe" ? this.time_frame : "")
                              ],
                              2
                          )
                        : "N/A",
                ],
                ["Overall P/L", formatNumber(this.current_item.value["PL" + this.time_frame], 2)],
                [
                    "Overall Win",
                    this.current_item.value["NetWin" + this.time_frame] != null
                        ? formatNumber(this.current_item.value["NetWin" + this.time_frame], 2)
                        : "N/A",
                ],
                [
                    "Overall Loss",
                    this.current_item.value["NetLoss" + this.time_frame] != null
                        ? formatNumber(this.current_item.value["NetLoss" + this.time_frame], 2)
                        : "N/A",
                ],
                [],
                [...this.fields.map((field) => field.label)],
                ...this.items.map((item) => {
                    return this.fields.map((field) => {
                        if (!isNaN(item[field.key])) return formatNumber(item[field.key], 2);
                        return item[field.key];
                    });
                }),
            ];
        },
    },
    watch: {
        flag: {
            // the callback will be called immediately after user click another row
            immediate: true,
            handler(val, oldVal) {
                this.$emit("set_time_frame", this.parent_timeframe);
                if (
                    this.time_frame == "CustomTimeframe" &&
                    (this.startDate != this.parent_start_date || this.endDate != this.parent_end_date)
                ) {
                    this.startDate = this.parent_start_date;
                    this.endDate = this.parent_end_date;
                    this.applyCustomTimeframe();
                }
            },
        },
        to_date() {
            // console.log(this.today, ' <=> ', this.to_date)
        },
    },
    channels: {
        MetricDataUpdateChannel: {
            connected() {
                console.log("Connected to the chat channel");
            },
            rejected() {
                console.log("Rejected to the chat channel");
            },
            received(data) {
                if (!this.isTimeframeCustomAndBeforeToday) {
                    if (
                        data?.event_name == "trade.MetricDatum.before_destroy" &&
                        data.payload.metric_definition_name == "MarketExposure"
                    ) {
                        let index = this.account_symbols.findIndex((item) => item.id == data.payload.id);
                        if (index != -1) this.account_symbols.splice(index, 1);
                        return;
                    }

                    if (
                        data?.event_name == "trade.MetricDatum.after_create" &&
                        data.payload.metric_definition_name == "MarketExposure"
                    ) {
                        if (this.account_symbols.find((item) => item.id == data.payload.id)) {
                            this.$set(
                                this.account_symbols.find((item) => item.id == data.payload.id),
                                "value",
                                data.payload.value
                            );
                        } else {
                            this.account_symbols.push(data.payload);
                        }
                        return;
                    }

                    data.forEach((datum) => {
                        if (datum.metric_definition_name == "MarketExposure") {
                            if (this.account_symbols.find((item) => item.id == datum.id)) {
                                this.$set(
                                    this.account_symbols.find((item) => item.id == datum.id),
                                    "value",
                                    datum.value
                                );
                            } else {
                                this.account_symbols.push(datum);
                            }
                        }
                    });
                }
                this.$store.commit("app/SET_DATA_LIVE_TIME", moment().format(" HH:mm:ss - DD/MM/YYYY"));
            },
            disconnected() {
                console.log("Disconnected to the chat channel");
            },
        },
    },
    async mounted() {
        this.initFilter();
        //? mockData
        // this.$cable.subscribe({
        //   channel: "MetricDataUpdateChannel",
        // });

        this.isLoading = true;
        try {
            // let res = await this.$store.dispatch("tableData/fetchMarketExposure");

            //? mockData
            let res = await axios.get("/MarketExposure/data");
            this.account_symbols = res.data;
        } catch (err) {
            console.error(err);
        } finally {
            this.isLoading = false;
        }
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style lang="scss" scoped>
.account-details ::v-deep .vs__dropdown-toggle {
    border-radius: 20px !important;
    // box-shadow: 0px 0px 5px white;
}

.account-details ::v-deep .flatpickr-input {
    border-radius: 20px !important;
    // box-shadow: 0px 0px 5px white;
    font-size: 0.75rem;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    height: 30px;
    width: 160px !important;
}

.account-details ::v-deep .width-160px {
    width: 160px !important;
}
.account-details ::v-deep .card {
    .table {
        td {
            border-radius: 0 !important;
        }
    }
}
.account-analysis,
.account-profit-summary,
.account-p-l {
    .card {
        height: 615px;
        max-height: 615px;
        &.is-custom-timeframe {
            height: 760px;
            max-height: 760px;
        }
    }
}
.custom-position {
    width: 40%;
    position: absolute;
    bottom: 15px;
}
</style>
